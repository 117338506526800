import axiosClient from "./ApiInstance";
import Cookies from "js-cookie";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(
      process.env.REACT_APP_BASE_URL + url,
      data
    );
    return response.data;
  } catch (error) {
    // Cookies.remove("net_purti_admin_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("net_purti_admin_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const editStatusData = async (url) => {
  try {
    const response = await axiosClient.put(
      process.env.REACT_APP_BASE_URL + url
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("net_purti_admin_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axiosClient.delete(
      process.env.REACT_APP_BASE_URL + url
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("net_purti_admin_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

// Download Apis
export const getDownloadDataExcel = async (aurl, data, name) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + aurl,
      data,
      {
        responseType: "blob", // Set responseType to "blob"
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${name + " " + new Date().toLocaleDateString()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();

    // Clean up after the download
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Cookies.remove("net_purti_admin_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const getProducts = async (
  category_id,
  sub_category_id,
  min,
  max,
  quantity,
  working_type_id,
  customization_id,
  attributes_id,
  sub_attributes_id
) => {
  try {
    let url = "/btb/best-seller/products?";

    if (category_id) {
      url += `&category_id=${category_id}`;
    }

    if (sub_category_id) {
      url += `&sub_category_id=${sub_category_id}`;
    }

    if (min !== undefined && min !== "") {
      url += `&min=${min}`;
    }

    if (max !== undefined && max !== "") {
      url += `&max=${max}`;
    }

    if (quantity !== undefined && quantity !== "") {
      url += `&quantity=${quantity}`;
    }

    if (attributes_id !== undefined && attributes_id !== "") {
      url += `&attributes_id=${attributes_id.value}`;
    }

    if (sub_attributes_id?.length) {
      url += `&sub_attributes_id=${sub_attributes_id?.map((id) => id.value)}`;
    }

    if (working_type_id?.length) {
      url += `&working_type_id=${working_type_id}`;
    }

    if (customization_id?.length) {
      url += `&customization_id=${customization_id}`;
    }

    // const res = await getData(
    //   `/btb/best-seller/products?&sub_category_id=${sub_category_id}&min=${min}&max=${max}&quantity=${quantity}&attributes_id=${
    //     attributes_id.value
    //   }&sub_attributes_id=${sub_attributes_id?.map(
    //     (id) => id.value
    //   )}&working_type_id=${working_type_id}&customization_id=${customization_id}&`
    // );

    const res = await getData(url);
    return res;
  } catch (error) {
    console.log(error);
  }
};
