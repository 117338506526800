import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  import Cookies from "js-cookie";
  import { v4 as uuidv4 } from "uuid";

  export const getStricks = async (data) => {
    try {
      return await getData(`/affiliate/affiliate-user-manage/stricks`,data);
    } catch (error) {
      console.log(error);
    }
  };

  
  export const postStrickStatus = async (data) => {
    try {
      return await postData(`/affiliate/affiliate-user-manage/stricks`,data);
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getAffiliateActive = async (data) => {
    try {
      return await getData(`/affiliate/affiliate-user-manage/active`,data);
    } catch (error) {
      console.log(error);
    }
  };

  export const getAffiliateNew = async (data) => {
    try {
      return await getData(`/affiliate/affiliate-user-manage/new`,data);
    } catch (error) {
      console.log(error);
    }
  };

  export const getAffiliateTerminate = async (data) => {
    try {
      return await getData(`/affiliate/affiliate-user-manage/terminate`,data);
    } catch (error) {
      console.log(error);
    }
  };

  export const getAffiliateDetails = async (id) => {
    try {
      return await getData(`/affiliate/affiliate-user-manage/details/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  export const blockApi = async (id,reason) => {
    try {
      return await postData(`/affiliate/affiliate-user-manage/blockUser`,{affiliate_id: id,remark: reason});
    } catch (error) {
      console.log(error);
    }
  };

  export const terminationApi = async (id,reason) => {
    try {
      return await postData(`/affiliate/affiliate-user-manage/terminateUser`,{affiliate_id: id,remark: reason});
    } catch (error) {
      console.log(error);
    }
  };

  export const removeTerminationApi = async (id) => {
    try {
      return await postData(`/affiliate/affiliate-user-manage/removeTermination`,{affiliate_id: id});
    } catch (error) {
      console.log(error);
    }
  };
  

  

  
 
  
  