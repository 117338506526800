import React, { useContext } from "react";
import { useState, useEffect } from "react";

import Header from "../Header/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { event } from "jquery";


import EventAddModal from "./EventAddModal/EventAddModal";
import { getEventsApi } from "../../utils/apis/calendar";
import { Context } from "../../utils/context";

const localizer = momentLocalizer(moment);

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
const Calendars = () => {



  const {IMG_URL} = useContext(Context);
    const [eventAddShow, setEventAddShow] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [eventData, setEventData] = useState("");


    const [events, setEvents] = useState([]);
  
    const GetEditData = async () => {
    //   const response = await getData(`/calender`);
    //   setEvents(response?.data);
    };
  
    useEffect(() => {
      GetEditData();
    }, []);
  
    const handleSelectSlot = async ({ start, end }) => {

        await setStartDate(start);
        await setEndDate(end);

        setEventAddShow(true);


    };
  
    const handleSelectEvent = async (event) => {
      const action = window.prompt(
        `Do you want to 'edit' or 'delete' the event titled "${event.title}"? Type 'edit' or 'delete'.`
      );
  
      console.log(event,"event eventedit delte");
      
  
      if (action === 'edit') {
        await setEventData(event);
        await setStartDate(event?.start);
        await setEndDate(event?.end);

        setEventAddShow(true);


      } else if (action === 'delete') {
        if (window.confirm(`Are you sure you want to delete the event '${event.title}'?`)) {
          setEvents((prevEvents) => prevEvents.filter((e) => e !== event));
       
        }
      }else {
        // Handle invalid input
        window.alert("Invalid action. Please type 'edit' or 'delete'.");
        console.log('Invalid action: ', action);
      }
    };
  
   
  
    const eventStyleGetter = (event) => {
      const backgroundColor = event.color; // Use the color from the event data
      return {
        style: {
          backgroundColor,
          color: 'white',
          borderRadius: '5px',
          border: 'none',
          display: 'block',
        }
      };
    };
  
    console.log(events, 'events');


    const getEvents = async () => {
      const res = await getEventsApi();

      if(res?.success){
        setEvents(res?.data?.data);
      }
    }

    const EventWithImage = ({ event }) => (
      <div>
        <img src={IMG_URL + event.image} alt="event" style={{ width: '40px', marginRight: '5px' }} />
        <span>{event.title}</span>
      </div>
    );

    

    useEffect(() => {
        getEvents();
    },[]);
    return (
        <>
            <div className="main-advancedashboard">
                <section className="AdvanceDashboard">
                    <div className="AdvanceDashboardTabs">
                        <Header title={"Calendar"} link={"/calendar"} />

                        <div className=""></div>
                    </div>
                </section>

                <div className="Map-Section">
                    <Row>
                        <Col xxl={12} className="my-3">
                            <Calendar
                                selectable
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 800 }}
                                onSelectSlot={handleSelectSlot}
                                onSelectEvent={handleSelectEvent} 
                                eventPropGetter={eventStyleGetter}
                                components={{
                                  event: EventWithImage, // Use custom component
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <EventAddModal show={eventAddShow} 
            onHide={() => {setEventAddShow(false); setEventData("");}} 
            startDate={startDate} endDate={endDate}
             events={events} setEvents={setEvents} 
             getEvents={getEvents} eventData={eventData}  setEventData={setEventData}/>
        </> 
    );
};

export default Calendars;
