import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import {
  MarginSettings,
  MinimunMariginSettings,
} from "../../../utils/apis/master/Master";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const [workinTypes, setWorkingTypes] = useState([]);

  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [min, setMin] = useState(0);

  const GetEditData = async () => {
    const response = await getData(
      `/seller/masters/customize-product-attributes/${id}`
    );
    reset(response?.data);

    {
      const response = await MarginSettings();
      if (response?.success) {
        setValue("d_margin", response?.data?.category_margin);
      }
    }
    {
      const response = await MinimunMariginSettings();
      if (response?.success) {
        setMin(response?.data?.category_margin);
      }
    }
  };

  const GetAllWorkingTypes = async () => {
    const response = await getData("/common/seller/masters/working-type");

    if (response?.success) {
      setWorkingTypes(await Select2Data(response?.data, "working_type_id"));
    }
  };

  useEffect(() => {
    GetEditData();
    GetAllWorkingTypes();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("name", data?.name);
      DataToSend.append("document", data?.document ? 1 : 0);

      const response = await postData(
        `/seller/masters/customize-product-attributes/${id}`,
        DataToSend
      );

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Customize Product Attributes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Customize Product Attributes</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Packaging Attributes"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group as={Col} controlId="formDocument">
                      <Form.Label>Document</Form.Label>
                      <InputGroup>
                        <Controller
                          control={control}
                          name="document"
                          render={({ field }) => (
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className={errors.document ? "is-invalid" : ""}
                              checked={field.value}
                              onChange={(e) =>
                                field.onChange(e.target.checked ? 1 : 0)
                              }
                              {...field}
                            />
                          )}
                        />
                      </InputGroup>
                      {errors.document && (
                        <Form.Control.Feedback type="invalid">
                          {errors.document.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
