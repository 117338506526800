import React, { useState, useContext, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import SArea from "../S_Area/Tables";
import ShipingServiceType from "../Shiping_Service_Type/Tables";
import { Context } from "../../../utils/context";
import LocationContent from "../SettingContent/LocationContent/LocationContent";
import MarginContent from "../SettingContent/MarginContent/MarginContent";
import AffiliateContent from "./AffiliateContent/AffiliateContent";
import DashboardContent from "./DashboardContent/DashboardContent";
import InhouseAdsContent from "./InhouseAdsContent/InhouseAdsContent";
import SellerAffiliateActivityContent from "./SellerAffiliateActivityContent/SellerAffiliateActivityContent";
import AffiliateActivity from "./AffiliateActivity/AffiliateActivity";
import AffiliateUserManager from "./AffiliateUserManager/AffiliateUserManager";
import NewLinkAndAddFlow from "./NewLinkAndAddFlow/NewLinkAndAddFlow";
import AffiliateInfluencerSettingContent from "./AffiliateInfluencerSettingContent/AffiliateInfluencerSettingContent";

const AffiliateInfluencerContent = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const tabItems = [
    { key: 1, name: "Affiliate", link: "" },
    { key: 2, name: "Dashboard", link: "" },
    { key: 3, name: "Inhouse Ads", link: "" },
    { key: 4, name: "Seller Affiliate Activity", link: "" },
    { key: 5, name: "Affiliate Activity", link: "" },
    { key: 6, name: "Affiliate User Manager", link: "" },
    { key: 7, name: " Affiliate ", link: "" },
    { key: 8, name: " New Link And Flow ", link: "" },
    { key: 9, name: " Setting ", link: "" },
    { key: 10, name: " Guide ", link: "" },
  ];

  return (
    <>
      <Header title={tabItems[active]?.name} link={"/masters/social_links"} />

      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="1"
        className="tabadminmain"
      >
        <Row className=" me-0 ms-0">
          <Col xl={2} lg={2} md={4} className="p-0">
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={10} md={8} className=" p-0">
            <Tab.Content className="tabadminmain">
              {[149, 150, 151, 152, 153, 154].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="1">
                  <AffiliateContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[155, 156, 157, 158, 159, 160].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="2">
                  <DashboardContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[161, 162, 163, 164, 165, 166].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="3">
                  <InhouseAdsContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[167, 168, 169, 170, 171, 172].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="4">
                  {" "}
                  <SellerAffiliateActivityContent />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}

              {[173, 174, 175, 176, 177, 178].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="5">
                  {" "}
                  <AffiliateActivity />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}

              {[179, 180, 181, 182, 183, 184].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="6">
                  {" "}
                  <AffiliateUserManager />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}

              {[185, 186, 187, 188, 189, 190].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="7"></Tab.Pane>
              ) : (
                <></>
              )}

              {[191, 192, 193, 194, 195, 196].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="8">
                  <NewLinkAndAddFlow />
                </Tab.Pane>
              ) : (
                <></>
              )}

              {[197, 198, 199, 200, 201, 202].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="9">
                  <AffiliateInfluencerSettingContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default AffiliateInfluencerContent;
