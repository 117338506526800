export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
  working_type: [1, 2, 3],
};

export const IDS = {
  web_site_type: {
    admin: "admin",
    outer: "outer",
    info: "info",
    inquiry: "inquiry",
  },
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select3Data = async (data, product_title, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.product_title,
    product_title: product_title,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", product_title: product_title });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}
export function dateShow(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// Function to format the date and add days
export function formatDateExpire(dateString, daysToAdd = 0) {
  if (dateString) {
    const date = new Date(dateString);
    const newDate = addDays(date, daysToAdd);
    return newDate.toISOString().split("T")[0];
  }
}


export const formatDateObject = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0'); // Get day and add leading zero if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and add leading zero
  const year = date.getFullYear(); // Get full year

  return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
};
