import React, { useState } from 'react'
// import './DashboardInHouse.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
// import AddTaskModal from './AddTaskModal';
import { Col, Nav, Row, Tab, Form, Pagination, Table } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const sellerData = [
  {
    sellerId: "S01",
    sellerName: "Mr. John",
    firmAddress: "XYZ",
    firmType: "A",
    contactNo: "1234569879",
    postTaskDetails: "High jhkbkihkjh",
    date: "22-04-2023",
    validity: "1Yr",
    totalOrder: "25",
    totalLinkGenerate: "Rejected",
    type: "Simple",
    setting: "add mark"
  },
  {
    sellerId: "S01",
    sellerName: "Mr. John",
    firmAddress: "XYZ",
    firmType: "A",
    contactNo: "1234569879",
    postTaskDetails: "High jhkbkihkjh",
    date: "22-04-2023",
    validity: "1Yr",
    totalOrder: "25",
    totalLinkGenerate: "Rejected",
    type: "Simple",
    setting: "add mark"
  },
  {
    sellerId: "S01",
    sellerName: "Mr. John",
    firmAddress: "XYZ",
    firmType: "A",
    contactNo: "1234569879",
    postTaskDetails: "High jhkbkihkjh",
    date: "22-04-2023",
    validity: "1Yr",
    totalOrder: "25",
    totalLinkGenerate: "Rejected",
    type: "Simple",
    setting: "add mark"
  },
  {
    sellerId: "S01",
    sellerName: "Mr. John",
    firmAddress: "XYZ",
    firmType: "A",
    contactNo: "1234569879",
    postTaskDetails: "High jhkbkihkjh",
    date: "22-04-2023",
    validity: "1Yr",
    totalOrder: "25",
    totalLinkGenerate: "Rejected",
    type: "Simple",
    setting: "add mark"
  },
  {
    sellerId: "S01",
    sellerName: "Mr. John",
    firmAddress: "XYZ",
    firmType: "A",
    contactNo: "1234569879",
    postTaskDetails: "High jhkbkihkjh",
    date: "22-04-2023",
    validity: "1Yr",
    totalOrder: "25",
    totalLinkGenerate: "Rejected",
    type: "Simple",
    setting: "add mark"
  },
]

const requestData = [
  {
    sellerId: 1234,
    sellerName: "Disha M",
    productid: "123654",
    productname: "P1",
    totallink: "High",
    totalOutput: "Rejected",
    totalLinkGenerate: "NA",
    type: "46546",
    influencerId: "All ",
    link: '67578567',

  },
  {
    sellerId: 1234,
    sellerName: "Disha M",
    productid: "123654",
    productname: "P1",
    totallink: "High",
    totalOutput: "Rejected",
    totalLinkGenerate: "NA",
    type: "46546",
    influencerId: "All ",
    link: '67578567',

  },
  {
    sellerId: 1234,
    sellerName: "Disha M",
    productid: "123654",
    productname: "P1",
    totallink: "High",
    totalOutput: "Rejected",
    totalLinkGenerate: "NA",
    type: "46546",
    influencerId: "All ",
    link: '67578567',

  },
  {
    sellerId: 1234,
    sellerName: "Disha M",
    productid: "123654",
    productname: "P1",
    totallink: "High",
    totalOutput: "Rejected",
    totalLinkGenerate: "NA",
    type: "46546",
    influencerId: "All ",
    link: '67578567',

  },
  {
    sellerId: 1234,
    sellerName: "Disha M",
    productid: "123654",
    productname: "P1",
    totallink: "High",
    totalOutput: "Rejected",
    totalLinkGenerate: "NA",
    type: "46546",
    influencerId: "All ",
    link: '67578567',
  },

]

const SellerInfluencerInner = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  // Modal
  const [lgShow, setLgShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  // star Mark

  const [rating, setRating] = useState(0);

  const handleRating = () => {
    setRating(rating === 1 ? 0 : 1);
  };

  // History Table

  const [filterReportText, setFilterReportText] = useState('');

  const reportcolumns = [
    {
      name: 'Seller Id',
      selector: row => row.sellerid,
      sortable: true,
    },
    {
      name: 'Seller Name',
      selector: row => row.starmark,
      sortable: true,
    },
    {
      name: 'Firm Address',
      selector: row => row.productid,
      sortable: true,
    },
    {
      name: 'Product Name',
      selector: row => row.productname,
      sortable: true,
    },
    {
      name: 'Total Link',
      selector: row => row.totallink,
      sortable: true,
    },
    {
      name: 'Link',
      selector: row => row.link,
      sortable: true,
    },
    {
      name: 'Incentive',
      selector: row => row.incentive,
      sortable: true,
    },
    {
      name: 'Views',
      selector: row => row.views,
      sortable: true,
    },
    {
      name: 'Total Buy	',
      selector: row => row.totalbuy,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => row.action,
      sortable: true,
    },

  ];

  const reportdata = [
    {
      id: 1,
      adid: 'Brand',
      starmark: "",
      title: "njkndkf",
      productname: 'Product Based',
      totallink: 'Yes',
      views: "200k",
      link: 'A',
      incentive: '10%',
      totalbuy: 'High',
      amount: '654646',
      status: '1Yr',
      action: ""
    },
    {
      id: 2,
      adid: 'Brand',
      starmark: '',
      title: "njkndkf",
      productname: 'Product Based',
      totallink: 'Yes',
      views: "200k",
      link: 'A',
      incentive: '10%',
      totalbuy: 'High',
      amount: '654646',
      status: '1Yr',
      action: ""
    },
    {
      id: 3,
      adid: 'Brand',
      starmark: "",
      title: "njkndkf",
      productname: 'Product Based',
      totallink: 'Yes',
      views: "200k",
      link: 'A',
      incentive: '10%',
      totalbuy: 'High',
      amount: '654646',
      status: '1Yr',
      action: ""
    },
  ];

  const filteredReportData = reportdata.filter(item =>
    item.title.toLowerCase().includes(filterReportText.toLowerCase())
  );

  const handleFilterReportChange = e => {
    setFilterReportText(e.target.value);
  };

  // Reject Request Table
  const [filterText, setFilterText] = useState('');
  const columns = [
    {
      name: 'Seller Id	',
      selector: row => row.sellerid,
      sortable: true,
    },
    {
      name: 'Seller Name',
      selector: row => row.sellername,
      sortable: true,
    },
    {
      name: 'Show Task',
      selector: row => row.showtask,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Total Link',
      selector: row => row.totallink,
      sortable: true,
    },
    {
      name: 'Total Output',
      selector: row => row.totaloutput,
      sortable: true,
    },
    {
      name: 'Total link generate',
      selector: row => row.totallinkgenerate,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => row.type,
      sortable: true,
    },
    {
      name: 'Influencer Id',
      selector: row => row.influencerid,
      sortable: true,
    },
    {
      name: 'Link',
      selector: row => row.link,
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      sellerid: 'Brand',
      image: " ",
      title: "njkndkf",
      sellername: "Saurav",
      showtask: '25',
      date: '25-04-2024',
      totallink: 'Yes',
      totaloutput: 'A',
      totallinkgenerate: '10%',
      type: 'High',
      influencerid: 'New York',
      link: '1Yr'
    },
    {
      id: 2,
      sellerid: 'Brand',
      image: "",
      title: "njkndkf",
      sellername: "Saurav",
      showtask: '25',
      date: '25-04-2024',
      totallink: 'Yes',
      totaloutput: 'A',
      totallinkgenerate: '10%',
      type: 'High',
      influencerid: 'New York',
      link: '1Yr'
    },
    {
      id: 3,
      sellerid: 'Brand',
      image: " ",
      title: "njkndkf",
      sellername: "Saurav",
      showtask: '25',
      date: '25-04-2024',
      totallink: 'Yes',
      totaloutput: 'A',
      totallinkgenerate: '10%',
      type: 'High',
      influencerid: 'New York',
      link: '1Yr'
    },
  ];

  const filteredData = data.filter(item =>
    item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = e => {
    setFilterText(e.target.value);
  };

  return (
    <section className='dashboard-in'>
      <div className='heading-holder'>
        {/* <h4>Complaint List</h4> */}
      </div>

      <div className='main-class-report-table'>
        <Row>
          <Col xl={3} lg={3} md={5} sm={12} >
            <Form.Label>Search</Form.Label>
            <Form.Control
              required
              type="text"
            />
          </Col>
          <Col xl={2} lg={3} md={5} sm={12} >
            <Form.Label>From</Form.Label>
            <Form.Control
              required
              type="Date"
            />
          </Col>
          <Col xl={2} lg={3} md={5} sm={12} >
            <Form.Label>To</Form.Label>
            <Form.Control
              required
              type="Date"
            />
          </Col>
          <Col xl={5} lg={5} md={5} sm={12}>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} >
                <Form.Label>Sort By</Form.Label>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} >
                <Form.Label>Download data in Excel</Form.Label><br />
                <FontAwesomeIcon icon="fa-solid fa-file-lines" className='pdf-icon' />
              </Col>
            </Row>
          </Col>

        </Row>

        <div className='brownborder-report mt-3'></div>
        <Row>
          <div className='col-md-12'>
            <div className='my-3'>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="first">History</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Reject Request</Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="table-sec-main mt-5">
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <th>Seller Id</th>
                            <th>Seller Name</th>
                            <th>Firm Address</th>
                            <th>Firm Type</th>
                            <th>Contact No</th>
                            <th>Post Task Details</th>
                            <th>Date</th>
                            <th>Total Click</th>
                            <th>Total Order</th>
                            <th>Total Link Generate</th>
                            <th>Type</th>
                            <th>Setting</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sellerData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.sellerId}</td>
                              <td>{item.sellerName}</td>
                              <td>{item.firmAddress}</td>
                              <td>{item.firmType}</td>
                              <td>{item.contactNo}</td>
                              <td>{item.postTaskDetails}</td>
                              <td>{item.date}</td>
                              <td>{item.totalClick}</td>
                              <td>{item.totalOrder}</td>
                              <td>{item.totalLinkGenerate}</td>
                              <td>{item.type}</td>
                              <td>{item.setting}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                    </div>
                    <div className="paginationss mt-4">
                      <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Ellipsis />
                        <Pagination.Item>{10}</Pagination.Item>
                        <Pagination.Next />
                      </Pagination>
                    </div>
                    <DataTable
                      columns={reportcolumns}
                      data={filteredReportData}
                      selectableRows
                      pagination
                      defaultSortFieldId={1}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {/* <div className="table-sec-main mt-5">
                      <Table responsive bordered>
                        <thead>
                          <tr className="heads-main">
                            <th><Form.Check type="checkbox" label="Select" /></th>
                            <th>Seller Id</th>
                            <th>Seller Name</th>
                            <th>Show Task</th>
                            <th>Date</th>
                            <th>Total Link</th>
                            <th>Total Output</th>
                            <th>Total link generate</th>
                            <th>Type</th>
                            <th>Influencer Id</th>
                            <th>Link</th>
                          </tr>
                        </thead>
                        <tbody>
                          {requestData.map((item, index) => (
                            <tr key={index} className="heads-main">
                              <td><Form.Check type="checkbox" /></td>
                              <td>{item.sellerId}</td>
                              <td>{item.sellerName}</td>
                              <td>{item.showTask}</td>
                              <td>{item.date}</td>
                              <td>{item.totalLink}</td>
                              <td>{item.totalOutput}</td>
                              <td>{item.totalLinkGenerate}</td>
                              <td>{item.type}</td>
                              <td>{item.influencerId}</td>
                              <td>{item.link}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>


                    </div>
                    <div className="paginationss mt-4">
                      <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Ellipsis />
                        <Pagination.Item>{10}</Pagination.Item>
                        <Pagination.Next />
                      </Pagination>
                    </div> */}
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      selectableRows
                      pagination
                      defaultSortFieldId={1}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Row>
      </div>
    </section>
  )
}

export default SellerInfluencerInner