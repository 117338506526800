import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { faEye } from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./ViewOffCanvance.css";
import toast, { Toaster } from "react-hot-toast";
import { type } from "@testing-library/user-event/dist/type";
import { Context } from "../../../../utils/context";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { data } from "jquery";

library.add(fas);

const ViewOffCanvance = (props) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState();
  const getSellerData = async () => {
    const res = await getData(`/media/personal-details/${props.id}`);
    setData(res.data);
    console.log(res);
  };

  useEffect(() => {
    getSellerData();
  }, [props.id]);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Media Details </h3>
                </Card.Title>

                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Personal Details" value="1" />
                        <Tab label="Media Type" value="2" />
                        <Tab label="Firm Details" value="3" />
                        <Tab label="Bank Details" value="4" />
                        <Tab label="Service Details" value="5" />
                        <Tab label="Branch Details" value="6" />{" "}
                        <Tab label="Attachment" value="7" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div>
                        <div>
                          <h3 className="titleName">Personal Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.f_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.l_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Contact Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.contact_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.email}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Designation</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.designation}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div>
                        <div>
                          <h3>Media Type</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Media Type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.media_sector?.media_type?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div>
                        <div>
                          <h3 className="titleName">Firm Details</h3>
                        </div>
                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>GST NO</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.media_firm_detail?.gst_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Uploaded GST Document</Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL +
                                        data?.media_firm_detail?.gst_image
                                      }
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4} className=""></Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.media_firm_detail?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm Type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.media_firm_detail?.s_firm_type
                                            ?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <div className="firm-addres">
                              <h3>Firm Address Details</h3>
                            </div>

                            <div>
                              <p>Firm Communication Address</p>
                            </div>

                            {data?.media_communication_addresses?.map(
                              (val, index) => (
                                <Row key={index}>
                                  <Col lg={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>Address Type</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={val?.address_name}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={val?.add_line_one}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={val?.add_line_two}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={val?.country?.name}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>State</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={val?.state?.name}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>City/District</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={val?.city?.name}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>

                                  <Col lg={4}>
                                    <div className="main-form-section mt-4">
                                      <Row className="justify-content-center">
                                        <Form.Label>PinCode</Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              value={val?.pincode?.name}
                                              disabled
                                            />
                                          </InputGroup>
                                        </Form.Group>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              )
                            )}
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="4">
                      <div>
                        <div>
                          <h3>Bank Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Group controlId="formAddressName">
                                    <Form.Label>Bank Account Number</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.media_bank_detail
                                            ?.bank_account_no
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Group controlId="formAddressName">
                                    <Form.Label>IFSC Code</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.media_bank_detail?.ifsc_code
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Group controlId="formAddressName">
                                    <Form.Label>Bank Name</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.media_bank_detail?.bank_name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Group controlId="formAddressName">
                                    <Form.Label>Branch Name</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.media_bank_detail?.branch_name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Group controlId="formAddressName">
                                    <Form.Label>MICR Code</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.media_bank_detail?.micr_code
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="5">
                      <div>
                        <div>
                          <h3>Service Details</h3>
                        </div>
                        {data?.media_service_details?.map((val, index) => (
                          <Row key={index}>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Country</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.country?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>State</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.state?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>City</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.city?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {val?.media_service_pincodes?.map((val, index) => (
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </div>
                    </TabPanel>
                    <TabPanel value="6">
                      <div>
                        {data?.media_branch_details?.map((val, index) => (
                          <div className="main-form-section mt-4">
                            <Row className="mt-5">
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>Branch Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.b_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>Branch Contact </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.b_contact}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>
                                    Branch Contact Person Name{" "}
                                  </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.b_contact_person_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>Address Line One </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.add_line_one}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>Address Line Two </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.add_line_two}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>City </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.city?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>State </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.state?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>Pincode </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.pincode?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>Country </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.country?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <Row className="justify-content-center">
                                  <Form.Label>Area </Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={val?.s_area?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                    <TabPanel value="7">
                      <div>
                        {data?.media_attachments?.map((val, index) => (
                          <Row>
                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Logo</Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={IMG_URL + val?.logo}
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Banner</Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={IMG_URL + val?.banner}
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Agreement Copy</Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={IMG_URL + val?.agreement}
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </>
  );
};

export default ViewOffCanvance;
