import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";

import Role from "./Components/EmployeeManagement/Role/Table";
import EmployeeDetails from "./Components/EmployeeManagement/EmployeeDetails/Table";

// Seller

import SBanner from "./Components/Masters/S_Banner/Tables";
import SCards from "./Components/Masters/S_Cards/Tables";
import SSellSmart from "./Components/Masters/S_sell_smart/Tables";
import STriumphs from "./Components/Masters/S_Triumphs/Tables";
import SMasterArt from "./Components/Masters/S_MasterArtType/Tables";
import SMasterArtSub from "./Components/Masters/S_MasterArtSubType/Tables";
import Language from "./Components/Masters/language/Tables";

import SPersonalDetails from "./Components/seller/CustomerManagement/PersonalDetails/Tables";
import SPlanDetails from "./Components/seller/CustomerManagement/PlanDetails/Tables";
import SOrderDetails from "./Components/seller/CustomerManagement/OrderDetails/Tables";
import MPersonalDetails from "./Components/Media/CustomerManagement/PersonalDetails/Tables";
import CPersonalDetails from "./Components/Checker/CustomerManagement/PersonalDetails/Tables";
import APersonalDetails from "./Components/affiliate/CustomerManagement/PersonalDetails/Tables";
import BPersonalDetails from "./Components/Brand/CustomerManagement/PersonalDetails/Tables";
import TPersonalDetails from "./Components/Transporter/CustomerManagement/PersonalDetails/Tables";
import ThreePPersonalDetails from "./Components/Three_p/CustomerManagement/PersonalDetails/Tables";
import BtcPersonalDetails from "./Components/Btc/CustomerManagement/PersonalDetails/Tables";
// BTB
import BtbPersonalDetails from "./Components/Btb/CustomerManagement/PersonalDetails/Tables";
import BtbAdvertisementSection from "./Components/Btb/Home/advertisement_section/Table";
import BtbBannerSection from "./Components/Btb/Home/BannerSection/Table";
import BtbAdvTwoSide from "./Components/Btb/Home/AdvTwoSide/Table";
import BtbAdvTwo from "./Components/Btb/Home/AdvTwo/Table";
import Trending_product from "./Components/Btb/Home/Trending_product/Table";
import NewArrival from "./Components/Btb/Home/NewArrival/Table";

// Success Stories
import SSuccessBanner from "./Components/Masters/S_SuccessStoriesBanner/Tables";
import SMoving from "./Components/Masters/S_Moving/Tables";
import SSurmount from "./Components/Masters/S_Surmount/Tables";
import SRelient from "./Components/Masters/S_SelfRelient/Tables";
import SWomens from "./Components/Masters/S_Women_Sellers/Tables";

import SFAQ from "./Components/Masters/S_FAQ/Tables";
import SSubFAQ from "./Components/Masters/S_Sub_FAQ/Tables";
import SBlog from "./Components/Masters/S_Blog/Tables";
import SBlogQuestion from "./Components/Masters/S_Sub_Blog/Tables";
import SSelectTopic from "./Components/Masters/S_Select_Topic/Tables";
import SFAQFeedback from "./Components/Masters/S_FAQ_Feedback/Tables";
import AffiliateDashboard from "./Components/affiliate/AffiliateDashboard/AffiliateDashboard";
import InhouseAds from "./Components/affiliate/InhouseAds/InhouseAds";
import SellerInfluencer from "./Components/affiliate/SellerInfluencer/SellerInfluencer";
import AffiliateActivity from "./Components/affiliate/Affiliate-Activity/AffiliateActivity";
import NewLink from "./Components/affiliate/New_Link/NewLink";
import AffilateUserManage from "./Components/affiliate/AffilateUserManage/AffilateUserManage";
import Stricks from "./Components/affiliate/Stricks/Stricks";
import Affiliate_Setting from "./Components/affiliate/settings/Affiliate_Setting";

// My Website
import MyWebsiteBanner from "./Components/MyWebsite/M_W_Banner/Tables";
import MyWebsiteDesigner from "./Components/MyWebsite/M_W_Designer/Tables";
import MyWebsiteReason from "./Components/MyWebsite/M_W_Reason/Tables";
import MyWebsiteReviews from "./Components/MyWebsite/M_W_Reviews/Tables";
import MyWebsiteFeature from "./Components/MyWebsite/M_W_Feature/Tables";
import MyWebsiteBlogs from "./Components/MyWebsite/M_W_Blog/Tables";
import MyWebsiteFaqCategory from "./Components/MyWebsite/M_W_Faq_Category/Tables";
import MyWebsiteFaq from "./Components/MyWebsite/M_W_Faq/Tables";
import MyWebsiteResources from "./Components/MyWebsite/M_W_Resources/Tables";
import MyWebsiteLeague from "./Components/MyWebsite/M_W_League/Tables";
import MyWebsitePlanType from "./Components/MyWebsite/M_W_Plan_Type/Tables";
import M_W_C_Features from "./Components/MyWebsite/M_W_C_Features/Tables";
import M_W_S_Features from "./Components/MyWebsite/M_W_S_Features/Tables";
import MyWebsitePlans from "./Components/MyWebsite/M_W_Plan/Tables";
import MyWebsitePortfoliosCat from "./Components/MyWebsite/M_W_portfolio_category/Tables";
import MyWebsitePortfolios from "./Components/MyWebsite/M_W_Portfolios/Tables";
import MyWebsiteSallerPlans from "./Components/MyWebsite/M_W_saller_plans/Tables";

import WebsitePages from "./Components/ApiDocumentation/WebsitePages/Tables";
import WebsiteApis from "./Components/ApiDocumentation/WebsiteApis/Tables";
import AllProducts from "./Components/seller/AllProducts/Table";
import UserOnboarding from "./Components/Masters/UserOnboarding/UserOnboarding";
import RTORefundReturn from "./Components/Masters/RTORefundReturn/RTORefundReturn";
import Afiliate from "./Components/Masters/Afiliate/Afiliate";
import ProductManager from "./Components/Masters/ProductManager/ProductManager";
import AccountPayment from "./Components/Masters/AccountPayment/AccountPayment";
import ProductBrand from "./Components/Masters/ProductBrand/ProductBrand";
import AffiliateInfluencerContent from "./Components/Masters/AffiliateInfluencerMain/AffiliateInfluencerContent";

import MyWebsiteSection from "./Components/MyWebsite/Section/Tables";
import MyWebsiteThemcategory from "./Components/MyWebsite/M_W_Them_category/Tables";
import MyWebsiteThemSubCategory from "./Components/MyWebsite/M_W_Them_subcategory/Tables";
import MyWebsiteThem from "./Components/MyWebsite/M_W_Them/Tables";
import DomainInformation from "./Components/MyWebsite/Domain_Information/Domain_Information";
import Calendar from "./Components/Calendar/Calendar";



import SupportQueries from "./Components/Support/Queries/Tables";
const App = () => {
  const location = useLocation();
  const { isSidebarOpen, setSidebarOpen } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  // useEffect(() => {
  //   setHeaderText(location.pathname);
  // });

  // Close sidebar by default
  useEffect(() => {
    setHeaderText(location.pathname);
    setSidebarOpen(false);
  }, []);
  return (
    <div className="container-fluid p-0">
      <div
        className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
      >
        {isLoginPage ? (
          <Login />
        ) : (
          <div className=" row">
            <div
              className={`${isSidebarOpen
                ? "col-xl-2 col-lg-2 col-md-3 pe-0 sidebarHeadOpen"
                : " col-xl-1 col-lg-1 col-md-2 pe-0 scale-in-hor-left sidebarHeadClose"
                } `}
            >
              <Sidebar />
            </div>
            <div
              className={`${isSidebarOpen
                ? "col-xl-10 col-lg-10 col-md-9  ps-0"
                : "col-xl-11 col-lg-11 col-md-10  ps-0 scale-in-hor-right dashboradopen"
                } `}
            >
              <div className="allRoutesMain">
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/Header" element={<Header />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/advanceDashboard"
                    element={<AdvanceDashboard />}
                  />

<Route
                    path="/calendar"
                    element={<Calendar />}
                  />

                  <Route path="/settingContent" element={<SettingContent />} />
                  <Route path="/userOnboarding" element={<UserOnboarding />} />
                  <Route
                    path="/rto-refund-return"
                    element={<RTORefundReturn />}
                  />
                  <Route path="/afiliate" element={<Afiliate />} />
                  <Route path="/product-manager" element={<ProductManager />} />
                  <Route path="/account-payment" element={<AccountPayment />} />
                  <Route path="/product-brand" element={<ProductBrand />} />
                  <Route
                    path="/affiliate-influencer"
                    element={<AffiliateInfluencerContent />}
                  />

                  <Route path="/employee/role" element={<Role />} />
                  <Route
                    path="/employee/employee-details"
                    element={<EmployeeDetails />}
                  />

                  <Route path="/seller/s-banner" element={<SBanner />} />
                  <Route path="/seller/s-cards" element={<SCards />} />
                  <Route path="/seller/s-smart" element={<SSellSmart />} />
                  <Route path="/seller/s-triumphs" element={<STriumphs />} />
                  <Route path="/seller/s-masterart" element={<SMasterArt />} />
                  <Route
                    path="/seller/s-mastersubart"
                    element={<SMasterArtSub />}
                  />
                  <Route path="/seller/languages" element={<Language />} />

                  <Route
                    path="/seller/s-success-banner"
                    element={<SSuccessBanner />}
                  />
                  <Route path="/seller/s-moving" element={<SMoving />} />
                  <Route path="/seller/s-surmount" element={<SSurmount />} />
                  <Route path="/seller/s-relient" element={<SRelient />} />
                  <Route path="/seller/s-womens" element={<SWomens />} />

                  <Route path="/seller/masters/s-faq" element={<SFAQ />} />
                  <Route
                    path="/seller/masters/s-sub-faq"
                    element={<SSubFAQ />}
                  />
                  <Route path="/seller/masters/s-blog" element={<SBlog />} />
                  <Route
                    path="/seller/masters/s-sub-blog"
                    element={<SBlogQuestion />}
                  />
                  <Route
                    path="/seller/masters/s-select-topic"
                    element={<SSelectTopic />}
                  />
                  <Route
                    path="/seller/masters/s-faq-feedback"
                    element={<SFAQFeedback />}
                  />

                  <Route
                    path="/seller/customer-management/personal-details"
                    element={<SPersonalDetails />}
                  />

                  <Route
                    path="/seller/customer-management/plan-details"
                    element={<SPlanDetails />}
                  />

                  <Route
                    path="/seller/customer-management/order-details"
                    element={<SOrderDetails />}
                  />
                  <Route
                    path="/media/customer-management/personal-details"
                    element={<MPersonalDetails />}
                  />
                  <Route
                    path="/checker/customer-management/personal-details"
                    element={<CPersonalDetails />}
                  />
                  <Route
                    path="/affiliate/customer-management/personal-details"
                    element={<APersonalDetails />}
                  />

                  <Route
                    path="/affiliate/customer-management/personal-details"
                    element={<APersonalDetails />}
                  />
                  <Route
                    path="/brand/customer-management/personal-details"
                    element={<BPersonalDetails />}
                  />
                  <Route
                    path="/transporter/customer-management/personal-details"
                    element={<TPersonalDetails />}
                  />
                  <Route
                    path="/three-p/customer-management/personal-details"
                    element={<ThreePPersonalDetails />}
                  />

                  <Route
                    path="/btc/customer-management/personal-details"
                    element={<BtcPersonalDetails />}
                  />

                  {/* BTB */}
                  <Route
                    path="/btb/customer-management/personal-details"
                    element={<BtbPersonalDetails />}
                  />

                  <Route
                    path="/btb/masters/advertisement-section"
                    element={<BtbAdvertisementSection />}
                  />

                  <Route
                    path="/btb/masters/banner-section"
                    element={<BtbBannerSection />}
                  />

                  <Route
                    path="/btb/masters/adv-two-side-section"
                    element={<BtbAdvTwoSide />}
                  />

                  <Route
                    path="/btb/masters/adv-two-section"
                    element={<BtbAdvTwo />}
                  />

                  <Route
                    path="/btb/masters/trending-product"
                    element={<Trending_product />}
                  />

                  <Route
                    path="/btb/masters/new-arrival"
                    element={<NewArrival />}
                  />

                  <Route
                    path="/btb/affiliate/affiliatedashboard"
                    element={<AffiliateDashboard />}
                  />

                  <Route
                    path="/btb/affiliate/inhouseads"
                    element={<InhouseAds />}
                  />

                  <Route
                    path="/btb/affiliate/seller-influencer"
                    element={<SellerInfluencer />}
                  />

                  <Route
                    path="/btb/affiliate/affiliate-activity"
                    element={<AffiliateActivity />}
                  />

                  <Route
                    path="/btb/affiliate/affilate-setting"
                    element={<Affiliate_Setting />}
                  />

                  <Route path="/btb/affiliate/new-link" element={<NewLink />} />
                  <Route
                    path="/btb/affiliate/stricks"
                    element={<Stricks />}
                  />
                  <Route
                    path="/btb/affiliate/affilate-user-management"
                    element={<AffilateUserManage />}
                  />

                  <Route
                    path="my-webite-home-banner"
                    element={<MyWebsiteBanner />}
                  />
                  <Route
                    path="my-webite-home-designer"
                    element={<MyWebsiteDesigner />}
                  />
                  <Route
                    path="my-webite-home-reason"
                    element={<MyWebsiteReason />}
                  />
                  <Route
                    path="my-webite-home-review"
                    element={<MyWebsiteReviews />}
                  />
                  <Route
                    path="my-webite-home-feature"
                    element={<MyWebsiteFeature />}
                  />
                  <Route
                    path="my-webite-home-blogs"
                    element={<MyWebsiteBlogs />}
                  />
                  <Route
                    path="my-webite-home-faq-category"
                    element={<MyWebsiteFaqCategory />}
                  />
                  <Route path="my-webite-home-faq" element={<MyWebsiteFaq />} />
                  <Route
                    path="my-webite-home-resources"
                    element={<MyWebsiteResources />}
                  />
                  <Route
                    path="my-webite-home-league"
                    element={<MyWebsiteLeague />}
                  />
                  <Route
                    path="my-webite-home-type-plan"
                    element={<MyWebsitePlanType />}
                  />
                  <Route path="m-w-c-feature" element={<M_W_C_Features />} />
                  <Route path="m-w-s-feature" element={<M_W_S_Features />} />
                  <Route
                    path="my-webite-category-them"
                    element={<MyWebsiteThemcategory />}
                  />
                  <Route
                    path="my-webite-subcategory-them"
                    element={<MyWebsiteThemSubCategory />}
                  />
                  <Route path="my-webite-them" element={<MyWebsiteThem />} />
                  <Route
                    path="my-webite-sections"
                    element={<MyWebsiteSection />}
                  />
                  <Route path="domain-information" element={<DomainInformation />} />
                  <Route
                    path="my-webite-home-plan"
                    element={<MyWebsitePlans />}
                  />
                  <Route
                    path="my-webite-portfolio-category"
                    element={<MyWebsitePortfoliosCat />}
                  />
                  <Route
                    path="my-webite-portfolios"
                    element={<MyWebsitePortfolios />}
                  />

                  <Route
                    path="api-documentation/website-page"
                    element={<WebsitePages />}
                  />
                  <Route
                    path="api-documentation/website-api"
                    element={<WebsiteApis />}
                  />
                  <Route
                    path="my-webite-seller-plans"
                    element={<MyWebsiteSallerPlans />}
                  />

                  <Route
                    path="/seller/product/all-products"
                    element={<AllProducts />}
                  />


                  {/* Support */}

                  <Route
                    path="support-queries"
                    element={<SupportQueries />}
                  />
                </Routes>
              </div>
              {/* <Home /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
