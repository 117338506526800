import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashboardClickGraph = () => {
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: [100, 200, 300, 400, 500, 600, 700],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Dataset 2',
                data: [700, 600, 500, 400, 300, 200, 100],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Click Chart',
                font: {
                    size: 25,
                    weight: 'bold',
                },
                color: 'black', // Set title color to black
            },
        },
    };

    return (
        <>
            <div className='customer-table mt-5'>
                <Bar data={data} options={options} />
            </div>
        </>
    );
};

export default DashboardClickGraph;