import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../../Tabels/Tabels.css";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../../common/ModelSave";
import { CancelButton } from "../../../common/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
library.add(fas);

const EditOffCanvance = (props) => {
  const { getData } = useContext(Context);

  const id = props.show;
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/seller/order-details/${id}`);
    setFormData(response?.data);
    console.log(formData);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Modal
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true} // Assuming you're controlling the modal visibility from outside
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Full Name:</Col>
                  <Col sm={9} className="text-center">{formData?.name}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Email:</Col>
                  <Col sm={9} className="text-center">{formData?.email}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Plan Name:</Col>
                  <Col sm={9} className="text-center">{formData?.phone}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Plan Bay Date:</Col>
                  <Col sm={9} className="text-center">{formData?.address || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Col sm={3} className="text-center">Plan Expired Date:</Col>
                  <Col sm={9} className="text-center">{formData?.message || 'N/A'}</Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* Buttons */}
          <Row className="justify-content-center mt-5 pb-3">
            <Col sm={6} className="d-flex justify-content-center">
              <CancelButton name={"Close"} handleClose={props.handleClose} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
