import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { faEye } from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./ViewOffCanvance.css";
import toast, { Toaster } from "react-hot-toast";
import { type } from "@testing-library/user-event/dist/type";
import { Context } from "../../../../utils/context";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { data } from "jquery";

library.add(fas);

const ViewOffCanvance = (props) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState();
  const getSellerData = async () => {
    const res = await getData(`/checker/personal-details/${props.id}`);
    setData(res.data);
    console.log(res);
  };

  useEffect(() => {
    getSellerData();
  }, [props.id]);

  console.log(data);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Checker Details </h3>
                </Card.Title>

                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Personal Details" value="1" />
                        <Tab label="Firm Details" value="2" />
                        <Tab label="Bank Details" value="3" />
                        <Tab label="Service Details" value="4" />
                        <Tab label="Branch Name" value="5" />{" "}
                        <Tab label="Attachment" value="6" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div>
                        <div>
                          <h3 className="titleName">Personal Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.f_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.l_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Contact Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.contact_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.email}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Designation</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.designation}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div>
                        <div>
                          <h3 className="titleName">Firm Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>GST</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.c_firm_detail?.gst_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>GST Document</Form.Label>

                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL + data?.c_firm_detail?.gst_image
                                      }
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.c_firm_detail?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_detail?.s_firm_type?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <div>
                              <h3>Communication Address</h3>
                            </div>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_address_detail?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Add Line One</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_address_detail
                                            ?.add_line_one
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Add Line Two</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_address_detail
                                            ?.add_line_two
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Country</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_address_detail?.country
                                            ?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>State</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_address_detail?.state
                                            ?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>City</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_address_detail?.city
                                            ?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>PinCode</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_firm_address_detail?.pincode
                                            ?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div>
                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Bank Account number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.c_bank_detail?.bank_account_no
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>IFSC Code</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.c_bank_detail?.ifsc_code}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Bank Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.c_bank_detail?.bank_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Branch Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.c_bank_detail?.branch_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>MICR code</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.c_bank_detail?.micr_code}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="4">
                      <div>
                        <div>
                          <h3>Service Area </h3>
                        </div>
                        <div>
                          {data?.c_service_details?.map((val, index) => (
                            <Row>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="5">
                      <div>
                        <div>
                          <h3>Branch Details</h3>
                        </div>
                        <div>
                          {data?.c_branch_details?.map((val, index) => (
                            <Row>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.branch_name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Bank Account Number</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.contact}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>
                                      Branch Contact Number
                                    </Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.branch_contct_person}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                              <h2> Branch Address</h2>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line One</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_one}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line Two</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_two}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Area</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.s_area?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="6">
                      <div>
                        <div>
                          <h3>Attachment </h3>
                        </div>
                        <div>
                          {data?.c_attachments?.map((val, index) => (
                            <Row>
                              <Col lg={4} className="">
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>LOGO</Form.Label>

                                    <div className="image-boxes-main">
                                      <img
                                        className="image-boxes"
                                        src={IMG_URL + val?.logo}
                                      ></img>
                                    </div>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4} className="">
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Banner</Form.Label>

                                    <div className="image-boxes-main">
                                      <img
                                        className="image-boxes"
                                        src={IMG_URL + val?.banner}
                                      ></img>
                                    </div>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4} className="">
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>
                                      Upload Agreement Copy
                                    </Form.Label>

                                    <div className="image-boxes-main">
                                      <img
                                        className="image-boxes"
                                        src={IMG_URL + val?.banner}
                                      ></img>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </>
  );
};

export default ViewOffCanvance;
