
import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const DashboardBarGraph = () => {
    const options = {
        backgroundColor: "transparent", // Set background color to transparent
        title: {
            text: "Customer Arrangement Chart",
            fontSize: 20, // Set font size of the title
            fontColor: "#333" // Set font color of the title
        },
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "column",
                color: "#4285F4",
                dataPoints: [
                    { label: "Apple", y: 10 },
                    { label: "Orange", y: 15 },
                    { label: "Banana", y: 25 },
                    { label: "Mango", y: 30 },
                    { label: "Grape", y: 28 },
                    { label: "Grape", y: 28 },
                    { label: "Grape", y: 28 }
                ]
            }
        ]
    }
    return (
        <>
            <div className='customer-table mt-5'>
                <CanvasJSChart options={options}
                /* onRef={ref => this.chart = ref} */
                />
            </div>
        </>
    )
}

export default DashboardBarGraph