import React, { useContext, useEffect, useState } from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { formatDate, formatDateObject, RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import Select from 'react-select';
import { eventCreate } from "../../../utils/apis/calendar";

const EventAddModal = (props) => {


  const { IMG_URL } = useContext(Context);

  const [eventType, setEventType] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    reset,
  } = useForm();



  const imageFile = watch("image");

  console.log(props?.startDate, 'props?.startDate');

  const options = [
    { value: "offline", label: "Offline" },
    { value: "online", label: "Online" },
  ];



  const { postData, getData, Select2Data } = useContext(Context);

  const [country, setCountry] = useState([]);
  const [state, setStates] = useState([]);
  const [city, setCities] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetAllCountries = async () => {
    const response = await getData("/common/seller/masters/allcountry");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getData(`/common/seller/masters/allstates/${id}`);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await getData(`/common/seller/masters/allcity/${id}`);

    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };




  const onSubmit = async (d) => {

    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    const finalData = new FormData();
    if(d.id){
      finalData.append('id', d.id);
    }
    finalData.append('image', d.image[0]);
    finalData.append('start', props?.startDate);
    finalData.append('end', props?.endDate);
    finalData.append('title', d.title);
    finalData.append('event_description', d.event_description);
    finalData.append('event_type', d.event_type);
    finalData.append('event_link', d.event_link);
    finalData.append('event_address', d.event_address);
    finalData.append('country_id', d.country_id);
    finalData.append('state_id', d.state_id);
    finalData.append('city_id', d.city_id);

    finalData.append('color', color);

    finalData.append('b2b_buyer_status', d.b2b_buyer_status);
    finalData.append('b2b_seller_status', d.b2b_seller_status);
    finalData.append('retail_b2c_status', d.retail_b2c_status);
    finalData.append('affiliate_status', d.affiliate_status);
    finalData.append('influencer_status', d.influencer_status);
    finalData.append('three_p_status', d.three_p_status);
    finalData.append('transport_status', d.transport_status);
    finalData.append('checker_status', d.checker_status);
    finalData.append('brand_status', d.brand_status);
    finalData.append('media_status', d.media_status);



    const res = await eventCreate(finalData);
    if (res?.success) {
      reset();
      props?.getEvents();
      props?.onHide();
    }



  }

  useEffect(() => {
    GetAllCountries();
    GetAllStates();
    GetAllCities();
  }, []);

  console.log(getValues());
  console.log(errors);
  console.log(props?.eventData);


  useEffect(() => {

    setValue('start', props?.startDate);
    setValue('end', props?.endDate);
    if(props?.eventData !== ""){
      reset({
        ...props?.eventData,
        country_id: { value: props?.eventData?.event_location_target?.country?.id, label: props?.eventData?.event_location_target?.country?.name },
        state_id: { value: props?.eventData?.event_location_target?.state?.id, label: props?.eventData?.event_location_target?.state?.name },
        city_id: { value: props?.eventData?.event_location_target?.city?.id, label: props?.eventData?.event_location_target?.city?.name },
        event_type: { value: props?.eventData?.event_type, label: props?.eventData?.event_type },
        b2b_buyer_status: props?.eventData?.event_target?.b2b_buyer_status,
        b2b_seller_status: props?.eventData?.event_target?.b2b_seller_status,
        retail_b2c_status: props?.eventData?.event_target?.retail_b2c_status,
        affiliate_status: props?.eventData?.event_target?.affiliate_status,
        influencer_status: props?.eventData?.event_target?.influencer_status,
        three_p_status: props?.eventData?.event_target?.three_p_status,
        checker_status: props?.eventData?.event_target?.checker_status,
        brand_status: props?.eventData?.event_target?.brand_status,
        media_status: props?.eventData?.event_target?.media_status,
      });
    }else{
      reset();
    }
   


  }, [props?.show]);




  return (
    <>
      <Modal className='mrp-modal' size="lg" {...props} centered >
        <Modal.Header closeButton onClick={() => {props.onHide(); props?.setEventData("");}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>




          <div className='row text-center'>
            <div className='col-12 mb-4'>
              <h6>Add Event</h6>
              <h6>({formatDateObject(props?.startDate)}) - ({formatDateObject(props?.endDate)})</h6>
            </div>
          </div>

          <div className='row text-center'>

            <Row>

              <Col xxl={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Event Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Event Name"
                    className={classNames("", {
                      "is-invalid": errors?.title,
                    })}
                    {...register("title", {
                      required: "Event Name is required",
                    })}

                  />
                </Form.Group>
              </Col>
              <Col xxl={6}>

              </Col>
              <Col xxl={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Image</Form.Label>

                  <Form.Control type="file" placeholder="Enter Image"
                    className={classNames("", {
                      "is-invalid": errors?.image,
                    })}
                    {...register("image", {
                      required: "Image is required",
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xxl={6}>

                {


                  typeof getValues('image') === "string" ? (
                    <img
                      src={IMG_URL + getValues('image')}
                      alt="Preview"
                      className="image-preview"
                      style={{ width: "150px", height: "130px" }}
                    />
                  ) : (
                    getValues('image') && getValues('image').length > 0 && (
                      <img
                        src={URL.createObjectURL(getValues('image')?.[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "150px", height: "130px" }}
                      />
                    )



                  )

                }
              </Col>

              <Col xxl={6}>
                <Form.Group className="mt-2 mb-3" controlId="formBasicEmail">
                  <Form.Label>Event Description</Form.Label>
                  <Form.Control as="textarea" type="text" placeholder="Enter Event Description"
                    className={classNames("", {
                      "is-invalid": errors?.event_description,
                    })}
                    {...register("event_description", {
                      required: "Description is required",
                    })}

                  />
                </Form.Group>
              </Col>
              <Col xxl={6}>

              </Col>



              <Col xxl={4}>
                <Form.Group className="mt-2 mb-3" controlId="formBasicEmail">
                  <Form.Label>Event Type</Form.Label>
                  <Controller
                    name="event_type"
                    {...register("event_type", {
                      required: "Event Type is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.event_type
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={options}
                        placeholder="--- Please select ---"
                        onChange={(e) => {
                          console.log(e);
                          setValue('event_link', ''); setValue('event_address', ''); setEventType(e.value); setValue('event_type', e);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Col>

              {eventType === 'online' && (
                <Col xxl={4}>
                  <Form.Group className="mt-2 mb-3" controlId="formBasicEmail">
                    <Form.Label>Event Link</Form.Label>
                    <Form.Control type="text" placeholder="Enter Event Link"
                      className={classNames("", {
                        "is-invalid": errors?.event_link,
                      })}
                      {...register("event_link", {
                        // required: "Date is required",
                      })}

                    />
                  </Form.Group>
                </Col>
              )}
              {eventType === 'offline' && (
                <Col xxl={4}>
                  <Form.Group className="mt-2 mb-3" controlId="formBasicEmail">
                    <Form.Label>Event Address</Form.Label>
                    <Form.Control as="textarea" type="text" placeholder="Enter Event Address"
                      className={classNames("", {
                        "is-invalid": errors?.event_address,
                      })}
                      {...register("event_address", {
                        // required: "Address is required",
                      })}

                    />
                  </Form.Group>
                </Col>
              )}




            </Row>

            <Row>
              <Col xxl={4}>
                <Form.Group className="mt-2 mb-3" controlId="formBasicEmail">
                  <Form.Label>Target Audience</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('b2b_buyer_status')}

                  onChange={(e) => {

                    if (e.target.checked) {
                      setValue(`b2b_buyer_status`, 1);
                    } else {
                      setValue(`b2b_buyer_status`, 0);
                    }
                  }}
                  label={'B2B Buyer'}
                />
              </Col>
              <Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('b2b_seller_status')}
                  onChange={(e) => {

                    if (e.target.checked) {
                      setValue(`b2b_seller_status`, 1);
                    } else {
                      setValue(`b2b_seller_status`, 0);
                    }

                  }}
                  label={'B2B Seller'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('retail_b2c_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`retail_b2c_status`, 1);
                    } else {
                      setValue(`retail_b2c_status`, 0);
                    }
                  }}
                  label={'Retail B2C Buyer'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('affiliate_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`affiliate_status`, 1);
                    } else {
                      setValue(`affiliate_status`, 0);
                    }
                  }}
                  label={'Affiliate'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('influencer_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`influencer_status`, 1);
                    } else {
                      setValue(`influencer_status`, 0);
                    }
                  }}
                  label={'Influencer'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('three_p_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`three_p_status`, 1);
                    } else {
                      setValue(`three_p_status`, 0);
                    }
                  }}
                  label={'3P Support'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('transport_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`transport_status`, 1);
                    } else {
                      setValue(`transport_status`, 0);
                    }
                  }}
                  label={'Transport'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('checker_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`checker_status`, 1);
                    } else {
                      setValue(`checker_status`, 0);
                    }
                  }}
                  label={'Checkers'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('brand_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`brand_status`, 1);
                    } else {
                      setValue(`brand_status`, 0);
                    }
                  }}
                  label={'Brand'}
                />
              </Col><Col xxl={4}>
                <Form.Check
                  type="checkbox"

                  checked={getValues('media_status')}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue(`media_status`, 1);
                    } else {
                      setValue(`media_status`, 0);
                    }
                  }}
                  label={'Media'}
                />


              </Col>


            </Row>

            <Row className="mt-4">

              <Col xxl={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Country</Form.Label>
                  <Controller
                    name="country_id"
                    {...register("country_id", {
                      // required: "Country is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.country_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={country}
                        placeholder="--- Please select ---"
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value); // Update Controller's value
                          GetAllStates(selectedOption.value);
                          setValue("country_id", selectedOption);
                          setValue("state_id", null);
                          setValue("city_id", null);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xxl={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>State</Form.Label>
                  <Controller
                    name="state_id"
                    {...register("state_id", {
                      // required: "State is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.state_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={state}
                        placeholder="--- Please select ---"
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value); // Update Controller's value
                          GetAllCities(selectedOption.value);
                          setValue("state_id", selectedOption);
                          setValue("city_id", null);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xxl={4}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>City</Form.Label>
                  <Controller
                    name="city_id"
                    {...register("city_id", {
                      // required: "City is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.city_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={city}
                        placeholder="--- Please select ---"
                      />
                    )}
                  />
                </Form.Group>
              </Col>

            </Row>

            <div className="mt-4">

              <button className="btn btn-success" onClick={handleSubmit(onSubmit)}>Save</button>

            </div>


          </div>


        </Modal.Body >
      </Modal >
    </>
  );
};

export default EventAddModal;
