import React, { useState, useContext, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Citytable from "../../City/Tables";
import InhouseActiveTaskContent from "../InhouseAdsContent/InhouseAdsContentTableDataContent/InhouseActiveTaskContent/Table";
import InhouseAddTaskContent from "../InhouseAdsContent/InhouseAdsContentTableDataContent/InhouseAddTaskContent/Table";
import Pincodetable from "../../Pincode/Tables";
import { Context } from "../../../../utils/context";
import InhouseDashboardContent from "./InhouseAdsContentTableDataContent/InhouseDashboardContent/InhouseDashboardContent";

const InhouseAdsContent = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const tabItems = [
    { key: 1, name: "Dashboard", link: "" },
    { key: 2, name: "Add Task", link: "" },
    { key: 3, name: "Active Task", link: "" },
  ];

  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="1"
        className="tabadminmain"
      >
        <Row className="me-0 ms-0">
          <Col xl={2} lg={2} md={4} className="p-0">
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={10} md={8} className=" p-0">
            <Tab.Content className="tabadminmain">
              {[149, 150, 151, 152, 153, 154].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="1">
                  <InhouseDashboardContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[155, 156, 157, 158, 159, 160].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="2">
                  <InhouseActiveTaskContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[161, 162, 163, 164, 165, 166].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="3">
                  <Citytable />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[167, 168, 169, 170, 171, 172].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="4">
                  {" "}
                  <InhouseAddTaskContent />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default InhouseAdsContent;
