import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SettingContent from "../SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import Select from "react-select";

import ReasonModalDynamic from "../../common/ReasonModalDynamic/PromptModalDynamic"
import SuccessModalDynamic from "../../common/SuccessModalDynamic/SuccessModalDynamic"
import { withrawPay } from "../../../utils/apis/wallet";
import * as XLSX from 'xlsx';
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    formatDateExpire,
    formatDate,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [searchStatus, setSearchStatus] = useState("");
  const [walletId, setWalletId] = useState("");
  const [razorModal, setRazorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const getDataAll = async () => {
    let url = `/payment/withdraw-requests/?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    const response = await getData(url);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/seller/masters/hsn-code/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/seller/masters/hsn-code/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
  });



  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });



  const withdrawTransaction = async (razor) => {
      setRazorModal(false);
    const res = await withrawPay({ id: walletId, razor: razor });
    if (res?.success) {
      setSuccessModal(true);
      getDataAll();
      setTimeout(() => {
            setWalletId("");
          setSuccessModal(false);
      }, 3000);
    }
  }


  const handleExcelExport = async () => {

    console.log(data);

    // Create worksheet data
    const clientInfoData = [
      ['Sr.No.', 'NPPID', 'UserID','TotalAmountWithdraw','AccountNo','IFSC Code','Bank Name','MICR Code','PaymentTransactionID'],
      []
    ];



    const transactionData = await Promise.all(data?.data?.data?.map(async (item, index) => {
    

      if(item?.wallet?.a_personal_detail !== null){
        return [
          index + 1,
          item?.affiliate_task_group?.npp_id,
          item?.wallet?.a_personal_detail !== null ? '#' + item?.wallet?.a_personal_detail?.af_id : '',
          item?.amount,
          item?.wallet?.a_personal_detail?.a_bank_detail?.bank_account_no ,
          item?.wallet?.a_personal_detail?.a_bank_detail?.ifsc_code ,
          item?.wallet?.a_personal_detail?.a_bank_detail?.bank_name ,
          item?.wallet?.a_personal_detail?.a_bank_detail?.micr_code ,
        ];
      }
      if(item?.wallet?.s_personal_detail !== null){
        return [
          index + 1,
          item?.affiliate_task_group?.npp_id,
          item?.wallet?.s_personal_detail !== null ? '#' + item?.wallet?.s_personal_detail?.s_id : '',
          item?.amount,
          item?.wallet?.s_personal_detail?.s_bank_detail?.bank_account_no,
          item?.wallet?.s_personal_detail?.s_bank_detail?.ifsc_code,
          item?.wallet?.s_personal_detail?.s_bank_detail?.bank_name,
          item?.wallet?.s_personal_detail?.s_bank_detail?.micr_code,
        ];
      }


       

     
    }));


    const worksheetData = clientInfoData.concat(transactionData);

    // Create worksheet and append data
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Set column widths
    ws['!cols'] = [
      { wch: 20 }, // Transaction Date
      { wch: 25 }, // Transaction segment
      { wch: 15 }, // Quantity
      { wch: 15 }, // Buy price
      { wch: 15 }, // Sell Price
      { wch: 15 }  // Net PnL
    ];


    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Append sheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Client Transactions');

    // Write workbook to file
    XLSX.writeFile(wb, 'Withdraw_requests'+ formatDate(new Date()) +'.xlsx');

    console.log('Excel file has been generated successfully.');
  };


  return (
    <>
      <div className="main-advancedashboard">
        <section className="AdvanceDashboard">
          <div className="row  me-0 ms-0  MainRowsec">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio ">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex add-btn-holder">
                      <div className="add me-3">
                        <b>Withdraw Requests</b>
                      </div>


                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="   col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex row-flex-holder">
                          <div className="show me-2">
                            <p className="show ">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show ">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="   col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex row-flex-holder justify-content-end">
                          <div className="show me-2">
                            <div className="d-flex">

                              <button className='btn btn-success ms-3 mt-3' onClick={handleExcelExport}>Excel Export</button>
                            </div>

                          </div>
                        </div>

                      </div>
                      <div className="   col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end entry-showing">
                          <div className="sowing me-2">
                            <p className="show ">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              placeholder="  Affiliate Id"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setSearchStatus("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {" "}
                            {visible.col0 && (
                              <th className="sr">
                                <input
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                  className="me-1"
                                ></input>
                              </th>
                            )}
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col7 && (
                              <th className="tax-name">NPP Id</th>
                            )}{" "}
                            {visible.col2 && (
                              <th className="tax-name">User Id</th>
                            )}{" "}
                            {visible.col3 && (
                              <th className="tax-name">User Type</th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name">Withdraw Amount</th>
                            )}
                            {visible.col6 && (
                              <th className="tax-name">Action</th>
                            )}{" "}

                            {/* {visible.col12 && (
                              <th className="active">Action</th>
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {isAllow.includes(263) ? (
                            data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {" "}
                                  {visible.col0 && (
                                    <td>

                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value={d?.id}
                                        name="perselected"
                                        checked={selectAllChecked.includes(
                                          d?.id
                                        )}
                                        onChange={handleChange}
                                        id="flexCheckDefault"
                                      ></input>
                                    </td>
                                  )}
                                  {visible.col1 && <td>{++index}.{d.id}</td>}{" "}
                                  {visible.col7 && <td>{d?.affiliate_task_group?.npp_id}</td>}{" "}
                                  {visible.col2 && (
                                    <td>
                                      {d?.wallet?.a_personal_detail !== null ? '#' + d?.wallet?.a_personal_detail?.af_id : ''}
                                      {d?.wallet?.s_personal_detail !== null ? '#' + d?.wallet?.s_personal_detail?.s_id : ''}

                                    </td>
                                  )}{" "}
                                  {visible.col3 && (
                                    <td>
                                      {d?.wallet?.a_personal_detail !== null ? 'Affiliate' : ''}
                                      {d?.wallet?.s_personal_detail !== null ? 'Seller' : ''}
                                    </td>
                                  )}

                                  {visible.col4 && (
                                    <td>
                                      ₹ {d?.amount}
                                    </td>
                                  )}

                                  {visible.col6 && (
                                    <td>
                                      {d?.razor_id === null ? (
                                        <button className="btn btn-success" onClick={() => { setWalletId(d?.id); setRazorModal(true); }}>Pay</button>
                                      ):(
                                        'Payed'
                                      )}

                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ReasonModalDynamic show={razorModal} onHide={() => setRazorModal(false)}
        button={"Submit"}
        text={"Please provide transaction id"}
        placeholder={"Trnsaction Id"}
        handleReasonFunction={withdrawTransaction}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
