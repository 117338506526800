import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Tabels/Tabels.css";
import plus from "../../../../Components/assets/icons/a1.png";
import colunms from "../../../../Components/assets/icons/LINES.png";
import search1 from "../../../../Components/assets/icons/search.png";
import top from "../../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";

import ModalDelete from "../../../common/ModelDelete";
import ModelBulkUpload from "../../../common/ModelBulkUpload";
import {
  AddButton,
  EditButton,
  DeletButton,
  ViewButton,
} from "../../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { Button } from "react-bootstrap";
import OffcanvasCon from "../../../OffcanvasCon/OffcanvasCon";
import parse from "html-react-parser";

import PanCanvas from "./Pan";
import GstCanvas from "./Gst";
import BankCanvas from "./BankDetails";
import ViewOffCanvance from "./ViewOffCanvance";
import DepositModal from "../../../common/mrp-modal/DepositModal";
import SuccessModalDynamic from "../../../common/SuccessModalDynamic/SuccessModalDynamic";
import { walletUpdate } from "../../../../utils/apis/wallet";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);

  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);

  const [showGst, setShowGst] = useState(0);
  const [showPan, setShowPan] = useState(0);
  const [showBank, setShowBank] = useState(0);
  const getDataAll = async () => {
    const response = await getData(
      `/affiliate/personal-details?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, showGst]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/affiliate/personal-details/${id}`);
    setChangeStatus(response);
  };

  const [view, setview] = useState(0);
  const [type, setType] = useState("");
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState("");
  const [walletData, setWalletData] = useState("");
  const [walletModal, setWalletModal] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);

  


  const handleWalletFunction = async () => {

    setWalletModal(false);
    const senddata = {
      wallet_id: walletData?.id,
      amount: amount,
      note: note,
      type:type,
    }
    const res = await walletUpdate(senddata);
    if(res?.success){
      setAmount(0);
      setSuccessModalShow(true);
      getDataAll();
      setTimeout(() => {
          setSuccessModalShow(false);
      }, 3000);
    }
  }

  
  const handleViewClose = () => setview(false);
  const handleViewshow = async (id) => {
    console.log(id);
    await setview(id);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleCloseGst = () => setShowGst(0);
  const handleShowGst = (id) => {
    setShowGst(id);
  };

  const handleClosePan = () => setShowPan(0);
  const handleShowPan = (id) => {
    setShowPan(id);
  };

  const handleCloseBank = () => setShowBank(0);
  const handleShowBank = (id) => {
    setShowBank(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/affiliate/personal-details/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const Export = async () => {
    try {
      await getDownloadDataExcel(
        "/affiliate/personal-details/export",
        { ids: selectAllChecked },
        "Affiliate Personal Details"
      );
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header
          title={"Affiliate's Personal Details"}
          link={"/affiliate/personal-details"}
        />
        <section className="AdvanceDashboard">
          <div className="row  me-0 ms-0  MainRowsec">
            <div className="col-lg-12">
              <section className="Tabels talentCategory_Table tab-radio tab-radio mt-5">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex add-btn-holder">
                      <div className="add me-3">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={colunms} className="columns " alt="" />
                            Column Selection
                            <img src={top} className="top ms-1" alt="" />
                          </button>
                          {isAllow.includes(113) ? (
                            <button
                              className="btn btn-columns ms-2"
                              type="button"
                              onClick={() => {
                                if (selectAllChecked.length === 0) {
                                  alert("Please Select At Least One Record");
                                } else {
                                  Export();
                                }
                              }}
                            >
                              Export
                            </button>
                          ) : (
                            <></>
                          )}
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                First Name
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Last Name
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                E-Mail
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Contact
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Status
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                View
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Bank Details
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="   col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex row-flex-holder">
                          <div className="show me-2">
                            <p className="show ">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show ">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="   col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end entry-showing">
                          <div className="sowing me-2">
                            <p className="show ">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {" "}
                            {visible.col0 && (
                              <th className="sr">
                                <input
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                  className="me-1"
                                ></input>
                              </th>
                            )}
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">First Name</th>
                            )}
                            {visible.col3 && (
                              <th className="tax-name">First Name</th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name">E-mail</th>
                            )}
                            {visible.col7 && (
                              <th className="tax-name">Contact</th>
                            )}
                            {visible.col8 && (
                              <th className="tax-name">Wallet Amount</th>
                            )}


                            {visible.col9 && (
                              <th className="tax-name">Wallet Action</th>
                            )}
                            {visible.col5 && (
                              <th className="tax-name">Status</th>
                            )}
                            {isAllow.includes(143) ? (
                              visible.col6 && <th className="active">View</th>
                            ) : (
                              <></>
                            )}
                            {/* {visible.col7 && (
                              <th className="active">Bank Details</th>
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          {isAllow.includes(112) ? (
                            data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col0 && (
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value={d?.id}
                                        name="perselected"
                                        checked={selectAllChecked.includes(
                                          d?.id
                                        )}
                                        onChange={handleChange}
                                        id="flexCheckDefault"
                                      ></input>
                                    </td>
                                  )}
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && <td>{d?.f_name}</td>}
                                  {visible.col3 && <td> {d?.l_name}</td>}
                                  {visible.col4 && <td>{d?.email}</td>}{" "}
                                  {visible.col7 && <td>{d?.contact_no}</td>}
                                  {visible.col8 && <td>₹ {d?.wallet?.amount}</td>}
                                  {visible.col9 && <td>
                                    <div className="d-flex">
                                      <FontAwesomeIcon
                                        className="btn btn-success ms-4"
                                        icon="fa-solid fa-add"
                                        title="Add Amount"
                                        onClick={() => {setWalletData(d?.wallet); setType("add"); setWalletModal(true);}}
                                      />
                                      <FontAwesomeIcon
                                        className="btn btn-danger ms-4"
                                        icon="fa-solid fa-minus"
                                        title="Deduct Amount"
                                        onClick={() => {setWalletData(d?.wallet); setType("minus"); setWalletModal(true);}}
                                      />
                                    </div>
                                  </td>}
                                  {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {isAllow.includes(143) ? (
                                    visible.col6 && (
                                      <td>
                                        <div className="add me-3">
                                          {" "}
                                          <Link
                                            type="button"
                                            className="btn btn-add pe-3"
                                          >
                                            <div
                                              onClick={() =>
                                                handleViewshow(d.id)
                                              }
                                            >
                                              view
                                            </div>
                                          </Link>
                                        </div>
                                      </td>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  {/* {visible.col7 && (
                                    <td>
                                      <ViewButton
                                        handleShow1={handleShowBank}
                                        id={d?.id}
                                      />
                                    </td>
                                  )} */}
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {view ? (
        <ViewOffCanvance
          handleClose={handleViewClose}
          setShow={setview}
          show={view}
          id={view}
        />
      ) : (
        ""
      )}

      <DepositModal show={walletModal} onHide={() => {setAmount(0); setNote(""); setWalletModal(false)}} handleWalletFunction={handleWalletFunction} walletData={walletData} amount={amount} setAmount={setAmount} note={note} setNote={setNote} type={type} />
      <SuccessModalDynamic show={successModalShow} onHide={() => setSuccessModalShow(false)} text={ type === 'add' ? "Amount Added to wallet!" : "Amount Deducted from wallet"} />


      {/* {showPan ? (
        <PanCanvas
          handleClose={handleClosePan}
          setShow={setShowPan}
          show={showPan}
        />
      ) : (
        ""
      )}

      {showGst ? (
        <GstCanvas
          handleClose={handleCloseGst}
          setShow={setShowGst}
          show={showGst}
        />
      ) : (
        ""
      )}

      {showBank ? (
        <BankCanvas
          handleClose={handleCloseBank}
          setShow={setShowBank}
          show={showBank}
        />
      ) : (
        ""
      )} */}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
