import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension, getData, Select2Data } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    removeOption,
  } = useForm();

  const imageFile = watch("image");
  const descriptionValue = watch("description");

  const [category, setCountries] = useState([]);
  const [leagues, setLeagues] = useState([]);

  const [error, setErrors] = useState("");
  const GetAllPlanType = async () => {
    const response = await getData("/common/my-website/masters/allPlanType");

    if (response?.success) {
      setCountries(await Select2Data(response?.data, "plan_type_id"));
    }
  };

  const GetAllLeagues = async () => {
    const response = await getData("/common/my-website/masters/allLeague");

    if (response?.success) {
      setLeagues(await Select2Data(response?.data, "league_id"));
    }
  };

  const [theme, setTheme] = useState([]);

  const GetAllTheme = async () => {
    const response = await getData("/common/my-website/masters/allTheme");

    if (response?.success) {
      setTheme(await Select2Data(response?.data, "theme_id"));
    }
  };

  const [cfeatures, setCFeatures] = useState([]);
  const GetAllCFeatures = async () => {
    const response = await getData("/my-website/masters/masters/c-features");
    if (response?.success) {
      setCFeatures(response?.data);
    }
  };

  useEffect(() => {
    GetAllPlanType();
    GetAllLeagues();
    GetAllTheme();
    GetAllCFeatures();
  }, []);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const response = await postData(`/my-website/pricing/plan`, data);
      if (response?.success) {
        await setShowModal({
          code: response.code,
          message: response.message,
        });
      } else {
        await setShowModal({
          code: response.code,
          message: response.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getValues());

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>League</Form.Label>
                    <Controller
                      name="league_id"
                      {...register("league_id", {
                        required: "Select League",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.league_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={leagues}
                        />
                      )}
                    />
                    {errors.league_id && (
                      <span className="text-danger">
                        {errors.league_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col> */}

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Plan Type</Form.Label>
                    <Controller
                      name="plan_type_id" // name of the field
                      {...register("plan_type_id", {
                        required: "Select Plan Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          // isMulti
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.plan_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={category}
                          onChange={(selectedOptions) => {
                            field.onChange(selectedOptions);
                          }}
                        />
                      )}
                    />
                    {errors.plan_type_id && (
                      <span className="text-danger">
                        {errors.plan_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col> */}

              <div className="main-form-section mt-3">
                <Row>
                  <Col md={4}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Plan</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Plan Name"
                              className={classNames("", {
                                "is-invalid": errors?.name,
                              })}
                              {...register(`name`, {
                                required: "Name is required",
                              })}
                            />
                          </InputGroup>
                          {errors.name && (
                            <span className="text-danger">
                              {errors.name.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Validity Days</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="day"
                              placeholder="Days"
                              className={classNames("", {
                                "is-invalid": errors?.day,
                              })}
                              {...register("day", {
                                required: "Day is required",
                              })}
                            />
                          </InputGroup>
                          {errors.day && (
                            <span className="text-danger">
                              {errors.day.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>MRP</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="mrp"
                              placeholder="MRP"
                              className={classNames("", {
                                "is-invalid": errors?.mrp,
                              })}
                              {...register(`mrp`, {
                                required: "MRP is required",
                              })}
                            />
                          </InputGroup>
                          {errors.mrp && (
                            <span className="text-danger">
                              {errors.mrp.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Selling Prize</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="selling_price"
                              placeholder="Selling Price"
                              className={classNames("", {
                                "is-invalid": errors?.selling_price,
                              })}
                              {...register(`selling_price`, {
                                required: "Selling Price is required",
                              })}
                            />
                          </InputGroup>
                          {errors.selling_price && (
                            <span className="text-danger">
                              {errors.selling_price.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Product</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="product"
                              placeholder="Product"
                              className={classNames("", {
                                "is-invalid": errors?.product,
                              })}
                              {...register(`product`, {
                                required: "Product is required",
                              })}
                            />
                          </InputGroup>
                          {errors.product && (
                            <span className="text-danger">
                              {errors.product.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Video</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="video"
                              placeholder="Video"
                              className={classNames("", {
                                "is-invalid": errors?.video,
                              })}
                              {...register(`video`, {
                                required: "Video is required",
                              })}
                            />
                          </InputGroup>
                          {errors.video && (
                            <span className="text-danger">
                              {errors.video.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="main-form-section mt-3">
                      <Row className="row justify-content-center mb-2">
                        <Form.Label>Theme</Form.Label>
                        <Controller
                          name="theme_id" // name of the field
                          {...register(`theme_id`, {
                            required: "Select Plan Type",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              isMulti
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.theme_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={theme}
                            />
                          )}
                        />
                        {errors.theme_id && (
                          <span className="text-danger">
                            {errors.theme_id.message}
                          </span>
                        )}
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Col sm={12}>
                          <Form.Label className="text-center">
                            Description
                          </Form.Label>
                          <Form.Group>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field }) => (
                                <JoditEditor
                                  value={descriptionValue || ""}
                                  onBlur={(newContent) => {
                                    setValue(`description`, newContent);
                                    trigger("description");
                                  }}
                                />
                              )}
                            />
                          </Form.Group>
                          {errors.description && (
                            <span className="text-danger">
                              {errors.description.message}
                            </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  {cfeatures?.map((feature, featureIndex) => (
                    <Col lg={12} key={feature.id || featureIndex}>
                      <div className="main-form-section mt-3">
                        <Form.Label className="text-center">
                          {feature.name}
                        </Form.Label>
                        <Row className="justify-content-start">
                          {feature.m_w_s_features?.map(
                            (sfeature, sfeatureIndex) => (
                              <Col sm={3} key={sfeature.id || sfeatureIndex}>
                                <Form.Group>
                                  <Form.Check
                                    type="checkbox"
                                    // defaultChecked={getValues(
                                    //   `features`
                                    // ).includes(sfeature?.id)}

                                    onChange={(e) => {
                                      let ids = getValues(`features`) || [];
                                      if (e.target.checked) {
                                        ids.push(sfeature.id);
                                      } else {
                                        ids = ids.filter(
                                          (id) => id !== sfeature.id
                                        );
                                      }
                                      setValue(`features`, ids);
                                      // console.log(getValues(`features`));
                                      // console.log(e.target.checked);
                                    }}
                                    label={sfeature.name}
                                  />
                                </Form.Group>
                              </Col>
                            )
                          )}
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
                {/* <button
                  className="mt-3"
                  type="button"
                  onClick={() => removedata(index)}
                >
                  Remove Variant
                </button> */}
              </div>

              <div className="main-form-section mt-3"></div>
              <hr />

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
