import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Context } from "../../../utils/context";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import {
  faCartShopping,
  faBoxOpen,
  faUsers,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { Chart } from "react-google-charts";
import DataTable from "datatables.net";
import Button from "react-bootstrap/Button";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

library.add(fas);

const AdvanceDashboard = () => {
  const { getData } = useContext(Context);
  const [result, setResult] = useState({});
  const getDataAll = async () => {
    const result = await getData("/dashboard");
    setResult(result.data);
  };
  // console.log(result);
  useEffect(() => {
    // getDataAll();
  }, []);

  // search functionality start

  const [filter, setFilter] = useState("");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const cardsData = [
    {
      number: "1492",
      types: "Orders",
      icon: "fa-cart-shopping",
      className: "#63bff5",
    },
    {
      number: "116",
      types: "Products",
      icon: "fa-solid fa-star",
      className: "#63bff5",
    },
    {
      number: "1094",
      types: "Customers",
      icon: "fa-solid fa-user",
      className: "#63bff5",
    },
    {
      number: "6",
      types: "Sellers",
      icon: "fa-solid fa-users",
      className: "#63bff5",
    },
  ];
  const options = {
    data: [
      {
        color: "#4285f4",
        width: 100,
        dataPoints: [
          { label: "29-March", y: 10 },
          { label: "28-March", y: 15 },
          { label: "20-March", y: 2 },
          { label: "18-March", y: 30 },
          { label: "13-March", y: 18 },
          { label: "27-March", y: 10 },
          { label: "30-March", y: 20 },
        ],
      },
    ],
  };

  // pie chart
  const dataOne = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const optiontwo = {
    title: "Product Category Count",
    is3D: true,
  };

  // close button
  const [showBox, setShowBox] = useState(true);

  const handleClose = () => {
    setShowBox(false);
  };

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  useEffect(() => {
    const table = new DataTable("#exampleContent");
    return () => {
      table.destroy();
    };
  }, []);

  const datanew = [
    {
      Name: "Tiger Nixon",
      Position: "System Architect",
      Office: "Edinburgh",
      Age: 61,
      StartDate: "2011-04-25",
      Salary: "$320,800",
    },
    {
      Name: "Garrett Winters",
      Position: "Accountant",
      Office: "Tokyo",
      Age: 63,
      StartDate: "2011-07-25",
      Salary: "$170,750",
    },
  ];

  const datacontent = [
    {
      Name: "Tiger Nixon",
      Position: "System Architect",
      Office: "Edinburgh",
      Age: 61,
      StartDate: "2011-04-25",
      Salary: "$320,800",
    },
    {
      Name: "Garrett Winters",
      Position: "Accountant",
      Office: "Tokyo",
      Age: 63,
      StartDate: "2011-07-25",
      Salary: "$170,750",
    },
  ];

  const colors = ["#63bff5", "#f39c12", "#dd4b39", "#00a65a"];

  return (
    <div className="main-advancedashboard">
      <section className="AdvanceDashboard">
        <div className="AdvanceDashboardTabs">
          <Header title={"Dashboard"} link={"/masters/social_links"} />

          <div className=""></div>
        </div>
      </section>
      <div className="Main-Section-Advanced-Dashboard">
        <h1 className="title">Home</h1>
        <Row className="">
          {cardsData.map((item, index) => (
            <Col xxl={3} xl={3} lg={3} md={6}>
              <Card
                className="small-box"
                style={{ backgroundColor: colors[index % colors.length] }}
              >
                <div className={item.className}>
                  <div className="content">
                    <div class="inner">
                      <h3>{item.number}</h3>
                      <p>{item.types}</p>
                    </div>
                    <div class="icon">
                      <FontAwesomeIcon icon={item.icon} />
                    </div>
                  </div>

                  <p className="mb-0 small-box-footer">
                    More info{" "}
                    <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                  </p>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <div className="Map-Section">
        <Row>
          <Col xxl={6} className="my-3">
            <div className="card cardbox box-success">
              <div className="card-body">
                <p className="text-one">Weekly sales</p>
                <p className="text">Total Sale in last week</p>
                <div className="Bar-Chart">
                  <CanvasJSChart
                    options={options}
                    containerProps={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={6} className="my-3">
            <div className="box box-success-two">
              <Chart
                chartType="PieChart"
                data={dataOne}
                options={optiontwo}
                width={"100%"}
                height={"400px"}
              />
            </div>
          </Col>
        </Row>
        {/* Tabel Section started */}

        <Row className="">
          <Col xxl={6} className="my-3">
            {showBox && (
              <div className="box-two box-warning">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h3 className="box-title">
                        Top Sellers <small>( Month: Mar)</small>
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <table
                        id="example"
                        className="display"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Office</th>
                            <th>Age</th>
                            <th>Start date</th>
                            <th>Salary</th>
                          </tr>
                        </thead>
                        <tbody>
                          {datanew.map((item, index) => (
                            <tr key={index}>
                              <td>{item.Name}</td>
                              <td>{item.Position}</td>
                              <td>{item.Office}</td>
                              <td>{item.Age}</td>
                              <td>{item.StartDate}</td>
                              <td>{item.Salary}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
          </Col>
          <Col xxl={6} className="my-3">
            {showBox && (
              <div className="box-two box-warning">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h3 className="box-title">
                        Top Sellers <small>( Month: Mar)</small>
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <table
                        id="exampleContent"
                        className="display"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Office</th>
                            <th>Age</th>
                            <th>Start date</th>
                            <th>Salary</th>
                          </tr>
                        </thead>
                        <tbody>
                          {datacontent.map((item, index) => (
                            <tr key={index}>
                              <td>{item.Name}</td>
                              <td>{item.Position}</td>
                              <td>{item.Office}</td>
                              <td>{item.Age}</td>
                              <td>{item.StartDate}</td>
                              <td>{item.Salary}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdvanceDashboard;
