import React, { useState, useContext, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./SettingContent.css";
import SArea from "../S_Area/Tables";
import ShipingServiceType from "../Shiping_Service_Type/Tables";
import { Context } from "../../../utils/context";
import LocationContent from "./LocationContent/LocationContent";
import MarginContent from "./MarginContent/MarginContent";

import PolicyContent from "./PolicyContent/PolicyContent";

const SettingContent = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const tabItems = [
    { key: 1, name: "Masters", link: "masters/country" },
    { key: 2, name: "Gio location data ( Area)", link: "masters/state" },
    { key: 3, name: "Shiping type", link: "masters/city" },
    { key: 4, name: "margin", link: "masters/pincode" },
    { key: 5, name: "Policy", link: "masters/policy" },
  ];

  return (
    <>
      <Header title={tabItems[active]?.name} link={"/masters/country"} />

      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="1"
        className="tabadminmain"
      >
        <Row className="me-0 ms-0">
          <Col xl={2} lg={2} md={4}>
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={10} md={8} className=" p-0">
            <Tab.Content className="tabadminmain">
              {[149, 150, 151, 152, 153, 154].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="1">
                  <LocationContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[155, 156, 157, 158, 159, 160].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="2">
                  <SArea />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[161, 162, 163, 164, 165, 166].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="3">
                  <ShipingServiceType />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[167, 168, 169, 170, 171, 172].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="4">
                  {" "}
                  <MarginContent />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}

              {[173, 174, 175, 176, 177, 178].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="5">
                  {" "}
                  <PolicyContent />{" "}
                </Tab.Pane>
              ) : (
                <></>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default SettingContent;
