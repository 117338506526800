import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

export const WorkingType = async () => {
  try {
    return await getData(`/common/seller/masters/working-type`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/common/seller/masters/allcountry`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/common/seller/masters/allstates/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/common/seller/masters/allcity/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (id) => {
  try {
    return await getData(`/common/seller/masters/allpincode/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Category = async (id) => {
  try {
    return await getData(`/common/seller/masters/allcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/common/seller/masters/allsubcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildCategory = async (id) => {
  try {
    return await getData(`/common/seller/masters/allchildcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/common/seller/masters/allchildsubcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Attributes = async () => {
  try {
    return await getData(`/common/seller/masters/allattributes`);
  } catch (error) {
    console.log(error);
  }
};

export const SubAttributes = async (id) => {
  try {
    return await getData(`/common/seller/masters/all-s-sub-attributes/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const AffiliatedSector = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-affiliated-sector`);
  } catch (error) {
    console.log(error);
  }
};

export const CountryCode = async () => {
  try {
    return await getData(`/common/seller/masters/allcountrycode`);
  } catch (error) {
    console.log(error);
  }
};

export const Area = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-area`);
  } catch (error) {
    console.log(error);
  }
};

export const Brand = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-brand`);
  } catch (error) {
    console.log(error);
  }
};

export const Custom = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-custom`);
  } catch (error) {
    console.log(error);
  }
};

export const MarginSettings = async () => {
  try {
    return await getData(`/common/seller/masters/margin-settings`);
  } catch (error) {
    console.log(error);
  }
};

export const MinimunMariginSettings = async () => {
  try {
    return await getData(`/common/seller/masters/min-margin-settings`);
  } catch (error) {
    console.log(error);
  }
};

export const Customization = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-customization`);
  } catch (error) {
    console.log(error);
  }
};

export const Days = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-days`);
  } catch (error) {
    console.log(error);
  }
};

export const FirmType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-firm-type`);
  } catch (error) {
    console.log(error);
  }
};

export const GST = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-gst`);
  } catch (error) {
    console.log(error);
  }
};

export const OrderStatus = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-order-status`);
  } catch (error) {
    console.log(error);
  }
};

export const ProductInfo = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-product-info`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceTerm = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-service-term`);
  } catch (error) {
    console.log(error);
  }
};

export const ServiceType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-service-type`);
  } catch (error) {
    console.log(error);
  }
};

export const Unit = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-unit`);
  } catch (error) {
    console.log(error);
  }
};

export const VarientType = async () => {
  try {
    return await getData(`/common/seller/masters/all-s-varient-type`);
  } catch (error) {
    console.log(error);
  }
};

export const updateExchangeApi = async () => {
  try {
    return await postData(`/seller/masters/currency/exchange/update`);
  } catch (error) {
    console.log(error);
  }
};

export const getSupplierProducts = async (
  id,
  category_id,
  sub_category_id,
  min,
  max,
  quantity,
  working_type_id,
  customization_id,
  attributes_id,
  sub_attributes_id
) => {
  try {
    let url = `/btb/best-seller/supplier-products/${id}?`;

    if (category_id) {
      url += `&category_id=${category_id}`;
    }

    if (sub_category_id) {
      url += `&sub_category_id=${sub_category_id}`;
    }

    if (min !== undefined && min !== "") {
      url += `&min=${min}`;
    }

    if (max !== undefined && max !== "") {
      url += `&max=${max}`;
    }

    if (quantity !== undefined && quantity !== "") {
      url += `&quantity=${quantity}`;
    }

    if (attributes_id !== undefined && attributes_id !== "") {
      url += `&attributes_id=${attributes_id.value}`;
    }

    if (sub_attributes_id?.length) {
      url += `&sub_attributes_id=${sub_attributes_id?.map((id) => id.value)}`;
    }

    if (working_type_id?.length) {
      url += `&working_type_id=${working_type_id}`;
    }

    if (customization_id?.length) {
      url += `&customization_id=${customization_id}`;
    }

    // const res = await getData(
    //   `/btb/best-seller/products?&sub_category_id=${sub_category_id}&min=${min}&max=${max}&quantity=${quantity}&attributes_id=${
    //     attributes_id.value
    //   }&sub_attributes_id=${sub_attributes_id?.map(
    //     (id) => id.value
    //   )}&working_type_id=${working_type_id}&customization_id=${customization_id}&`
    // );

    const res = await getData(url);
    return res;
  } catch (error) {
    console.log(error);
  }
};



export const getMainProductDetails = async (id) => {
  try {
    const res = await getData(`/btb/product-main/products/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};



export const btobCartAdd = async (data) => {
  try {
    if (Cookies.get("net_purti_security")) {
      const res = await postData(`/btb/cart/add`, data);
      return true;
    } else {
      let cartItems = [];

      const existingCart = Cookies.get("btb_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.product_id === data?.product_id &&
          item.seller_id === data?.seller_id &&
          item.s_p_v_a_d_id === data?.s_p_v_a_d_id &&
          item.s_p_v_a_one_d_a_d_id === data?.s_p_v_a_one_d_a_d_id &&
          item.s_p_v_a_o_d_a_d_a_d_id === data?.s_p_v_a_o_d_a_d_a_d_id
      );

      if (existingItemIndex === -1) {
        const newItem = {
          cart_id: uuidv4(),
          product_id: data.product_id,
          seller_id: data.seller_id,
          s_p_v_a_d_id: data.s_p_v_a_d_id,
          s_p_v_a_one_d_a_d_id: data.s_p_v_a_one_d_a_d_id,
          s_p_v_a_o_d_a_d_a_d_id: data.s_p_v_a_o_d_a_d_a_d_id,
          min_quantity: data.min_quantity,
          quantity: data.min_quantity,
          selling_price: data.selling_price,
          sku_id: data.sku_id,
          product_title: data.product_title,
        };
        cartItems.push(newItem);
      } else {
        cartItems[existingItemIndex].quantity++;
      }
      Cookies.set("btb_cart", JSON.stringify(cartItems));
    }

    return true;
  } catch (error) {
    console.log(error);
  }
};

