import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../../common/ModelSave";
import { CancelButton, SaveButton } from "../../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import JoditEditor from "jodit-react";
import classNames from "classnames";
library.add(fas);

const BankDetails = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL ,getDimension} = useContext(Context);
  const [data , setData ] = useState({});
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/media/personal-details/${id}`);
    setData(response?.data);
   
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");
  const bannerimageFile = watch("banner_image");

  

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Media's Bank Deatils : - {data?.f_name} {data?.l_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form role="form">
            <Row>

            <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Group>
                      <p>{data?.media_bank_detail?.bank_name}</p>
                    </Form.Group>
                  </Row>
                </div>
                </Col>
                <Col md={6}>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Account No. </Form.Label>
                    <Form.Group>
                      <p>{data?.media_bank_detail?.bank_account_no}</p>
                    </Form.Group>
                  </Row>
                </div>
                </Col>
                <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>IFSC Code </Form.Label>
                    <Form.Group>
                      <p>{data?.media_bank_detail?.ifsc_code}</p>
                    </Form.Group>
                  </Row>
                </div>
            </Col>
            <Col md={6}>

                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Branch Name </Form.Label>
                    <Form.Group>
                      <p>{data?.media_bank_detail?.branch_name}</p>
                    </Form.Group>
                  </Row>
                </div>
                </Col>

                <Col md={6}>

<div className="main-form-section mt-3">
  <Row className="justify-content-center">
    <Form.Label>MICR </Form.Label>
    <Form.Group>
      <p>{data?.media_bank_detail?.micr_code}</p>
    </Form.Group>
  </Row>
</div>
</Col>

            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default BankDetails;
