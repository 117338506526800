
import React, {useState} from 'react'
import { Col, Nav, Row, Tab, Form, Pagination, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';


const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

const sellerdata = [
    {
        affilateId: "A01",
        platform: "Linkdin",
        date: "20-04-2024",
        totalLink: "4",
        item: "XYZ",
        view: "High",
        onboard: "Rejected",
        earnTill: "1Yr",
    },
    {
        affilateId: "A01",
        platform: "Linkdin",
        date: "20-04-2024",
        totalLink: "4",
        item: "XYZ",
        view: "High",
        onboard: "Rejected",
        earnTill: "1Yr",
    },
    {
        affilateId: "A01",
        platform: "Linkdin",
        date: "20-04-2024",
        totalLink: "4",
        item: "XYZ",
        view: "High",
        onboard: "Rejected",
        earnTill: "1Yr",
    },
    {
        affilateId: "A01",
        platform: "Linkdin",
        date: "20-04-2024",
        totalLink: "4",
        item: "XYZ",
        view: "High",
        onboard: "Rejected",
        earnTill: "1Yr",
    },
]

const affilateTable = [
    {
        sellerid: 1,
        sellername: 'Seller 1',
        platform: 'Platform 1',
        date: '2024-04-20',
        product: 'Product 1',
        views: 100,
        onboard: 'Yes',
        tillspend: '$100',
        activity: 20
    },
    {
        sellerid: 2,
        sellername: 'Seller 2',
        platform: 'Platform 2',
        date: '2024-04-21',
        product: 'Product 2',
        views: 150,
        onboard: 'No',
        tillspend: '$150',
        activity: 30
    },
    {
        sellerid: 2,
        sellername: 'Seller 2',
        platform: 'Platform 2',
        date: '2024-04-21',
        product: 'Product 2',
        views: 150,
        onboard: 'No',
        tillspend: '$150',
        activity: 30
    },
    {
        sellerid: 2,
        sellername: 'Seller 2',
        platform: 'Platform 2',
        date: '2024-04-21',
        product: 'Product 2',
        views: 150,
        onboard: 'No',
        tillspend: '$150',
        activity: 30
    },
];


const NewLinkDash = () => {
    const [selectedOption, setSelectedOption] = useState(null);

    // Modal
    const [lgShow, setLgShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    // star Mark

    const [rating, setRating] = useState(0);

    const handleRating = () => {
        setRating(rating === 1 ? 0 : 1);
    };
    return (
        <section className='dashboard-in'>
            <div className='heading-holder'>
                {/* <h4>Complaint List</h4> */}
            </div>

            <div className='main-class-report-table'>
                <Row>
                    <Col xl={3} lg={3} md={5} sm={12} >
                        <Form.Label>Search</Form.Label>
                        <Form.Control
                            required
                            type="text"
                        />
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >
                        <Form.Label>From</Form.Label>
                        <Form.Control
                            required
                            type="Date"
                        />
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >
                        <Form.Label>To</Form.Label>
                        <Form.Control
                            required
                            type="Date"
                        />
                    </Col>
                    <Col xl={3} lg={5} md={5} sm={12}>
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} >
                                <Form.Label>Sort By</Form.Label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                />
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} >
                                <Form.Label>Download data in Excel</Form.Label><br />
                                <FontAwesomeIcon icon="fa-solid fa-file-lines" className='pdf-icon' />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >
                       
                    </Col>
                </Row>

                <div className='brownborder-report mt-3'></div>
                <Row>
                    <div className='col-md-12'>
                        <div className='my-3'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Affilate</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Seller</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="table-sec-main mt-5">
                                            <Table responsive bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Affilate Id</th>
                                                        <th>Platform</th>
                                                        <th>Date</th>
                                                        <th>Total Link</th>
                                                        <th>Item</th>
                                                        <th>View</th>
                                                        <th>Onboard</th>
                                                        <th>Earn Till</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sellerdata.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.affilateId}</td>
                                                            <td>{item.platform}</td>
                                                            <td>{item.date}</td>
                                                            <td>{item.totalLink}</td>
                                                            <td>{item.item}</td>
                                                            <td>{item.view}</td>
                                                            <td>{item.onboard}</td>
                                                            <td>{item.earnTill}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="paginationss mt-4">
                                            <Pagination>
                                                <Pagination.Prev />
                                                <Pagination.Item>{1}</Pagination.Item>
                                                <Pagination.Item>{2}</Pagination.Item>
                                                <Pagination.Ellipsis />
                                                <Pagination.Item>{10}</Pagination.Item>
                                                <Pagination.Next />
                                            </Pagination>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="table-sec-main mt-5">
                                            <Table responsive bordered>
                                                <thead>
                                                    <tr className="heads-main">
                                                        <th><Form.Check type="checkbox" label="Select" /></th>
                                                        <th>Seller Id</th>
                                                        <th>Seller Name</th>
                                                        <th>Platform</th>
                                                        <th>Date</th>
                                                        <th>Product</th>
                                                        <th>View</th>
                                                        <th>Onboard</th>
                                                        <th>Till Spend</th>
                                                        <th>Total Affilate Activity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {affilateTable.map((data, index) => (
                                                        <tr key={index} className="heads-main">
                                                            <td>{data.sellerid}</td>
                                                            <td>{data.sellername}</td>
                                                            <td>{data.platform}</td>
                                                            <td>{data.date}</td>
                                                            <td>{data.product}</td>
                                                            <td>{data.views}</td>
                                                            <td>{data.onboard}</td>
                                                            <td>{data.tillspend}</td>
                                                            <td>{data.activity}</td>
                                                            <td>
                                                                <FontAwesomeIcon icon="fa-solid fa-eye" className='action-icon me-2' />
                                                                <FontAwesomeIcon icon="fa-solid fa-pen" className='action-icon me-2' />
                                                                <FontAwesomeIcon icon="fa-solid fa-trash-can" className='action-icon me-2' />
                                                                <FontAwesomeIcon icon="fa-solid fa-clock-rotate-left" onClick={() => setLgShow(true)} className='action-icon' />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="paginationss mt-4">
                                            <Pagination>
                                                <Pagination.Prev />
                                                <Pagination.Item>{1}</Pagination.Item>
                                                <Pagination.Item>{2}</Pagination.Item>
                                                <Pagination.Ellipsis />
                                                <Pagination.Item>{10}</Pagination.Item>
                                                <Pagination.Next />
                                            </Pagination>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </Row>
            </div>
        </section>
    )
}

export default NewLinkDash