import React, { useContext, useEffect, useState } from "react";
import "./mrp-modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfluencerWorkModal = (props) => {

  
  const { IMG_URL } = useContext(Context);


 
  return (
    <>
      <Modal className='mrp-modal' size="lg" {...props} centered >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        


          <div className='row text-center'>
            <div className='col-12 mb-4'>
                <h6>Uploaded Work</h6>
            </div>
          </div>

          <div className='row text-center'>

            {props?.data?.map((value, index) => (
              <div className='col-12 mb-4'>
                <span><b>Platform :</b> {value?.a_social_detail?.social_platform?.name} </span>
                <span   className="ms-5"><b>Post Link :  </b><a target="_blank" className="btn btn-primary" href={value?.link}><FontAwesomeIcon icon="fa-solid fa-globe" /></a></span>
            </div>
            ))}
            

          </div>
          

        </Modal.Body>
      </Modal>
    </>
  );
};

export default InfluencerWorkModal;
