import React, { useState, useEffect, useContext } from "react";
import './DashboardInHouse.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import AddTaskModal from './AddTaskModal';
import EditTaskModal from './EditTaskModal';
import DeleteModal from "./Delete_modal";
import { Col, Nav, Row, Tab, Form, Pagination, Table } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { getData } from '../../../../utils/api';
import { Context } from '../../../../utils/context';
import parse from "html-react-parser";
import AffiliateModal from "./AffiliateModal";

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];


const DashboardInHouse = ({setActiveCount}) => {
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const [selectedOption, setSelectedOption] = useState(null);

    const { getData, postData, IMG_URL, Select2Data, userdata, putData,formatDateExpire,editStatusData } = useContext(Context);
    const [products, setProducts] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [productTitle, setProductTitle] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [modalaaffilateDelete, setModalaaffilateDelete] = useState(false);
    const [modalaaffilateEdit, setModalaaffilateEdit] = useState(false);
    // Modal
    const [lgShow, setLgShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    // star Mark

    const [rating, setRating] = useState(0);

    const handleRating = () => {
        setRating(rating === 1 ? 0 : 1);
    };

    const [editId, setEditId] = useState(0);
    const handleEdit = (row) => {
        console.log("Edit row:", row);
        setModalaaffilateEdit(true)
        setEditId(row?.id)

    };
    function handleDeleteClick(row) {
        setModalaaffilateDelete(true)
        setEditId(row?.id)

    }


    const [filterText, setFilterText] = useState('');
    const columns = [
        {
            name: 'Task ID',
            selector: row => row.task_id,
            sortable: true,
        },
        {
            name: 'Product Type',
            selector: row => row.product_type,
            sortable: true,
        },
        {
            name: 'Website URL',
            selector: row => row.website_url,
            sortable: true,
        },
        {
            name: 'Insentive',
            selector: row => row.product_type === 'Website' ? row.insentive + ' %' : '₹ ' + row.insentive,
            sortable: true,
        },
        {
            name: 'Product Title',
            selector: row => row.product_name,
            sortable: true,
        },
        // {
        //     name: 'Actions',
        //     cell: row => (
        //         <>
        //             <FontAwesomeIcon icon="fa-solid fa-pen" className="actionIcon" onClick={() => handleEdit(row)} />
        //             <FontAwesomeIcon icon="fa-solid fa-trash" className="actionIcon" onClick={() => handleDeleteClick(row)} />
        //             {/* <button onClick={() => handleEdit(row)}>Edit</button>
        //             <button onClick={() => handleDelete(row)}>Delete</button> */}
        //         </>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // },
    ];

  

    // const filteredData = products.filter(item =>
    //     item.brand_name.toLowerCase().includes(filterText.toLowerCase())
    // );

    const handleFilterChange = e => {
        setFilterText(e.target.value);
    };

    // Report Table

    const [filterReportText, setFilterReportText] = useState('');

    const reportcolumns = [
        {
            name: 'Task Id',
            selector: row => row.task_id,
            sortable: true,
        },
        {
            name: 'Incentive',
            selector: row => row.product_type === 'Website' ? row.insentive + ' %' : '₹ ' + row.insentive,
            sortable: true,
        },
        {
            name: 'Product Type',
            selector: row => row.product_type,
            sortable: true,
        },
        {
            name: 'Long term Offer',
            selector: row => row.long_term == 1 ? "Offer Available for Long Term" : "Offer Not Available for Long Term",
            sortable: true,
        },
        {
            name: 'validity',
            selector: row => formatDateExpire(row.createdAt,row.validity),
            sortable: true,
        },
        {
            name: 'Affiliate Id',
            selector: row => row.affiliate_id,
            sortable: true,
        },
        {
            name: 'Total Buy',
            selector: row => row.total_buy,
            sortable: true,
        },
        {
            name: 'Affiliate Status',
            selector: row => row.affiliate_status,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                row.affiliate_status === 'accepted' && (
                <>
                    <FontAwesomeIcon icon="fa-solid fa-eye" className="actionIcon" onClick={() => handleShow(row)} />
                </>
                )
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Status',
            cell: row => (
                row.affiliate_status === 'accepted' && (
                <>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={row?.status}
                            onChange={() => {
                                ChangeStatus(row?.id);
                            }}
                            id={`flexSwitchCheckDefault${row?.id}`}
                        />
                        <label
                            className="form-check-label"
                            htmlFor={`flexSwitchCheckDefault${row?.id}`}
                        >
                            {row?.status ? "Active" : "Inactive"}
                        </label>
                    </div>
                </>
                )
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },

    ];

    const [changeStatus, setChangeStatus] = useState();
    const ChangeStatus = async (id) => {
        const response = await editStatusData(`/affiliate/inhouse-task/active/data/${id}`);
        
        getActiveTasks();
      };

   const [data, setData] = useState();
   
   const [activeShow, setActiveShow] = React.useState(false);
    const handleShow = async (row) => {
        const res = await getData(`/affiliate/inhouse-task/active/data/${row?.id}`);
        if(res?.success){
            setData(res?.data);
            setActiveShow(true);
        }
    }


    const handleFilterReportChange = e => {
        setFilterReportText(e.target.value);
    };

    const formatDate = (date) => {
        console.log(date, "data kkkk");
        if (!date) return null;
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getProducts = async (currentPage = 1) => {
        console.log(currentPage, "currentPage");
        let url = `/affiliate/inhouse-task?page=${currentPage}&term=${productTitle}`;
        if (startDate !== "") {
            url += `&date=${startDate}`;
        }
        const res = await getData(url);


        if (res?.success) {
            //   const newData = res?.data?.data;
            //   if (newData) {
            //     const newIds = newData.map((d) => d?.id);
            //     setAllChecked(newIds);
            //   }
            setProducts(res?.data?.data);
            // setShimmerLoader(false);
        }
    };
    const [activeTasks, setActiveTask] = useState([]);
    const getActiveTasks = async (currentPage = 1) => {
        console.log(currentPage, "currentPage");
        let url = `/affiliate/inhouse-task/active/data?page=${currentPage}&term=${productTitle}&type=accepted`;
        if (startDate !== "") {
            url += `&date=${startDate}`;
        }
        const res = await getData(url);


        if (res?.success) {
            
            let array = [];
            res?.data?.data.map((value) => {
                value?.affiliate_tasks?.map((innervalue) => {
                    array.push(innervalue);
                })
            })
            setActiveTask(array);
            setActiveCount(array.length);
        }
    };

    const [rejectTasks, setRejectTask] = useState([]);
    const getRejectTasks = async (currentPage = 1) => {
        console.log(currentPage, "currentPage");
        let url = `/affiliate/inhouse-task/active/data?page=${currentPage}&term=${productTitle}&type=rejected`;
        if (startDate !== "") {
            url += `&date=${startDate}`;
        }
        const res = await getData(url);


        if (res?.success) {
           
            let array = [];
            res?.data?.data.map((value) => {
                value?.affiliate_tasks?.map((innervalue) => {
                    array.push(innervalue);
                })
            })
            setRejectTask(array);
        }
    };

    console.log(products, "products products");
    useEffect(() => {
        getProducts(currentPage);
        getActiveTasks(currentPage);
        getRejectTasks(currentPage);
    }, [currentPage, productTitle, searchStatus, startDate]);




    return (
        <>
            <section className='dashboard-in'>
                <div className='heading-holder'>
                    {/* <h4>Complaint List</h4> */}
                </div>

                <div className='main-class-report-table'>
                    <Row>
                        <Col xl={3} lg={3} md={5} sm={12} >
                            <Form.Group className="form-group">
                                <Form.Label>Search</Form.Label>
                                <Form.Control
                                    value={productTitle}
                                    type="search"
                                    placeholder="Search by Task Id"
                                    onChange={(e) => setProductTitle(e.target.value)}

                                />

                            </Form.Group>
                        </Col>
                        <Col xl={2} lg={3} md={5} sm={12} >
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                required
                                value={startDate}
                                type="Date"
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Col>
                        {/* <Col xl={2} lg={3} md={5} sm={12} >
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                required
                                type="Date"
                            />
                        </Col> */}
                        <Col xl={4} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={6} lg={6} md={12} sm={12} >
                                    {/* <Form.Group className="form-group">
                                      
                                        <div className='status-button text-end my-3'>
                                            <button
                                                className='add-neww-btn'
                                                type="button"
                                                onClick={() => {
                                                   
                                                    setProductTitle("");
                                                    setStartDate("");

                                                    
                                                }}
                                            >
                                                Reset Button
                                            </button>
                                        </div>
                                    </Form.Group> */}
                                    <div className='status-button text-end my-3'>
                                        <Button variant="primary" className='add-neww-btn' onClick={() => {
                                            // setSearchStatus("");
                                            setProductTitle("");
                                            setStartDate("");

                                            // setCategory("");
                                        }} >
                                              Reset Button
                                        </Button>
                                    </div>
                                </Col>
                                <Col xl={6} lg={6} md={12} sm={12} >
                                    <Form.Label>Download data in Excel</Form.Label><br />
                                    <FontAwesomeIcon icon="fa-solid fa-file-lines" className='pdf-icon' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={2} lg={3} md={5} sm={12} >
                            <div className='status-button text-end my-3'>
                                <Button variant="primary" className='add-neww-btn' onClick={() => setModalShow(true)} >
                                    <FontAwesomeIcon icon="fa-solid fa-plus" className="fonticn" />  Add New Task
                                </Button>
                            </div>
                            <AddTaskModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                getAllProducts={getProducts}
                            />
                        </Col>
                    </Row>

                    <div className='brownborder-report mt-3'></div>
                    <Row>
                        <div className='col-md-12'>
                            <div className='my-3'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="pills" className="flex-row">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Task</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Active Task</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Rejected Task</Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            {/* <div className="table-sec-main mt-5">
                                                <Table responsive bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>Brand Name</th>
                                                            <th>Image</th>
                                                            <th>Industries</th>
                                                            <th>Product Review</th>
                                                            <th>Type</th>
                                                            <th>Discount</th>
                                                            <th>Highlight</th>
                                                            <th>Description</th>
                                                            <th>Validity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productdata.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.brandName}</td>
                                                                <td><img src={process.env.PUBLIC_URL + item.image} className="banner-img" alt="..." /></td>
                                                                <td>{item.industries}</td>
                                                                <td>
                                                                    <div className='one-star-rating' onClick={handleRating}>
                                                                        <span className={`star ${rating === 1 ? 'rated' : ''}`}>★★★★★</span>
                                                                    </div>
                                                                </td>
                                                                <td>{item.type}</td>
                                                                <td>{item.discount}</td>
                                                                <td>{item.highlight}</td>
                                                                <td>{item.description}</td>
                                                                <td>{item.validity}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="paginationss mt-4">
                                                <Pagination>
                                                    <Pagination.Prev />
                                                    <Pagination.Item>{1}</Pagination.Item>
                                                    <Pagination.Item>{2}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item>{10}</Pagination.Item>
                                                    <Pagination.Next />
                                                </Pagination>
                                            </div> */}
                                            <div className='datatableMain'>
                                                {/* <Row>
                                                    <Col xl={2} lg={3} md={6} sm={12} className='mt-3' >
                                                        <input
                                                            type='text'
                                                            placeholder='Search by title...'
                                                            value={filterText}
                                                            onChange={handleFilterChange}
                                                        />
                                                    </Col>
                                                </Row> */}
                                                <DataTable
                                                    columns={columns}
                                                    data={products}
                                                    selectableRows
                                                    pagination
                                                    defaultSortFieldId={1}
                                                />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            {/* <div className="table-sec-main mt-5">
                                                <Table responsive bordered>
                                                    <thead>
                                                        <tr className="heads-main">
                                                            <th><Form.Check type="checkbox" label="Select" /></th>
                                                            <th>Ad Id</th>
                                                            <th>Star Mark</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Total Link</th>
                                                            <th>Link</th>
                                                            <th>Incentive</th>
                                                            <th>Views</th>
                                                            <th>Total Buy</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reportTable.map((data, index) => (
                                                            <tr key={index} className="heads-main">
                                                                <td><Form.Check type="checkbox" /></td>
                                                                <td>{data.complaintno}</td>
                                                                <td>
                                                                    <div className='one-star-rating' onClick={handleRating}>
                                                                        <span className={`star ${rating === 1 ? 'rated' : ''}`}>★</span>
                                                                    </div>
                                                                </td>
                                                                <td>{data.productid}</td>
                                                                <td>{data.productname}</td>
                                                                <td>{data.totallink}</td>
                                                                <td>{data.link}</td>
                                                                <td>{data.incentive}</td>
                                                                <td>{data.views}</td>
                                                                <td>{data.totalbuy}</td>
                                                                <td>{data.amount}</td>
                                                                <td>{data.status}</td>
                                                                <td>
                                                                    <FontAwesomeIcon icon="fa-solid fa-eye" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-pen" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-clock-rotate-left" onClick={() => setLgShow(true)} className='action-icon' />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </div>
                                            <div className="paginationss mt-4">
                                                <Pagination>
                                                    <Pagination.Prev />
                                                    <Pagination.Item>{1}</Pagination.Item>
                                                    <Pagination.Item>{2}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item>{10}</Pagination.Item>
                                                    <Pagination.Next />
                                                </Pagination>
                                            </div> */}
                                            {/* <input
                                                type="text"
                                                value={filterReportText}
                                                onChange={handleFilterReportChange}
                                                placeholder="Filter by Title"
                                                className='mt-4'
                                            /> */}
                                            <DataTable
                                                columns={reportcolumns}
                                                data={activeTasks}
                                                selectableRows
                                                pagination
                                                defaultSortFieldId={1}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            {/* <div className="table-sec-main mt-5">
                                                <Table responsive bordered>
                                                    <thead>
                                                        <tr className="heads-main">
                                                            <th><Form.Check type="checkbox" label="Select" /></th>
                                                            <th>Ad Id</th>
                                                            <th>Star Mark</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Total Link</th>
                                                            <th>Link</th>
                                                            <th>Incentive</th>
                                                            <th>Views</th>
                                                            <th>Total Buy</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reportTable.map((data, index) => (
                                                            <tr key={index} className="heads-main">
                                                                <td><Form.Check type="checkbox" /></td>
                                                                <td>{data.complaintno}</td>
                                                                <td>
                                                                    <div className='one-star-rating' onClick={handleRating}>
                                                                        <span className={`star ${rating === 1 ? 'rated' : ''}`}>★</span>
                                                                    </div>
                                                                </td>
                                                                <td>{data.productid}</td>
                                                                <td>{data.productname}</td>
                                                                <td>{data.totallink}</td>
                                                                <td>{data.link}</td>
                                                                <td>{data.incentive}</td>
                                                                <td>{data.views}</td>
                                                                <td>{data.totalbuy}</td>
                                                                <td>{data.amount}</td>
                                                                <td>{data.status}</td>
                                                                <td>
                                                                    <FontAwesomeIcon icon="fa-solid fa-eye" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-pen" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-clock-rotate-left" onClick={() => setLgShow(true)} className='action-icon' />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </div>
                                            <div className="paginationss mt-4">
                                                <Pagination>
                                                    <Pagination.Prev />
                                                    <Pagination.Item>{1}</Pagination.Item>
                                                    <Pagination.Item>{2}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item>{10}</Pagination.Item>
                                                    <Pagination.Next />
                                                </Pagination>
                                            </div> */}
                                            {/* <input
                                                type="text"
                                                value={filterReportText}
                                                onChange={handleFilterReportChange}
                                                placeholder="Filter by Title"
                                                className='mt-4'
                                            /> */}
                                            <DataTable
                                                columns={reportcolumns}
                                                data={rejectTasks}
                                                selectableRows
                                                pagination
                                                defaultSortFieldId={1}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </Row>
                    <EditTaskModal
                        editId={editId}
                        show={modalaaffilateEdit}
                        onHide={() => setModalaaffilateEdit(false)}
                        // getAdvertisement={props.getAdvertisement}
                        getAllProducts={getProducts}

                    />
                    <DeleteModal
                        editId={editId}
                        show={modalaaffilateDelete}
                        onHide={() => setModalaaffilateDelete(false)}
                        // getAdvertisement={props.getAdvertisement}
                        getAllProducts={getProducts}

                    />

                    <AffiliateModal show={activeShow}  onHide={() => setActiveShow()} data={data}/>

                </div>
            </section>
        </>
    )
}

export default DashboardInHouse


