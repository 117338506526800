import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./SuccessModal.css";
import Animation from "../Animation/SuccessAnimation/Animation";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
const StrickStatusModal = (props) => {

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();



  const onSubmit = async (d) => {
    props.handleReasonFunction(d.strick_status.value, d.company_remark);
    setValue('strick_status', "");
    // give onHide in handleReasonFunction not here
  }



  const options = [
    { value: 'reject strick', label: 'Reject Strick', name: 'strick_status' },
    { value: 'refund payment', label: 'Refund Payment', name: 'strick_status' },
    { value: 'pending', label: 'Pending', name: 'strick_status' },
  ];



  useEffect(() => {
    setValue('strick_status', { value: props.strickId.strick_status, label: props.strickId.strick_status, name: 'strick_status' });
    setValue('company_remark',props.strickId.company_remark)
  }, [props.strickId]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="SuccessModalPop"
      >
        <Modal.Body>
          <div className="modalContentinfo">
            <div className="modalHead">
              {/* <h3>SUCCESS!!</h3> */}
              <p>{props?.text}</p>
            </div>




            <div className="text-center">
              <div className=" mb-3">


                {errors.strick_status && (
                  <span className="text-danger">
                    {errors.strick_status.message}
                  </span>
                )}
                <Controller
                  name="strick_status" // name of the field
                  {...register("strick_status", {
                    required: "Select Strick Status",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.strick_status
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={options}
                    />
                  )}
                />

              </div>
              <div className=" mb-3">
                {errors.company_remark && (
                  <span className="text-danger">
                    {errors.company_remark.message}
                  </span>
                )}
                <Form.Control
                  type="text"
                  name="company_remark"
                  // value={formData.name}
                  // onChange={handleChange}
                  placeholder={"Company Remark"}
                  className={classNames("mb-3", {
                    "is-invalid": errors?.company_remark,
                  })}
                  {...register("company_remark", {
                    required: "Company Remark is requried",
                  })}
                // id="inputEmail3"
                // className="input-mandatory"
                />
              </div>

              <Button className="btn btn-danger" type="button" onClick={props.onHide}>Cancel</Button>

              <Button className="btn btn-success ms-3" onClick={handleSubmit(onSubmit)}>{props.button}</Button>

            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StrickStatusModal;
