
import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { Col, Nav, Row, Tab, Form, Pagination, Table } from 'react-bootstrap'

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

const productdata = [
    {
        brandName: "ABC",
        id: "1",
        address: "pune",
        type: "A",
        firmtype: "c",
        totallink: "4",
        deshbord: "1",
        validity: "1Yr",
        contact: "1234567890",
        date: "1/02/2024",
        click: "2",
        order: "3",
        seller: "4",
        setting: "add",
        searchseller: "5",
        earning: "1000"
    },
    {
        brandName: "ABC",
        id: "1",
        address: "pune",
        type: "A",
        firmtype: "c",
        totallink: "4",
        deshbord: "1",
        validity: "1Yr",
        contact: "1234567890",
        date: "1/02/2024",
        click: "2",
        order: "3",
        seller: "4",
        setting: "add",
        searchseller: "5",
        earning: "1000"
    },
    {
        brandName: "ABC",
        id: "1",
        address: "pune",
        type: "A",
        firmtype: "c",
        totallink: "4",
        deshbord: "1",
        validity: "1Yr",
        contact: "1234567890",
        date: "1/02/2024",
        click: "2",
        order: "3",
        seller: "4",
        setting: "add",
        searchseller: "5",
        earning: "1000"
    },
    {
        brandName: "ABC",
        id: "1",
        address: "pune",
        type: "A",
        firmtype: "c",
        totallink: "4",
        validity: "1Yr",
        contact: "1234567890",
        date: "1/02/2024",
        click: "2",
        order: "3",
        deshbord: "1",
        seller: "4",
        setting: "add",
        searchseller: "5",
        earning: "1000"
    },
    {
        brandName: "ABC",
        id: "1",
        address: "pune",
        type: "A",
        firmtype: "c",
        totallink: "4",
        deshbord: "1",
        validity: "1Yr",
        contact: "1234567890",
        date: "1/02/2024",
        click: "2",
        order: "3",
        seller: "4",
        setting: "add",
        searchseller: "5",
        earning: "1000"
    }
]

const reportTable = [
    {
        complaintno: 1234,
        starmark: "Disha M",
        productid: "123654",
        productname: "P1",
        totallink: "High",
        link: "Rejected",
        incentive: "NA",
        views: "46546",
        totalbuy: "All ",
        amount: '67578567',
        status: "Active"
    },
    {
        complaintno: 1234,
        starmark: "Disha M",
        productid: "123654",
        productname: "P1",
        totallink: "High",
        link: "Rejected",
        incentive: "NA",
        views: "46546",
        totalbuy: "All ",
        amount: '67578567',
        status: "Active"
    },
    {
        complaintno: 1234,
        starmark: "Disha M",
        productid: "123654",
        productname: "P1",
        totallink: "High",
        link: "Rejected",
        incentive: "NA",
        views: "46546",
        totalbuy: "All ",
        amount: '67578567',
        status: "Active"
    },
    {
        complaintno: 1234,
        starmark: "Disha M",
        productid: "123654",
        productname: "P1",
        totallink: "High",
        link: "Rejected",
        incentive: "NA",
        views: "46546",
        totalbuy: "All ",
        amount: '67578567',
        status: "Active"
    },
    {
        complaintno: 1234,
        starmark: "Disha M",
        productid: "123654",
        productname: "P1",
        totallink: "High",
        link: "Rejected",
        incentive: "NA",
        views: "46546",
        totalbuy: "All ",
        amount: '67578567',
        status: "Active"
    },

]
const AffilateActivityTable = () => {
    const [selectedOption, setSelectedOption] = useState(null);

    // Modal
    const [lgShow, setLgShow] = useState(false);


    // star Mark

    const [rating, setRating] = useState(0);

    const handleRating = () => {
        setRating(rating === 1 ? 0 : 1);
    };

    return (
        <>
            <section className='dashboard-in'>
                <div className='heading-holder'>
                    {/* <h4>Complaint List</h4> */}
                </div>

                <div className='main-class-report-table'>
                    <Row>
                        <Col xl={3} lg={3} md={5} sm={12} >
                            <Form.Label>Search</Form.Label>
                            <Form.Control
                                required
                                type="text"
                            />
                        </Col>
                        <Col xl={2} lg={3} md={5} sm={12} >
                            <Form.Label>From</Form.Label>
                            <Form.Control
                                required
                                type="Date"
                            />
                        </Col>
                        <Col xl={2} lg={3} md={5} sm={12} >
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                required
                                type="Date"
                            />
                        </Col>
                        <Col xl={3} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={6} lg={6} md={12} sm={12} >
                                    <Form.Label>Sort By</Form.Label>
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={options}
                                    />
                                </Col>
                                <Col xl={6} lg={6} md={12} sm={12} >
                                    <Form.Label>Download data in Excel</Form.Label><br />
                                    <FontAwesomeIcon icon="fa-solid fa-file-lines" className='pdf-icon' />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={2} lg={3} md={5} sm={12} >

                        </Col>
                    </Row>

                    <div className='brownborder-report mt-3'></div>
                    <Row>
                        <div className='col-md-12'>
                            <div className='my-3'>
                            <div className="table-sec-main mt-5">
                                                <Table responsive bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>Affiliate Name</th>
                                                            <th>Affiliate id</th>
                                                            <th>Firm Address</th>
                                                            <th>Firm Type</th>
                                                            <th>Contact No</th>
                                                            <th>Total Link Generate</th>
                                                            <th>Date</th>
                                                            <th>Total Click</th>
                                                            <th>Total Order</th>
                                                            <th>DashBoard</th>
                                                            <th>Connected Seller</th>
                                                            <th>Type</th>
                                                            <th>Setting</th>
                                                            <th>Search Seller</th>
                                                            <th>Earning</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productdata.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.brandName}</td>
                                                                <td>{item.id}</td>
                                                                <td>{item.address}</td>
                                                                <td> {item.firmtype}</td>
                                                                <td>{item.contact}</td>
                                                                <td>{item.totallink}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.click}</td>
                                                                <td>{item.order}</td>
                                                                <td>{item.deshbord}</td>
                                                                <td>{item.seller}</td>
                                                                <td>{item.type}</td>
                                                                <td>{item.setting}</td>
                                                                <td>{item.searchseller}</td>
                                                                <td>{item.earning}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="paginationss mt-4">
                                                <Pagination>
                                                    <Pagination.Prev />
                                                    <Pagination.Item>{1}</Pagination.Item>
                                                    <Pagination.Item>{2}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item>{10}</Pagination.Item>
                                                    <Pagination.Next />
                                                </Pagination>
                                            </div>
                                {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="pills" className="flex-row">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Task</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Active Task</Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                         
                                        </Tab.Pane> */}
                                        {/* <Tab.Pane eventKey="second">
                                            <div className="table-sec-main mt-5">
                                                <Table responsive bordered>
                                                    <thead>
                                                        <tr className="heads-main">
                                                            <th><Form.Check type="checkbox" label="Select" /></th>
                                                            <th>Ad Id</th>
                                                            <th>Star Mark</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Total Link</th>
                                                            <th>Link</th>
                                                            <th>Incentive</th>
                                                            <th>Views</th>
                                                            <th>Total Buy</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reportTable.map((data, index) => (
                                                            <tr key={index} className="heads-main">
                                                                <td><Form.Check type="checkbox" /></td>
                                                                <td>{data.complaintno}</td>
                                                                <td>
                                                                    <div className='one-star-rating' onClick={handleRating}>
                                                                        <span className={`star ${rating === 1 ? 'rated' : ''}`}>★</span>
                                                                    </div>
                                                                </td>
                                                                <td>{data.productid}</td>
                                                                <td>{data.productname}</td>
                                                                <td>{data.totallink}</td>
                                                                <td>{data.link}</td>
                                                                <td>{data.incentive}</td>
                                                                <td>{data.views}</td>
                                                                <td>{data.totalbuy}</td>
                                                                <td>{data.amount}</td>
                                                                <td>{data.status}</td>
                                                                <td>
                                                                    <FontAwesomeIcon icon="fa-solid fa-eye" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-pen" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" className='action-icon me-2' />
                                                                    <FontAwesomeIcon icon="fa-solid fa-clock-rotate-left" onClick={() => setLgShow(true)} className='action-icon' />
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </div>
                                            <div className="paginationss mt-4">
                                                <Pagination>
                                                    <Pagination.Prev />
                                                    <Pagination.Item>{1}</Pagination.Item>
                                                    <Pagination.Item>{2}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item>{10}</Pagination.Item>
                                                    <Pagination.Next />
                                                </Pagination>
                                            </div>
                                        </Tab.Pane> */}
                                    {/* </Tab.Content>
                                </Tab.Container> */}
                            </div>
                        </div>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default AffilateActivityTable



