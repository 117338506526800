import React, { useState, useContext, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import NewLinkAndAddFlowSellerContent from "./NewLinkAndAddFlowDataTableContent/NewLinkAndAddFlowSellerContent/Table";
import Statetable from "../../State/Tables";
import Countrytable from "../../Country/Tables";
import Pincodetable from "../../Pincode/Tables";
import { Context } from "../../../../utils/context";

const NewLinkAndAddFlow = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const tabItems = [{ key: 1, name: "Seller", link: "" }];

  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="1"
        className="tabadminmain"
      >
        <Row className="me-0 ms-0">
          <Col xl={2} lg={2} md={4} className="p-0">
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={10} md={8} className=" p-0">
            <Tab.Content className="tabadminmain">
              {[149, 150, 151, 152, 153, 154].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="1">
                  <NewLinkAndAddFlowSellerContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default NewLinkAndAddFlow;
