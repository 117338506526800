import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import {
  MarginSettings,
  MinimunMariginSettings,
} from "../../../utils/apis/master/Master";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const [workinTypes, setWorkingTypes] = useState([]);
  const [category, setCategory] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [min, setMin] = useState(0);

  const GetAllWorkingTypes = async () => {
    const response = await getData("/common/seller/masters/working-type");

    if (response?.success) {
      setWorkingTypes(await Select2Data(response?.data, "working_type_id"));
    }

    {
      const response = await MarginSettings();
      if (response?.success) {
        setValue("d_margin", response?.data?.sub_category_margin);
      }
    }

    {
      const response = await MinimunMariginSettings();
      if (response?.success) {
        setMin(response?.data?.sub_category_margin);
      }
    }
  };

  const GetAllCategory = async (id) => {
    const response = await getData(`/common/seller/masters/allcategory/${id}`);

    if (response?.success) {
      setCategory(await Select2Data(response?.data, "category_id"));
    }
  };

  useEffect(() => {
    GetAllWorkingTypes();
    GetAllCategory();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();
      finalData.append("working_type_id", data?.working_type_id?.value);
      finalData.append("category_id", data?.category_id?.value);
      finalData.append("name", data?.name);
      finalData.append("c_margin", data?.c_margin);
      finalData.append("d_margin", data?.d_margin);
      finalData.append("margin", data?.margin);
      finalData.append("meta_address", data?.meta_address);
      finalData.append("meta_description", data?.meta_description);
      if (data?.image && data.image.length > 0) {
        finalData.append("image", data.image[0]);
      }
      const response = await postData(
        `/seller/masters/s-sub-category`,
        finalData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Sector Sub Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Working Type</Form.Label>
                    <Controller
                      name="working_type_id" // name of the field
                      {...register("working_type_id", {
                        required: "Select Work Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.working_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={workinTypes}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllCategory(selectedOption.value);
                            setValue("working_type_id", selectedOption);
                          }}
                        />
                      )}
                    />
                    {errors.working_type_id && (
                      <span className="text-danger">
                        {errors.working_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>S. Category</Form.Label>

                    <Controller
                      name="category_id" // name of the field
                      {...register("category_id", {
                        required: "Select Sector Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={category}
                        />
                      )}
                    />

                    {errors.category_id && (
                      <span className="text-danger">
                        {errors.category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>S. Sub Category</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Sub Category"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "State is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Margin</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="c_margin"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Margin"
                          className={classNames("", {
                            "is-invalid": errors?.c_margin,
                          })}
                          {...register("c_margin", {
                            required: "Margin is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.c_margin && (
                        <span className="text-danger">
                          {errors.c_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Custom Margin</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="c_margin"
                          placeholder="Custom Margin"
                          className={classNames("", {
                            "is-invalid": errors?.c_margin,
                          })}
                          {...register("c_margin", {
                            required: "Margin is required",
                          })}
                          onChange={(e) => {
                            const c_margin = Number(e.target.value);
                            const d_margin = Number(getValues("d_margin"));
                            const margin = c_margin + d_margin;
                            if (margin >= min) {
                              setValue("c_margin", c_margin);
                              setValue("margin", margin);
                            } else {
                              setValue("c_margin", "");
                              setError("c_margin", {
                                type: "manual",
                                message: `Margin is Required ${min}`,
                              });
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.c_margin && (
                        <span className="text-danger">
                          {errors.c_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Default Margin</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="d_margin"
                          disabled
                          // value={margin?.category_margin}
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Default Margin"
                          className={classNames("", {
                            "is-invalid": errors?.d_margin,
                          })}
                          {...register("d_margin", {
                            required: "Default Margin is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.d_margin && (
                        <span className="text-danger">
                          {errors.d_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Margin</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="margin"
                          disabled
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Margin"
                          className={classNames("", {
                            "is-invalid": errors?.margin,
                          })}
                          {...register("margin", {
                            required: "Margin is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.margin && (
                        <span className="text-danger">
                          {errors.margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Meta Address</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="meta_address"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Meta Address"
                          className={classNames("", {
                            "is-invalid": errors?.meta_address,
                          })}
                          {...register("meta_address", {
                            required: "Meta Address is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.meta_address && (
                        <span className="text-danger">
                          {errors.meta_address.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Meta Description</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="meta_description"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Meta Description"
                          className={classNames("", {
                            "is-invalid": errors?.meta_description,
                          })}
                          {...register("meta_description", {
                            required: "Meta Description is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.meta_description && (
                        <span className="text-danger">
                          {errors.meta_description.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image</Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
