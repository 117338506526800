import React from "react";
import {  Col, Row } from "react-bootstrap";
import DashboardBarGraph from "../../../../../affiliate/AffiliateDashboard/DashboardTab/Dashbaord-bar-graph/DashboardBarGraph";
import DashboardPieChart from "../../../../../affiliate/AffiliateDashboard/DashboardTab/Dashboard-pie-chart/DashboardPieChart";
import DashboardClickGraph from "../../../../../affiliate/AffiliateDashboard/DashboardTab/DashboardClickGraph/DashboardClickGraph";
import NewCustomerTable from "../../../../../affiliate/AffiliateDashboard/DashboardTab/New-Customer-table/NewCustomerTable";
import DashboardLineGraph from "../../../../../affiliate/AffiliateDashboard/DashboardTab/Dashboard-line-graph/DashboardLineGraph";

const DashboardGraphContent = () => {
  return (
    <section className="affiliate-desh-board">
      <Row className="me-0 ms-0">
        <Col xxl={7} xl={7} lg={8}>
          <DashboardBarGraph />
        </Col>
        <Col xxl={5} xl={5} lg={4}>
          <DashboardPieChart />
        </Col>
      </Row>
      <Row className="me-0 ms-0">
        <Col xxl={6} xl={6}>
          <DashboardClickGraph />
        </Col>
        <Col xxl={6} xl={6}>
          <DashboardLineGraph />
        </Col>

        <Col xxl={12} xl={12}>
          <NewCustomerTable />
        </Col>
      </Row>
    </section>
  );
};

export default DashboardGraphContent;
