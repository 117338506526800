import React from 'react'
import Header from '../../Header/Header'
import DashboardTab from './DashboardTab/DashboardTab'

const AffiliateDashboard = () => {
    return (
        <>
            <Header title={"Affiliate Dashboard"} />
            <DashboardTab/>
        </>
    )
}

export default AffiliateDashboard