import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import parse from "html-react-parser";
import Select from "react-select";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
    Select2Data,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [searchWorking, setSearchWorking] = useState("");
  const [searchWebsitePage, setSearchWebsitePage] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const getDataAll = async () => {
    let url = `/api-documentation/website-api?page=${currentPage}&per_page=${perPage}&term=${search}&websitetype=${
      searchWorking?.label || ""
    }&websitepage=${searchWebsitePage?.label || ""}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    const response = await getData(url);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(
      `/api-documentation/website-api/${id}`
    );
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/api-documentation/website-api/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [websiteTypes, setWebsiteTypes] = useState([]);
  const [websitePages, setWebsitePages] = useState([]);

  const GetAllWebsiteTypes = async () => {
    const response = await getData(
      "/common/api-documentation/getAllWebsiteTypes"
    );

    if (response?.success) {
      setWebsiteTypes(await Select2Data(response?.data, "website_type_id"));
    }
  };

  const GetAllWebsitePages = async (id) => {
    const response = await getData(
      `/common/api-documentation/getAllWebsitePages/${id}`
    );

    if (response?.success) {
      setWebsitePages(await Select2Data(response?.data, "website_page_id"));
    }
  };

  useEffect(() => {
    GetAllWebsiteTypes();
  }, []);

  const statusOption = [
    { label: "active", value: 1 },
    { label: "Inactive", value: 0 },
  ];
  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Website Apis"} link={"/seller/s-banner"} />
        <section className="AdvanceDashboard">
          <div className="row  me-0 ms-0  MainRowsec">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio ">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex add-btn-holder">
                      <div className="add me-3">
                        {isAllow.includes(10) ? (
                          <Link
                            // to="/api-documentation/website-api/add"
                            type="button"
                            className="btn btn-add pe-3"
                          >
                            <div onClick={() => handleShow()}>
                              <img
                                src={plus}
                                className="plus me-2 ms-0"
                                alt=""
                              />
                              Add Website Page
                            </div>
                          </Link>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="add me-3">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={colunms} className="columns " alt="" />
                            Column Selection
                            <img src={top} className="top ms-1" alt="" />
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Website Type
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Website Page
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Website Api
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Status
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Action
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="   col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex row-flex-holder">
                          <div className="show me-2">
                            <p className="show ">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show ">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="   col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end entry-showing">
                          <div className="sowing me-2">
                            <p className="show ">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>
                          <div className="num me-2">
                            <Select
                              value={searchStatus}
                              onChange={(selectedOption) => {
                                setSearchStatus(selectedOption);
                              }}
                              options={statusOption}
                              placeholder="status"
                              isSearchable
                            />
                          </div>
                          <div className="num me-2">
                            <Select
                              value={searchWorking}
                              onChange={(selectedOption) => {
                                setSearchWorking(selectedOption);
                                GetAllWebsitePages(selectedOption.value);
                              }}
                              options={websiteTypes}
                              placeholder="websiteTypes"
                              isSearchable
                            />
                          </div>
                          <div className="num me-2">
                            <Select
                              value={searchWebsitePage}
                              onChange={(selectedOption) => {
                                setSearchWebsitePage(selectedOption);
                              }}
                              options={websitePages}
                              placeholder="websitePage"
                              isSearchable
                            />
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setSearchWorking("");
                                setSearchStatus("");
                                setSearchWebsitePage("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">Website Type</th>
                            )}
                            {visible.col3 && (
                              <th className="tax-name">Website Page</th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name">Website Api</th>
                            )}
                            {visible.col5 && (
                              <th className="tax-name">Status</th>
                            )}
                            {visible.col6 && <th className="active">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          {isAllow.includes(9) ? (
                            data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && (
                                    <td>{d?.website_type?.name}</td>
                                  )}
                                  {visible.col3 && (
                                    <td>{d?.website_page?.name}</td>
                                  )}
                                  {visible.col4 && <td>{d?.name}</td>}

                                  {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}

                                  {visible.col6 && (
                                    <td>
                                      <div className="d-flex">
                                        {isAllow.includes(11) ? (
                                          <EditButton
                                            handleShow1={handleShow1}
                                            id={d?.id}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        {isAllow.includes(12) ? (
                                          <DeletButton
                                            showDeleteRecord={showDeleteRecord}
                                            id={d?.id}
                                            name={d?.name}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
