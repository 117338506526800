import React, { useState, useContext, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Header from "../../Header/Header";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import AddProduct from "../AddProduct/Tables";
import ApproveProduct from "../ApproveProduct/Tables";
import RejectedProductList from "../RejectedProductList/Tables";
import { Context } from "../../../utils/context";
import AttributeContent from "./AttributeContent/AttributeContent";
import AttributeSettingContent from "./AttributeSettingContent/AttributeSettingContent";
import CatagoryContent from "./CatagoryContent/CatagoryContent";
import Caution_icons from "../Caution_icons/Tables";
import Packaging_attributes from "../Packaging_attributes/Tables";
import CustomizeProductAttribute from "../CustomizeProductAttribute/Table";
import Product_video from "../Product_video/Tables";
import BTBCancelReson from "../BTBCancelMaster/Tables";
import BTB_orders_settings from "../BTB_orders_settings/Tables";

const ProductManager = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const tabItems = [
    { key: 1, name: " Category", link: "" },
    { key: 2, name: " Attribute", link: "" },
    {
      key: 3,
      name: " list Setting",
      link: "",
    },
    { key: 4, name: " Add Product", link: "" },
    { key: 5, name: "Approve Product", link: "" },
    { key: 6, name: "Rejected product", link: "" },
    { key: 7, name: "Caution Icons", link: "" },
    { key: 8, name: "Packaging Attributes", link: "" },
    { key: 9, name: "Customize Product Attributes", link: "" },
    { key: 10, name: "Product Video Setting", link: "" },
    { key: 11, name: "BTB Cancel Reson", link: "" },
    { key: 12, name: "BTB Order Setting", link: "" },
  ];

  return (
    <>
      <Header
        title={tabItems[active]?.name}
        link={"masters/affiliated-sectors"}
      />

      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="1"
        className="tabadminmain"
      >
        <Row className=" me-0 ms-0">
          <Col xl={2} lg={2} md={4} className="p-0">
            <div className="card settingCard">
              <InputGroup className="mb-3 p-2 settingInputGrp">
                <Form.Control
                  placeholder="Search..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  onChange={handleFilterChange}
                  value={filter}
                />
              </InputGroup>

              <Nav variant="pills" className="flex-column">
                {tabItems.map(({ key, name }, index) => (
                  <Nav.Item
                    key={key}
                    style={{
                      display: name.toLowerCase().includes(filter)
                        ? "block"
                        : "none",
                    }}
                  >
                    <Nav.Link
                      eventKey={key}
                      onClick={() => {
                        setActive(index);
                      }}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          <Col xl={10} lg={10} md={8} className=" p-0">
            <Tab.Content className="tabadminmain">
              {[149, 150, 151, 152, 153, 154].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="1">
                  <CatagoryContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[155, 156, 157, 158, 159, 160].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="2">
                  <AttributeContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[161, 162, 163, 164, 165, 166].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="3">
                  <AttributeSettingContent />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[167, 168, 169, 170, 171, 172].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="4">
                  {" "}
                  <AddProduct />
                </Tab.Pane>
              ) : (
                <></>
              )}
              {[173, 174, 175, 176, 177, 178].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="5">
                  {" "}
                  <ApproveProduct />
                </Tab.Pane>
              ) : (
                <></>
              )}{" "}
              {[179, 180, 181, 182, 183, 184].some((value) =>
                isAllow.includes(value)
              ) ? (
                <Tab.Pane eventKey="6">
                  {" "}
                  <RejectedProductList />
                </Tab.Pane>
              ) : (
                <></>
              )}{" "}
              <Tab.Pane eventKey="7">
                <Caution_icons />
              </Tab.Pane>
              <Tab.Pane eventKey="8">
                <Packaging_attributes />
              </Tab.Pane>
              <Tab.Pane eventKey="9">
                <CustomizeProductAttribute />
              </Tab.Pane>
              <Tab.Pane eventKey="10">
                <Product_video />
              </Tab.Pane>
              <Tab.Pane eventKey="11">
                <BTBCancelReson />
              </Tab.Pane>
              <Tab.Pane eventKey="12">
                <BTB_orders_settings />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default ProductManager;
