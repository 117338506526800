import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";
import { faAffiliatetheme } from "@fortawesome/free-brands-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen, isAllow } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      // setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  return (
    <>
      <section className="sidebar">
        <div className="top_section">
          <Link to={'/advanceDashboard'}>
            <img
              className="logo mx-2"
              src={process.env.PUBLIC_URL + "/netpurtilogo.png"}
            />
          </Link>
          <Link to={'/advanceDashboard'}>
            <img
              className="logo2 mx-2"
              src={process.env.PUBLIC_URL + '/assets/image/logo/netpurtilogo.png'}
            />
          </Link>

          {/* <h1>Net Purti</h1> */}
        </div>
        <ul className="list-unstyled ps-0">
          {/* ***************************************************Dashboard*************************************************************** */}

          <Accordion
            defaultActiveKey="0"
            className="mb-1"
            data-bs-toggle="collapse"
            data-bs-target="#home-collapse"
            aria-expanded="false"
          >
            {/* <Accordion.Item
              eventKey="0"
              className="mb-1"
              onClick={() => handleClick("0")}
            >
             
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Dashboard</Tooltip>}
              >
                <Link to={"/advanceDashboard"}>
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-house"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      onClick={() => setSidebarOpen(true)}
                    >
                      Dashboard
                    </p>                  
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>        

            </Accordion.Item> */}

            <Accordion.Item
              eventKey="0"
              className="no-arrow mb-1"
              onClick={() => handleClick("0")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Dashboard</Tooltip>}
              >
                <Link to={"/advanceDashboard"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-house" />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Dashboard
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>
            </Accordion.Item>


            {/* *********************************************************Dashboard Ends*************************************************************** */}
            {/* **************************************************************************Setting************************************************************** */}

            <Accordion.Item
              eventKey="1"
              className="no-arrow mb-1"
              onClick={() => handleClick("1")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Setting</Tooltip>}
              >
                <Link to={"/settingContent"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-gear" />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Settings
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>
            </Accordion.Item>


            <Accordion.Item
              eventKey="25"
              className="no-arrow mb-1"
              onClick={() => handleClick("25")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Calendar</Tooltip>}
              >
                <Link to={"/calendar"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-calendar" />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Calendar
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>
            </Accordion.Item>


            {/* ************************************************************  Setting Ends ************************************************************* */}
            {/* ************************************************************  Employment Starts ************************************************************* */}
            {[1, 2, 3, 4, 5, 6, 7, 8].some((value) =>
              isAllow.includes(value)
            ) ? (
              <Accordion.Item
                eventKey="2"
                className="mb-1"
                onClick={() => handleClick("2")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Employee Management</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-user" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Employee Management
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body
                  className="p-0"
                  style={{
                    display: isOpen ? "block" : "",
                  }}
                >
                  <ul
                    className="accordion_list list-unstyled fw-normal pb-1 small"
                  // style={{
                  //   display: isSidebarOpen ? "block" : "none",
                  // }}
                  >
                    {isAllow.includes(1) ||
                      isAllow.includes(2) ||
                      isAllow.includes(3) ||
                      isAllow.includes(4) ? (
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip> Roles</Tooltip>}
                      >
                        <Link
                          to="/employee/role"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/role")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Roles
                            </p>
                          </li>
                        </Link>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}

                    {isAllow?.includes(5) ||
                      isAllow?.includes(6) ||
                      isAllow?.includes(7) ||
                      isAllow?.includes(8) ? (
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip> Employee Details</Tooltip>}
                      >
                        <Link
                          to="/employee/employee-details"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/employee/employee-details"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Employee Details
                            </p>
                          </li>
                        </Link>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  Employment Management Ends ************************************************************* */}
            {/* **************************************************************************Seller Start************************************************************** */}
            {[
              9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
              26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
              42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
              58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
              74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
              90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104,
              105, 106, 107, 140,
            ].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="3"
                className="mb-1"
                onClick={() => handleClick("3")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Seller</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-users"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      Seller
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body
                  className="p-0 inneraccoo"
                  style={{
                    display: isOpen ? "block" : "",
                  }}
                >
                  <ul
                    className="accordion_list list-unstyled fw-normal pb-1 small"
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                  >
                    <Link
                      // to="/recruiters/contact_us_list"
                      className="text-decoration-none rounded "
                    >
                      <Accordion className="accordion-item-sub">
                        <Accordion.Item eventKey="01" className="mb-1">
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Home</Tooltip>}
                          >
                            <Accordion.Header className="">
                              Home
                            </Accordion.Header>
                          </OverlayTrigger>
                          <Accordion.Body className="p-0">
                            <ul
                              className="accordion_list list-unstyled fw-normal pb-1 small"
                              style={{
                                display: isSidebarOpen ? "block" : "none",
                              }}
                            >
                              {" "}
                              {isAllow.includes(9) ||
                                isAllow.includes(10) ||
                                isAllow.includes(11) ||
                                isAllow.includes(12) ||
                                isAllow.includes(13) ||
                                isAllow.includes(14) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Banner</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-banner"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/s-banner"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Banner
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(15) ||
                                isAllow.includes(16) ||
                                isAllow.includes(17) ||
                                isAllow.includes(18) ||
                                isAllow.includes(19) ||
                                isAllow.includes(20) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Cards</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes("/seller/s-cards")
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/s-cards"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Cards
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(21) ||
                                isAllow.includes(22) ||
                                isAllow.includes(23) ||
                                isAllow.includes(24) ||
                                isAllow.includes(25) ||
                                isAllow.includes(26) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Sell Smart</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes("/seller/s-smart")
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/s-smart"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Sell Smart
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(27) ||
                                isAllow.includes(28) ||
                                isAllow.includes(29) ||
                                isAllow.includes(30) ||
                                isAllow.includes(31) ||
                                isAllow.includes(32) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Triumphs</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-triumphs"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/s-triumphs"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Triumphs
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(33) ||
                                isAllow.includes(34) ||
                                isAllow.includes(35) ||
                                isAllow.includes(36) ||
                                isAllow.includes(37) ||
                                isAllow.includes(38) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip> S. Master Art Type</Tooltip>
                                  }
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-masterart"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/s-masterart"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Master Art Type
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(39) ||
                                isAllow.includes(40) ||
                                isAllow.includes(41) ||
                                isAllow.includes(42) ||
                                isAllow.includes(43) ||
                                isAllow.includes(44) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip> S. Master Art Sub Type</Tooltip>
                                  }
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-mastersubart"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/s-mastersubart"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Master Art Sub Type
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(39) ||
                                isAllow.includes(40) ||
                                isAllow.includes(41) ||
                                isAllow.includes(42) ||
                                isAllow.includes(43) ||
                                isAllow.includes(44) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> Languages</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/languages"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/languages"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Languages
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="02" className="mb-1">
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Blog</Tooltip>}
                          >
                            <Accordion.Header className="">
                              Blog
                            </Accordion.Header>
                          </OverlayTrigger>
                          <Accordion.Body className="p-0">
                            <ul
                              className="accordion_list list-unstyled fw-normal pb-1 small"
                              style={{
                                display: isSidebarOpen ? "block" : "none",
                              }}
                            >
                              {" "}
                              {isAllow.includes(45) ||
                                isAllow.includes(46) ||
                                isAllow.includes(47) ||
                                isAllow.includes(48) ||
                                isAllow.includes(49) ||
                                isAllow.includes(50) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> Add blog</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/masters/s-blog"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/masters/s-blog"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Add blog
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(51) ||
                                isAllow.includes(52) ||
                                isAllow.includes(53) ||
                                isAllow.includes(54) ||
                                isAllow.includes(55) ||
                                isAllow.includes(56) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> Sub Blog</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/masters/s-sub-blog"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/masters/s-sub-blog"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Sub Blog
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {/* <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/masters/job-portal/home/banner-two"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/masters/job-portal/home/banner-two"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Blog Comment</p>
                              </Link>
                            </li> */}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="03" className="mb-1">
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Success Stories</Tooltip>}
                          >
                            <Accordion.Header className="">
                              Success Stories
                            </Accordion.Header>
                          </OverlayTrigger>
                          <Accordion.Body className="p-0">
                            <ul
                              className="accordion_list list-unstyled fw-normal pb-1 small"
                              style={{
                                display: isSidebarOpen ? "block" : "none",
                              }}
                            >
                              {" "}
                              {isAllow.includes(57) ||
                                isAllow.includes(58) ||
                                isAllow.includes(59) ||
                                isAllow.includes(60) ||
                                isAllow.includes(61) ||
                                isAllow.includes(62) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> Banner</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-success-banner"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/s-success-banner"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Banner
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(63) ||
                                isAllow.includes(64) ||
                                isAllow.includes(65) ||
                                isAllow.includes(66) ||
                                isAllow.includes(67) ||
                                isAllow.includes(68) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Moving</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-moving"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/s-moving"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Moving
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(69) ||
                                isAllow.includes(70) ||
                                isAllow.includes(71) ||
                                isAllow.includes(72) ||
                                isAllow.includes(73) ||
                                isAllow.includes(74) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Surmount</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-surmount"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/s-surmount"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Surmount
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(75) ||
                                isAllow.includes(76) ||
                                isAllow.includes(77) ||
                                isAllow.includes(78) ||
                                isAllow.includes(79) ||
                                isAllow.includes(80) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Relient</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-relient"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/s-relient"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Relient
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {isAllow.includes(81) ||
                                isAllow.includes(82) ||
                                isAllow.includes(83) ||
                                isAllow.includes(84) ||
                                isAllow.includes(85) ||
                                isAllow.includes(86) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> S. Women Sellers</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/s-womens"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/s-womens"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        S. Women Sellers
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="04" className="mb-1">
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> FAQ</Tooltip>}
                          >
                            <Accordion.Header className="">
                              FAQ
                            </Accordion.Header>
                          </OverlayTrigger>
                          <Accordion.Body className="p-0">
                            <ul
                              className="accordion_list list-unstyled fw-normal pb-1 small"
                              style={{
                                display: isSidebarOpen ? "block" : "none",
                              }}
                            >
                              {" "}
                              {isAllow.includes(87) ||
                                isAllow.includes(88) ||
                                isAllow.includes(89) ||
                                isAllow.includes(90) ||
                                isAllow.includes(91) ||
                                isAllow.includes(92) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> Add FAQ</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/masters/s-faq"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/masters/s-faq"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Add FAQ
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              <li
                                className={
                                  isOpen
                                    ? headerText.includes(
                                      "/seller/masters/s-sub-faq"
                                    )
                                      ? "active block"
                                      : "none"
                                    : "none"
                                }
                              // className={isActive("/masters/contact_us_list")}
                              >
                                {" "}
                                {isAllow.includes(93) ||
                                  isAllow.includes(94) ||
                                  isAllow.includes(95) ||
                                  isAllow.includes(96) ||
                                  isAllow.includes(97) ||
                                  isAllow.includes(98) ? (
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip> Sub FAQ</Tooltip>}
                                  >
                                    <Link
                                      to="/seller/masters/s-sub-faq"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Sub FAQ
                                      </p>
                                    </Link>
                                  </OverlayTrigger>
                                ) : (
                                  <></>
                                )}
                              </li>{" "}
                              {isAllow.includes(99) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> Feedback</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/masters/s-faq-feedback"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    {" "}
                                    <Link
                                      to="/seller/masters/s-faq-feedback"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        {" "}
                                        Feedback
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}{" "}
                              {isAllow.includes(100) ||
                                isAllow.includes(101) ||
                                isAllow.includes(102) ||
                                isAllow.includes(103) ||
                                isAllow.includes(104) ||
                                isAllow.includes(105) ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip> Select Topic</Tooltip>}
                                >
                                  <li
                                    className={
                                      isOpen
                                        ? headerText.includes(
                                          "/seller/masters/s-select-topic"
                                        )
                                          ? "active block"
                                          : "none"
                                        : "none"
                                    }
                                  // className={isActive("/masters/contact_us_list")}
                                  >
                                    <Link
                                      to="/seller/masters/s-select-topic"
                                      className="text-decoration-none rounded "
                                    >
                                      <p className="insideDropdown_list">
                                        Select Topic
                                      </p>
                                    </Link>
                                  </li>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                              {/*
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/seller/masters/s-faq-feedback"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/seller/masters/s-faq-feedback"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">FAQ Feedback</p>
                              </Link>
                            </li> */}
                              {/* <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "/seller/masters/s-faq-feedback"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="/seller/masters/s-faq-feedback"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">FAQ Feedback</p>
                              </Link>
                            </li> */}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="05" className="mb-1">
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Customer Management</Tooltip>}
                          >
                            <Accordion.Header className="">
                              Customer Management
                            </Accordion.Header>
                          </OverlayTrigger>
                          <Accordion.Body className="p-0">
                            {" "}
                            {/* {isAllow.includes(106) ||
                            isAllow.includes(107) ||
                            isAllow.includes(140) ? ( */}
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip> S. Personal Details</Tooltip>}
                            >
                              <li
                                className={
                                  isOpen
                                    ? headerText.includes(
                                      "/seller/customer-management/personal-details"
                                    )
                                      ? "active block"
                                      : "none"
                                    : "none"
                                }
                              // className={isActive("/masters/contact_us_list")}
                              >
                                <Link
                                  to="/seller/customer-management/personal-details"
                                  className="text-decoration-none rounded "
                                >
                                  <p className="insideDropdown_list">
                                    S. Personal Details
                                  </p>
                                </Link>
                              </li>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip> S. Plan Details</Tooltip>}
                            >
                              <li
                                className={
                                  isOpen
                                    ? headerText.includes(
                                      "/seller/customer-management/plan-details"
                                    )
                                      ? "active block"
                                      : "none"
                                    : "none"
                                }
                              // className={isActive("/masters/contact_us_list")}
                              >
                                <Link
                                  to="/seller/customer-management/plan-details"
                                  className="text-decoration-none rounded "
                                >
                                  <p className="insideDropdown_list">
                                    S. Plan Details
                                  </p>
                                </Link>
                              </li>
                            </OverlayTrigger>
                            {/* ) : (
                              <></>
                            )} */}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="06" className="mb-1">
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Order Management</Tooltip>}
                          >
                            <Accordion.Header className="">
                              Order Management
                            </Accordion.Header>
                          </OverlayTrigger>
                          <Accordion.Body className="p-0">
                            {" "}
                            {/* {isAllow.includes(106) ||
                            isAllow.includes(107) ||
                            isAllow.includes(140) ? ( */}
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip> S. Order Details</Tooltip>}
                            >
                              <li
                                className={
                                  isOpen
                                    ? headerText.includes(
                                      "/seller/customer-management/order-details"
                                    )
                                      ? "active block"
                                      : "none"
                                    : "none"
                                }

                              // className={isActive("/masters/contact_us_list")}
                              >
                                <Link
                                  to="/seller/customer-management/order-details"
                                  className="text-decoration-none rounded "
                                >
                                  <p className="insideDropdown_list">

                                    S. Order Details
                                  </p>
                                </Link>
                              </li>
                            </OverlayTrigger>
                            {/* <li
                                className={
                                  isOpen
                                    ? headerText.includes(
                                        "/seller/customer-management/plan-details"
                                      )
                                      ? "active block"
                                      : "none"
                                    : "none"
                                }
                                // className={isActive("/masters/contact_us_list")}
                              >
                                <Link
                                  to="/seller/customer-management/plan-details"
                                  className="text-decoration-none rounded "
                                >
                                  <p className="insideDropdown_list">
                                    S. Plan Details
                                  </p>
                                </Link>
                              </li> */}
                            {/* ) : (
                              <></>
                            )} */}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="07" className="mb-1">
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> All Products</Tooltip>}
                          >
                            <Accordion.Header className="">
                              All Products
                            </Accordion.Header>
                          </OverlayTrigger>
                          <Accordion.Body className="p-0">
                            {" "}
                            {/* {isAllow.includes(106) ||
                            isAllow.includes(107) ||
                            isAllow.includes(140) ? ( */}
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip> All Products</Tooltip>}
                            >
                              <li
                                className={
                                  isOpen
                                    ? headerText.includes(
                                      "/seller/product/all-products"
                                    )
                                      ? "active block"
                                      : "none"
                                    : "none"
                                }
                              // className={isActive("/masters/contact_us_list")}
                              >
                                <Link
                                  to="/seller/product/all-products"
                                  className="text-decoration-none rounded "
                                >
                                  <p className="insideDropdown_list">
                                    All Products
                                  </p>
                                </Link>
                              </li>
                            </OverlayTrigger>
                            {/* ) : (
                              <></>
                            )} */}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  Seller End ************************************************************* */}
            {/* ************************************************************  Media Start ************************************************************* */}
            {[108, 109, 141].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="4"
                className="mb-1"
                onClick={() => handleClick("4")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Media</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-tv"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      Media
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>
                <Accordion.Body className="p-0">
                  {isAllow.includes(108) ||
                    isAllow.includes(109) ||
                    isAllow.includes(141) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip> M. Personal Details</Tooltip>
                      }
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/media/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/media/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            M. Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  Media End ************************************************************* */}
            {/* ************************************************************  Checker Start ************************************************************* */}
            {[110, 111, 142].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="5"
                className="mb-1"
                onClick={() => handleClick("5")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Checker</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-flag-checkered"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      Checker
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>
                <Accordion.Body className="p-0">
                  {" "}
                  {isAllow.includes(110) ||
                    isAllow.includes(111) ||
                    isAllow.includes(142) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>C. Personal Details</Tooltip>}
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/checker/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/checker/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            C. Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  Media End ************************************************************* */}



            {/* ************************************************************  Affiliate Start ************************************************************* */}
            {[112, 113, 143].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="6"
                className="mb-1"
                onClick={() => handleClick("6")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Affiliate-Influencer</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon={faAffiliatetheme} />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      Affiliate-Influencer
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>
                <Accordion.Body className="p-0">
                  {isAllow.includes(112) ||
                    isAllow.includes(113) ||
                    isAllow.includes(143) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip> A. Personal Details</Tooltip>
                      }
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/affiliate/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/affiliate/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            A. Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Dashboard</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/affiliatedashboard"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/affiliatedashboard"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">
                          Dashboard
                        </p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Inhouse Ads</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/inhouseads"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/inhouseads"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">
                          Inhouse Ads
                        </p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip> Seller Influencer Activity</Tooltip>
                    }
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/seller-influencer"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/seller-influencer"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">
                          Seller Influencer Activity
                        </p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> New Link</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/new-link"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/new-link"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">
                          New Link
                        </p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip> Stricks</Tooltip>
                    }
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/stricks"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/stricks"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">
                          Stricks
                        </p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip> Affiliate User Management</Tooltip>
                    }
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/affilate-user-management"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/affilate-user-management"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">
                          Affiliate User Management
                        </p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  {/* <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip>  Fee Structure</Tooltip>
                              }
                            >
                              <li
                                className={
                                  isOpen
                                    ? headerText.includes(
                                      ""
                                    )
                                      ? "active block"
                                      : "none"
                                    : "none"
                                }
                              // className={isActive("/masters/contact_us_list")}
                              >
                                <Link
                                  to=""
                                  className="text-decoration-none rounded "
                                >
                                  <p className="insideDropdown_list">
                                  Fee Structure
                                  </p>
                                </Link>
                              </li>
                            </OverlayTrigger> */}

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Affiliate Activity</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/affiliate-activity"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/affiliate-activity"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">
                          Affiliate Activity
                        </p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Guide</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/affiliate-guide"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/affiliate-guide"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">Guide</p>
                      </Link>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Setting</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "/btb/affiliate/affilate-setting"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="/btb/affiliate/affilate-setting"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">Setting</p>
                      </Link>
                    </li>
                  </OverlayTrigger>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  Affiliate End ************************************************************* */}




            {/* ************************************************************  Brand Start ************************************************************* */}
            {[114, 115, 144].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="7"
                className="mb-1"
                onClick={() => handleClick("7")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Brand</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-shirt"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      Brand
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>
                <Accordion.Body className="p-0">
                  {" "}
                  {isAllow.includes(114) ||
                    isAllow.includes(115) ||
                    isAllow.includes(144) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip> B. Personal Details</Tooltip>
                      }
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/brand/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/brand/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            B. Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  Brand End ************************************************************* */}



            {/* ************************************************************  Transporter Start ************************************************************* */}
            {[116, 117, 145].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="8"
                className="mb-1"
                onClick={() => handleClick("8")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Transporter</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-motorcycle"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      Transporter
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>
                <Accordion.Body className="p-0">
                  {" "}
                  {isAllow.includes(116) ||
                    isAllow.includes(117) ||
                    isAllow.includes(145) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip> T. Personal Details</Tooltip>
                      }
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/transporter/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/transporter/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            T. Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  Transporter End ************************************************************* */}



            {/* ************************************************************  Three P Start ************************************************************* */}
            {[118, 119, 146].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="9"
                className="mb-1"
                onClick={() => handleClick("9")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> Three P</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-users"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      Three P
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>
                <Accordion.Body className="p-0">
                  {isAllow.includes(118) ||
                    isAllow.includes(119) ||
                    isAllow.includes(146) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip> Three P. Personal Details</Tooltip>
                      }
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/three-p/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/three-p/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            Three P. Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}


            {/* ************************************************************  Three P End ************************************************************* */}
            {/* ************************************************************  BTB Start ************************************************************* */}



            {[
              120, 121, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134,
              135, 136, 137, 138, 139, 147,
            ].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="10"
                className="mb-1"
                onClick={() => handleClick("10")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> BTB</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-cart-shopping"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      BTB
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>


                <Accordion.Body className="p-0">
                  {" "}
                  {isAllow.includes(120) ||
                    isAllow.includes(121) ||
                    isAllow.includes(147) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip> BTB Personal Details</Tooltip>
                      }
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/btb/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/btb/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            BTB Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </Accordion.Body>


              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* ************************************************************  BTB End ************************************************************* */}
            {/* *************************************************************** BTC Starts ***************************************************** */}


            {[122, 123, 148].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="11"
                className="mb-1"
                onClick={() => handleClick("11")}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip> BTC</Tooltip>}
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon
                        icon="fa-solid fa-cart-shopping"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      className="sidebar_txt"
                    >
                      BTC
                    </p>
                  </Accordion.Header>
                </OverlayTrigger>

                <Accordion.Body className="p-0">
                  {isAllow.includes(122) ||
                    isAllow.includes(123) ||
                    isAllow.includes(148) ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip> BTC Personal Details</Tooltip>
                      }
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                              "/btc/customer-management/personal-details"
                            )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      // className={isActive("/masters/contact_us_list")}
                      >
                        <Link
                          to="/btc/customer-management/personal-details"
                          className="text-decoration-none rounded "
                        >
                          <p className="insideDropdown_list">
                            BTC Personal Details
                          </p>
                        </Link>
                      </li>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}
            {/* *************************************************************** BTC Ends ***************************************************** */}
            {/* ************************************************************  Media Start ************************************************************* */}
            {/* {[108, 109, 141].some((value) => isAllow.includes(value)) ? ( */}
            <Accordion.Item
              eventKey="15"
              className="mb-1"
              onClick={() => handleClick("7")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> My Website</Tooltip>}
              >
                <Accordion.Header>
                  <div className=" sidebar-menu-icon">
                    <FontAwesomeIcon
                      icon="fa-solid fa-tv"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                    className="sidebar_txt"
                  >
                    My Website
                  </p>
                </Accordion.Header>
              </OverlayTrigger>
              <Accordion.Body
                className="p-0"
                style={{
                  display: isOpen ? "block" : "",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    // to="/recruiters/contact_us_list"
                    className="text-decoration-none rounded "
                  >
                    <Accordion className="accordion-item-sub">
                      <Accordion.Item eventKey="01" className="mb-1">
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip> Home</Tooltip>}
                        >
                          <Accordion.Header className="">Home</Accordion.Header>
                        </OverlayTrigger>
                        <Accordion.Body className="p-0">
                          {/* {isAllow.includes(108) ||
                            isAllow.includes(109) ||
                            isAllow.includes(141) ? ( */}
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M. W. Banner</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-home-banner")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-banner"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M. W. Banner
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                          {/* ) : (
                            <></>
                          )} */}
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M. W. Designer</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "my-webite-home-designer"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-designer"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M. W. Designer
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M. W. Reason</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-home-reason")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-reason"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M. W. Reason
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M. W. Review</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-home-review")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-review"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M. W. Review
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M. W. Feature</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "my-webite-home-feature"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-feature"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M. W. Feature
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M. W. Blogs</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-home-blogs")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-blogs"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M. W. Blogs
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M. W. Resources</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "my-webite-home-resources"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-resources"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M. W. Resources
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="02" className="mb-1">
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip> FAQ</Tooltip>}
                        >
                          <Accordion.Header className="">FAQs</Accordion.Header>
                        </OverlayTrigger>
                        <Accordion.Body className="p-0">
                          {/* {isAllow.includes(122) ||
                              isAllow.includes(123) ||
                              isAllow.includes(148) ? ( */}
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> FAQ Category</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "my-webite-home-faq-category"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }

                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-faq-category"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  FAQ Category
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                          {/* ) : (
                              <></>
                            )} */}
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> FAQ</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-home-faq")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-faq"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">FAQ</p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="03" className="mb-1">
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip> Pricing</Tooltip>}
                        >
                          <Accordion.Header className="">
                            Pricing
                          </Accordion.Header>
                        </OverlayTrigger>
                        <Accordion.Body className="p-0">
                          {/* {isAllow.includes(122) ||
                              isAllow.includes(123) ||
                              isAllow.includes(148) ? ( */}
                          {/* <li
                            className={
                              isOpen
                                ? headerText.includes("my-webite-home-league")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                            // className={isActive("/masters/contact_us_list")}
                          >
                            <Link
                              to="my-webite-home-league"
                              className="text-decoration-none rounded "
                            >
                              <p className="insideDropdown_list">League</p>
                            </Link>
                          </li> */}
                          {/* ) : (
                              <></>
                            )} */}
                          {/* <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "my-webite-home-type-plan"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                            // className={isActive("/masters/contact_us_list")}
                          >
                            <Link
                              to="my-webite-home-type-plan"
                              className="text-decoration-none rounded "
                            >
                              <p className="insideDropdown_list">Plan Type</p>
                            </Link>
                          </li> */}
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> M W Feature Category</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("m-w-c-feature")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="m-w-c-feature"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M W Feature Category{" "}
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip> M W Feature Subcategory</Tooltip>
                            }
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("m-w-s-feature")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="m-w-s-feature"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  M W Feature Subcategory{" "}
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Plan</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-home-plan")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-home-plan"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Plan</p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Theme Category</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "my-webite-category-them"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-category-them"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Theme Category
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Theme SubCategory</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "my-webite-subcategory-them"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-subcategory-them"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Theme SubCategory
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Theme</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-them")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-them"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Theme</p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Sections</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-sections")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-sections"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Sections</p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Domain Information</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("domain-information")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="domain-information"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Domain Information</p>
                              </Link>
                            </li>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Seller Plans</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-seller-plans")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-seller-plans"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">Seller Plans</p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="04" className="mb-1">
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip> Portfolio</Tooltip>}
                        >
                          <Accordion.Header className="">
                            Portfolio
                          </Accordion.Header>
                        </OverlayTrigger>
                        <Accordion.Body className="p-0">
                          {/* {isAllow.includes(122) ||
                              isAllow.includes(123) ||
                              isAllow.includes(148) ? ( */}
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Portfolio Category</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes(
                                    "my-webite-portfolio-category"
                                  )
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-portfolio-category"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Portfolio Category
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                          {/* ) : (
                              <></>
                            )} */}

                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Portfolios</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("my-webite-portfolios")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="my-webite-portfolios"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Portfolios
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>


            {/* ) : (
              <></>
            )} */}
            {/* ************************************************************  Media End ************************************************************* */}

            <Accordion.Item
              eventKey="16"
              className="mb-1"
              onClick={() => handleClick("16")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Api Documentation</Tooltip>}
              >
                <Accordion.Header>
                  <div className=" sidebar-menu-icon">
                    <FontAwesomeIcon
                      icon="fa-solid fa-cart-shopping"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                    className="sidebar_txt"
                  >
                    Api Documentation
                  </p>
                </Accordion.Header>
              </OverlayTrigger>
              <Accordion.Body
                className="p-0 inneraccoo"
                style={{
                  display: isOpen ? "block" : "",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  {/* {isAllow.includes(122) ||
                            isAllow.includes(123) ||
                            isAllow.includes(148) ? ( */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Website Pages</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes(
                            "api-documentation/website-page"
                          )
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="api-documentation/website-page"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">Website Pages</p>
                      </Link>
                    </li>
                  </OverlayTrigger>
                  {/* ) : (
                            <></>
                          )} */}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip> Website Apis</Tooltip>}
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("api-documentation/website-api")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    // className={isActive("/masters/contact_us_list")}
                    >
                      <Link
                        to="api-documentation/website-api"
                        className="text-decoration-none rounded "
                      >
                        <p className="insideDropdown_list">Website Apis</p>
                      </Link>
                    </li>
                  </OverlayTrigger>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="17"
              className="no-arrow mb-1"
              onClick={() => handleClick("17")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> User Onboarding</Tooltip>}
              >
                <Link to={"/userOnboarding"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-user" />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      User Onboarding
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>

              {/* </div> */}
            </Accordion.Item>

            <Accordion.Item
              eventKey="18"
              className="no-arrow mb-1"
              onClick={() => handleClick("18")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> RTO | Refund | Return</Tooltip>}
              >
                <Link
                  to={"/rto-refund-return"}
                  className=" text-decoration-none"
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-arrow-rotate-left" />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      RTO | Refund | Return
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>

              {/* </div> */}
            </Accordion.Item>

            <Accordion.Item
              eventKey="19"
              className="no-arrow mb-1"
              onClick={() => handleClick("19")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Afiliate</Tooltip>}
              >
                <Link to={"/afiliate"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon={faAffiliatetheme} />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Afiliate
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>

              {/* </div> */}
            </Accordion.Item>

            <Accordion.Item
              eventKey="20"
              className="no-arrow mb-1"
              onClick={() => handleClick("20")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Product Manager</Tooltip>}
              >
                <Link to={"/product-manager"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-people-roof" />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Product Manager
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>

              {/* </div> */}
            </Accordion.Item>

            <Accordion.Item
              eventKey="21"
              className="no-arrow mb-1"
              onClick={() => handleClick("21")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Product Brand</Tooltip>}
              >
                <Link to={"/account-payment"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Account | Payment
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>

              {/* </div> */}
            </Accordion.Item>

            <Accordion.Item
              eventKey="22"
              className="no-arrow mb-1"
              onClick={() => handleClick("22")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Product Brand</Tooltip>}
              >
                <Link to={"/product-brand"} className=" text-decoration-none">
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon="fa-solid fa-shirt " />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Product Brand
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>

              {/* </div> */}
            </Accordion.Item>



            {/* <Accordion.Item
              eventKey="23"
              className="no-arrow mb-1"
              onClick={() => handleClick("23")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip> Affiliate-Influencer 2</Tooltip>}
              >
                <Link
                  to={"/affiliate-influencer"}
                  className=" text-decoration-none"
                >
                  <Accordion.Header>
                    <div className=" sidebar-menu-icon">
                      <FontAwesomeIcon icon={faAffiliatetheme} />
                    </div>

                    <p
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                      className="sidebar_txt"
                    >
                      Affiliate-Influencer 2
                    </p>
                  </Accordion.Header>
                </Link>
              </OverlayTrigger>
            </Accordion.Item> */}



            {/* ************************************************************  Media Start ************************************************************* */}
            {/* {[108, 109, 141].some((value) => isAllow.includes(value)) ? ( */}
            <Accordion.Item
              eventKey="24"
              className="mb-1"
              onClick={() => handleClick("24")}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Support</Tooltip>}
              >
                <Accordion.Header>
                  <div className=" sidebar-menu-icon">
                    <FontAwesomeIcon
                      icon="fa-solid fa-headset"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                    className="sidebar_txt"
                  >
                    Support
                  </p>
                </Accordion.Header>
              </OverlayTrigger>
              <Accordion.Body
                className="p-0"
                style={{
                  display: isOpen ? "block" : "",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                  style={{
                    display: isSidebarOpen ? "block" : "none",
                  }}
                >
                  <Link
                    // to="/recruiters/contact_us_list"
                    className="text-decoration-none rounded "
                  >
                    <Accordion className="accordion-item-sub">
                      <Accordion.Item eventKey="01" className="mb-1">
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip> Queries</Tooltip>}
                        >
                          <Accordion.Header className="">Queries</Accordion.Header>
                        </OverlayTrigger>
                        <Accordion.Body className="p-0">
                          {/* {isAllow.includes(108) ||
                            isAllow.includes(109) ||
                            isAllow.includes(141) ? ( */}
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip> Queries</Tooltip>}
                          >
                            <li
                              className={
                                isOpen
                                  ? headerText.includes("support-queries")
                                    ? "active block"
                                    : "none"
                                  : "none"
                              }
                            // className={isActive("/masters/contact_us_list")}
                            >
                              <Link
                                to="support-queries"
                                className="text-decoration-none rounded "
                              >
                                <p className="insideDropdown_list">
                                  Queries
                                </p>
                              </Link>
                            </li>
                          </OverlayTrigger>
                          {/* ) : (
                            <></>
                          )} */}

                        </Accordion.Body>
                      </Accordion.Item>


                    </Accordion>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            {/* ) : (
              <></>
            )} */}
            {/* ************************************************************  Media End ************************************************************* */}
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;