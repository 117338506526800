import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension, Select2Data } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });


  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
    trigger, setValue
  } = useForm();

  const imageFile = watch("image");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections", // Name of your field array
  });

  const [category, setCountries] = useState([]);

  const GetAllCountry = async () => {
    const response = await getData("/my-website/masters/masters/allFaqCategory");

    if (response?.success) {
      setCountries(await Select2Data(response?.data, "faq_cat_id"));
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/my-website/pricing/them/${id}`);
    reset(response?.data);

  };
  console.log(imageFile);

  useEffect(() => {
    GetAllCountry();
    GetEditData();
  }, []);

  const [themeCategory, setThemeCategory] = useState([]);
  const [themeSubCategory, setThemeSubCategory] = useState([]);
  const [sections, setSections] = useState([]);
  const GetAllThemeCategory = async () => {
    const response = await getData("/my-website/masters/masters/allThemecategory");

    if (response?.success) {
      setThemeCategory(await Select2Data(response?.data, "faq_cat_id"));
    }
  };
  const GetAllThemeSubCategory = async (id) => {
    const response = await getData("/my-website/masters/masters/allThemesubcategory");

    if (response?.success) {
      setThemeCategory(await Select2Data(response?.data, "faq_cat_id"));
    }
  };
  const GetAllSection = async () => {
    const response = await getData("/my-website/masters/masters/allsections");

    if (response?.success) {
      setSections(await Select2Data(response?.data, "section_id"));
    }
  };

  useEffect(() => {
    GetAllThemeCategory();
    GetAllThemeSubCategory();
    GetAllSection();
  }, []);
  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("demo_url", data?.demo_url);
      if (data.image && data.image.length > 0) {
        for (let i = 0; i < data.image.length; i++) {
          finalData.append(`images${i}`, data.image[i]);
        }
      }
      finalData.append("imageCount", data.image.length);
      finalData.append("description", data?.description);
      finalData.append("theme_category_id", data?.theme_category_id?.value);
      finalData.append(
        "theme_sub_category_id",
        data?.theme_sub_category_id?.value
      );

      const sections = [];
      data.sections.forEach((section, index) => {
        sections.push({
          section_id: section.section_id?.value,
          url: section.url,
        });
      });

      finalData.append("sections", JSON.stringify(sections));

      const response = await postData(`/my-website/pricing/them/${id}`, finalData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  // const onSubmit = async (data) => {
  //   try {
  //     console.log(data);
  //     const finalData = new FormData();
  //     finalData.append("faq_cat_id", data?.faq_cat_id?.value);
  //     finalData.append("name", data?.name);
  //     finalData.append("description", data?.description);

  //     const response = await postData(`/my-website/home/faq/${id}`, finalData);
  //     console.log(response);
  //     if (response?.success) {
  //       await setShowModal({ code: response.code, message: response.message });
  //     } else {
  //       await setShowModal({ code: response.code, message: response.message });
  //     }
  //     setTimeout(() => {
  //       setShowModal(0);
  //       props.handleClose();
  //     }, 1000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const appendData = () => {
    append({
      section_id: "",
      url: "",
    });
  }

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Theme
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Theme Category</Form.Label>

                    <Controller
                      name="theme_category_id" // name of the field
                      {...register("theme_category_id", {
                        required: "Select  Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.theme_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={themeCategory}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllThemeSubCategory(selectedOption.value);
                            setValue("theme_category_id", selectedOption);
                          }}
                        />
                      )}
                    />

                    {errors.child_category_id && (
                      <span className="text-danger">
                        {errors.child_category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Theme Sub Category</Form.Label>

                    <Controller
                      name="theme_sub_category_id" // name of the field
                      // {...register("theme_sub_category_id", {
                      //   required: "Select  Category",
                      // })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.theme_sub_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={themeSubCategory}
                        />
                      )}
                    />

                    {errors.theme_sub_category_id && (
                      <span className="text-danger">
                        {errors.child_catetheme_sub_category_idgory_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Theme Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Theme Category"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>{" "}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Demo Url  <sup className="text-warning">( Mention Url for static live preview of theme )</sup></Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="demo_url"
                          placeholder="Demo Url"
                          className={classNames("", {
                            "is-invalid": errors?.demo_url,
                          })}
                          {...register("demo_url", {
                            required: "Demo Url is required",
                          })}
                        />
                      </InputGroup>
                      {errors.demo_url && (
                        <span className="text-danger">
                          {errors.demo_url.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    {" "}
                    {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field?.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>{" "}

              <div className="mt-3">
                <p className="text-info">Add Sections which are in the design of theme</p>
                <sup className="text-warning">( The tabs will be shown in seller admin as per sections )</sup>
              </div>
              {fields.map((section, index) => (
                <div key={section.id} >
                  <Row>
                    <Col md={4}>
                      <div className="main-form-section mt-1">
                        <Row className="row justify-content-center mb-2">
                          {index === 0 && (
                            <Form.Label>section</Form.Label>
                          )}
                          

                          <Controller
                            name="section_id" // name of the field
                            {...register(`sections.${index}.section_id`, {
                              required: "Select  section",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors?.sections?.[index]?.section_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={sections}

                              />
                            )}
                          />

                          {errors?.sections?.[index]?.section_id && (
                            <span className="text-danger">
                              {errors?.sections?.[index]?.section_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    {/* <Col md={4}>
                      <div className="main-form-section mt-1">
                        <Row className="justify-content-center">
                        {index === 0 && (
                          <Form.Label>URL</Form.Label>
                        )}
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="url"
                                placeholder="Section URL"
                                className={classNames("", {
                                  "is-invalid": errors?.sections?.[index]?.url,
                                })}
                                {...register(`sections.${index}.url`, {
                                  // required: "URL is required",
                                })}
                              />
                            </InputGroup>
                            {errors?.sections?.[index]?.url && (
                              <span className="text-danger">
                                {errors?.sections?.[index]?.url.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col> */}
                    <Col md={4}>
                    <button
                      className="removesecbttt"
                      type="text"
                      onClick={() => remove(index)}
                    >
                      - Remove Section
                    </button>
                    </Col>
                  </Row>


                </div>
              ))}


              <button className="addsecbttt" type="button" onClick={() => appendData()}>
                + Add Section
              </button>

              <div className="main-form-section mt-3">
                <Row className="justify-content-start">
                <Form.Label className="text-left">Image <sub className="text-info">Select Atleast 5 Screenshots of Theme</sub></Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.image,
                      })}
                      type="file"
                      {...register("image", {
                        // validate: async (value) => {
                        //   if (typeof value !== "string") {
                        //     const fileTypes = ["jpg", "png", "jpeg"];
                        //     const fileType = value[0].name?.split(".")[1];
                        //     if (!fileTypes.includes(fileType)) {
                        //       return `please upload a valid file format. (${fileTypes})`;
                        //     }
                        //     const sizes = await getDimension(value[0]);
                        //     if (
                        //       sizes.width !== 420 &&
                        //       sizes.height !== 520
                        //     ) {
                        //       return "Image width and height must be 420 px and 520 px";
                        //     }
                        //     const fileSize = Math.round(
                        //       value[0].size / 1024
                        //     );
                        //     if (fileSize > 500) {
                        //       return "file size must be lower than 500kb";
                        //     }
                        //   }
                        // },
                      })}
                      accept=".jpg, .jpeg, .png"

                      multiple
                      onChange={() => setValue('image', [])}
                    />
                  </Form.Group>
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </Row>
              </div>
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {getValues(`image`)?.length > 0 ? (
                        <div className="image-preview-container">
                          {getValues('image')?.map((value) => (
                            <img
                              src={IMG_URL + value?.image}
                              alt="Preview"
                              className="image-preview ms-3"
                              style={{ width: "150px", height: "130px" }}
                            />
                          ))}
                        </div>
                      ) : (
                        imageFile && imageFile.length > 0 && (
                          <div className="image-preview-container">
                            {imageFile && imageFile.length > 0 && (
                              [...Array(imageFile.length)].map((_, i) => (
                                <img
                                  src={URL.createObjectURL(imageFile[i])}
                                  alt={`Preview ${i}`}
                                  className="image-preview ms-3"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              ))
                            )}
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
