import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import { Col, Nav, Row, Tab, Form, } from 'react-bootstrap'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const NewCustomerTable = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const [filterText, setFilterText] = useState('');
    const columns = [
        {
            name: 'Sr. No',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Id',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
        },
    ];

    const data = [
        {
            id: 1,
            year: '1988',
            title: 'Beetlejuice',
            name: 'John Doe',
            email: 'john.doe@example.com',
            phone: '+1234567890',
            address: '123 Main St',
            city: 'New York',
            country: 'USA'
        },
        {
            id: 2,
            year: '1984',
            title: 'Ghostbusters',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
            phone: '+1987654321',
            address: '456 Elm St',
            city: 'Los Angeles',
            country: 'USA'
        }
    ];


    const filteredData = data.filter(item =>
        item.title.toLowerCase().includes(filterText.toLowerCase())
    );

    const handleFilterChange = e => {
        setFilterText(e.target.value);
    };
    return (
        <>
            <section className='Walletdashboard'>
                <div className=' dashboard-in customer-table my-5'>
                    <h2>New Customer </h2>
                    <Row className='mb-3'>
                        <Col xl={3} lg={4} md={6} sm={12}>
                            <Form.Label>Search</Form.Label>
                            <Form.Control
                                required
                                type="text"
                            />
                        </Col>

                        <Col xl={3} lg={4} md={6} sm={12}>
                            <Form.Label>From</Form.Label>
                            <Form.Control
                                required
                                type="Date"
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12}>
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                required
                                type="Date"
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12}>
                            <Form.Label>Sort By</Form.Label>
                            <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12}>
                            <Form.Label>Sort By</Form.Label>
                            <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12}>
                            <Form.Label>Download data in Excel</Form.Label><br />
                            <FontAwesomeIcon icon="fa-solid fa-file-lines" className='pdf-icon' />
                        </Col>
                    </Row>
                    <div className='datatableMain'>
                        <Row>
                            <Col xl={3} lg={4} md={6} sm={12}>
                                <input
                                    type='text'
                                    placeholder='Search by title...'
                                    value={filterText}
                                    onChange={handleFilterChange}
                                />
                            </Col>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            selectableRows
                            pagination
                            defaultSortFieldId={1}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewCustomerTable