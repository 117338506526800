import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ViewOffCanvance.css";
import { Context } from "../../../../utils/context";

import Select from 'react-select';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import classNames from "classnames";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TabPanel from "@mui/lab/TabPanel";
import "react-phone-input-2/lib/bootstrap.css";
import { getAffiliateDetails } from "../../../../utils/apis/affiliate";

library.add(fas);

const ViewOffCanvance = (props) => {

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
} = useForm();
  const [value, setValueTab] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const { getData, IMG_URL } = useContext(Context);

  
  const [influencerData, setInfluencerData] = useState();
  const [openStatus, setOpenStatus] = useState(0);
  const [influemodalShow, influesetModalShow] = useState(false);


  
  const [recieveStatus, setReciveStatus] = useState(false);
  const [recieve, setRecive] = useState(0);

  const [platformData, setPlatformData] = useState();
  const [socialLink, setSocialLink] = useState([]);
  const [languages, setLanguage] = useState([]);
  const [categories, setCategory] = useState([]);
  const [currencies, setCurrency] = useState([]);
  const [countries, setCountries] = useState([]);
  const [socialPlatform, setSocialPlatform] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [addId, setAddId] = useState("");
  const [data, setData] = useState();
  
  const [discount, setDiscount] = useState(0);
  const getSellerData = async () => {
    const res = await getAffiliateDetails(props.id);
    setData(res.data);

    await setValue('package_amount', res?.data?.influencer_open?.package_amount);
    await setValue('visit_cost', res?.data?.influencer_open?.visit_cost);
    await setValue('visit_currency_id', { value: res?.data?.influencer_open?.visit_currency?.id, name: 'currency_id', label: res?.data?.influencer_open?.visit_currency?.name + " " + res?.data?.influencer_open?.visit_currency?.symbol });
    await setValue('open_currency_id', { value: res?.data?.influencer_open?.open_currency?.id, name: 'currency_id', label: res?.data?.influencer_open?.open_currency?.name + " " + res?.data?.influencer_open?.open_currency?.symbol });

    await setValue('gst_no', res?.data?.a_firm_detail?.gst_no);
    await setValue('pan_no', res?.data?.a_firm_detail?.pan_no);

    await setValue('branch_name', res?.data?.a_bank_detail?.branch_name);
    await setValue('micr_code', res?.data?.a_bank_detail?.micr_code);
    await setValue('ifsc_code', res?.data?.a_bank_detail?.ifsc_code);
    await setValue('bank_account_no', res?.data?.a_bank_detail?.bank_account_no);
    await setValue('bank_name', res?.data?.a_bank_detail?.bank_name);
    
    await setValue('contact_no', res?.data?.contact_no);
    await setValue('email', res?.data?.email);

    await setValue('open_influencer', res?.data?.influencer_open?.status ? { value: '1', label: 'Yes' } : { value: '0', label: 'NO' });
    await setValue('recieve_status', res?.data?.influencer_open?.place_visit_status ? { value: '1', label: 'Yes' } : { value: '0', label: 'NO' });

    await setReciveStatus(res?.data?.influencer_open?.place_visit_status == 1);
    await setRecive(res?.data?.influencer_open?.place_visit_status);
    await setOpenStatus(res?.data?.influencer_open?.status);
    await influesetModalShow(res?.data?.influencer_open?.status == 1);
    await setDiscount(res?.data?.influencer_open?.discount);


    let selectLanguage = [];
    res?.data?.influencer_languages?.map((value) => {
                selectLanguage.push({ value: value?.language?.id, label: value?.language?.name });
            });

            await setValue('influencerLanguages', selectLanguage);
  };

  const options = [
    { value: "1", label: "Yes" },
    { value: "0", label: "NO" },
];
  useEffect(() => {
    getSellerData();
  }, [props.id]);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "auto" }}
        placement={"end"}
        onHide={props.handleClose}
        className="offcan"
      >
        <Container className="tabss-main">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>
                  <h3>Seller Details </h3>
                </Card.Title>

                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Personal Details" value="1" />
                        <Tab label="Social Links" value="2" />
                        <Tab label="Influencer Details" value="3" />
                        {/* <Tab label="Firm Details" value="4" />
                        <Tab label="Bank Details" value="5" /> */}
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div>
                        <div>
                          <h3 className="titleName">Personal Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.f_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.l_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Contact Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.contact_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.email}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Designation</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.designation}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div>
                        <div>
                          <h3 className="titleName">Social Links</h3>
                        </div>

                        <div>
                          <Row>
                            {data?.a_social_details?.map((val) => (
                              <Col md={4}>
                                <div className='d-flex socialmeddlinkflex'>

                                  <div className='data'>

                                    <div>
                                      <p><b>Platform : </b><img width={20} src={IMG_URL + val?.social_platform?.image} /> {val?.social_platform?.name}</p>
                                      <p><b>Page Link : </b><a href={val?.page_link} target='_blank'>{val?.page_link}</a></p>
                                      <p><b>Page Name : </b>{val?.page_name}</p>
                                      <p><b>Subscribers / Followers : </b>{val?.subscriber}</p>
                                    </div>
                                  </div>


                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div>
                        <div>
                          <h3 className="titleName">Influencer Details</h3>
                        </div>
                        <div>
                          <div className='row'>
                            <div className='col-md-3'>
                              <h5 className='aff-title'><span className='orange-border'>Influencer</span> Language</h5>
                              <div className='row my-3'>
                                <div className='col-md-12 mb-2'>
                                  <Form.Label>Language</Form.Label>

                                  <Controller
                                    name="influencerLanguages" // name of the field
                                    {...register("influencerLanguages", {
                                      // required: "Select Category",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        styles={{
                                          control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors.influencerLanguages
                                              ? "red"
                                              : baseStyles,
                                          }),
                                        }}
                                        {...field}
                                        isMulti
                                        options={languages}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            
                            {/* <div className='col-md-3'>
                              <h5 className='aff-title'><span className='orange-border'>Currency</span></h5>
                              <div className='row my-3'>
                                <div className='col-md-12 mb-2'>
                                  <Form.Group className="mb-3 " controlId="">
                                    <Form.Label>Currency</Form.Label>
                                    <Controller
                                      name="currency_id" // name of the field
                                      {...register("currency_id", {
                                        // required: "Select Category",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.currency_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={currencies}
                                        />
                                      )}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </div> */}
                            <div className='col-md-3'>
                              <h5 className='aff-title'><span className='orange-border'>Open</span> for influencer</h5>
                              <Form className='mt-5'>

                                <div key="openStatus" className="mb-3">



                                  <Controller
                                    name="open_influencer"
                                    {...register("open_influencer", {
                                      // required: "Available for review is required",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        styles={{
                                          control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors.open_influencer
                                              ? "red"
                                              : baseStyles,
                                          }),
                                        }}
                                        {...field}
                                        options={options}
                                        placeholder="--- Please select ---"
                                      />
                                    )}
                                  />

                                  {/* <Form.Check
                                    inline
                                    label="Yes"
                                    name="openStatus"
                                    type="radio"
                                    value={1}
                                    defaultChecked={openStatus == 1}

                                    onClick={(e) => handleOpenInfluence(e.target.value, getValues('package_amount'), discount, getValues('open_currency_id'))}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="openStatus"
                                    type="radio"
                                    value={0}
                                    defaultChecked={openStatus == 0}

                                    onClick={(e) => handleOpenInfluence(e.target.value, getValues('package_amount'), discount, getValues('open_currency_id'))}
                                /> */}

                                </div>


                                {influemodalShow && (
                                  <>
                                    <Form.Group className="mb-3" controlId="">
                                      <Form.Label>Currency</Form.Label>
                                      <Controller
                                        name="open_currency_id" // name of the field
                                        {...register("open_currency_id", {
                                          // required: "Select Category",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            styles={{
                                              control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: errors.open_currency_id
                                                  ? "red"
                                                  : baseStyles,
                                              }),
                                            }}
                                            {...field}
                                            options={currencies}
                                          />
                                        )}
                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="">
                                      <Form.Label>Influencer package Amount</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="package_amount"
                                        placeholder="package_amount"
                                        className={classNames("", {
                                          "is-invalid": errors?.package_amount,
                                        })}
                                        {...register("package_amount", {
                                          required: "package amount is required",
                                        })}
                                      />
                                      {errors.package_amount && (
                                        <span className="text-danger">
                                          {errors.package_amount.message}
                                        </span>
                                      )}
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="discount">
                                      <Form.Label>Discount</Form.Label>
                                      <div key="inline-radio" className="mb-3">
                                        <Form.Check
                                          inline
                                          label="Yes"
                                          name="discount"
                                          type="radio"
                                          value={1}
                                         
                                        />
                                        <Form.Check
                                          inline
                                          label="No"
                                          name="discount"
                                          type="radio"
                                          value={0}
                                         
                                        />
                                      </div>
                                    </Form.Group>
                                  </>
                                )}

                              </Form>

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                              <h5 className='aff-title'><span className='orange-border'>Recieve</span> place visit</h5>
                              <div className='row my-3'>

                                {/* <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Add Place</Form.Label>
                                    <Form.Control type="text" placeholder="Add place" />
                                </Form.Group>
                            </div> */}

                                <div className='col-md-3 mb-2'>
                                  {/* <Form className='mt-5'>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-1`}


                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-2`}
                                            />

                                        </div>
                                    ))}
                                </Form> */}
                                  <Form.Group className="mb-3" controlId="discount">
                                    {/* <Form.Label>Discount</Form.Label> */}
                                    <div key="inline-radio" className="mb-3">

                                      <Controller
                                        name="recieve_status"
                                        {...register("recieve_status", {
                                          // required: "Available for review is required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            styles={{
                                              control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: errors.recieve_status
                                                  ? "red"
                                                  : baseStyles,
                                              }),
                                            }}
                                            {...field}
                                            options={options}
                                            placeholder="--- Please select ---"
                                          />
                                        )}
                                      />


                                      {/* <Form.Check
                                            inline
                                            label="Yes"
                                            name="recive"
                                            type="radio"
                                            value={1}
                                            defaultChecked={recieve == 1}
                                            onChange={(e) => handleRecieveChange(e.target.value, getValues('visit_cost'), getValues('visit_currency_id'))}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="recive"
                                            type="radio"
                                            value={0}
                                            defaultChecked={recieve == 0}
                                            onChange={(e) => handleRecieveChange(e.target.value, getValues('visit_cost'), getValues('visit_currency_id'))}
                                        /> */}
                                    </div>
                                  </Form.Group>
                                </div>

                                {/* <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Visit Cost</Form.Label>
                                    <Form.Control type="text" placeholder="Add place" />
                                </Form.Group>
                            </div> */}

                                {recieveStatus && (
                                  <>
                                    <Form >
                                      <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="">
                                          <Form.Label>Currency</Form.Label>
                                          <Controller
                                            name="visit_currency_id" // name of the field
                                            {...register("visit_currency_id", {
                                              // required: "Select Category",
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                              <Select
                                                styles={{
                                                  control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors.visit_currency_id
                                                      ? "red"
                                                      : baseStyles,
                                                  }),
                                                }}
                                                {...field}
                                                options={currencies}
                                              />
                                            )}
                                          />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="visitCost">
                                          <Form.Label>Visit Cost</Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="visit_cost"
                                            placeholder="Visit Cost"
                                            className={classNames("", { "is-invalid": errors?.visit_cost })}
                                            {...register("visit_cost", { required: "Visit cost is required" })}
                                          />
                                          {errors.visit_cost && (
                                            <span className="text-danger">
                                              {errors.visit_cost.message}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </div>
                                      {/* <div >
                                            <Button className="btn submit-btn me-3" type="submit">
                                                Submit
                                            </Button>
                                        </div> */}
                                    </Form>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <h5 className='aff-title'><span className='orange-border'>Tax</span> & Payment Account</h5>
                              <div className='row my-3'>
                                <div className='col-md-3 mb-2'>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label>GST No</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="gst_no"
                                      placeholder="Enter your GST number"
                                      {...register("gst_no", {
                                        required: "GST Number is required",
                                        pattern: {
                                          value:
                                            /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
                                          message: "Invalid GST Number",
                                        },
                                      })}
                                    // className={classNames("", {
                                    //     "is-invalid": errors?.gst_no,
                                    //     "is-valid": verifyStatus,
                                    // })}
                                    // onChange={() => {
                                    //     setError("gst_no", "");
                                    //     setVerifyStatus(false);
                                    // }}
                                    />
                                  </Form.Group>
                                </div>
                                <div className='col-md-3 mb-2'>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label>PAN No</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="pan_no"
                                      placeholder="Enter your PAN number"
                                      {...register("pan_no", {
                                        required: "PAN Number is required",
                                        pattern: {
                                          value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                          message: "Invalid PAN Number",
                                        },
                                      })}
                                    // className={classNames("", {
                                    //     "is-invalid": errors?.pan_number,
                                    //     "is-valid": verifyPanStatus,
                                    // })}
                                    // onChange={() => {
                                    //     setError("pan_no", "");
                                    //     setVerifyPanStatus(false);
                                    // }}
                                    />
                                  </Form.Group>
                                </div>
                                {/* <div className='col-md-3 mb-2'>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>International No</Form.Label>
                                    <Form.Control type="number" placeholder="International No" />
                                </Form.Group>
                            </div> */}
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <h5 className='aff-title'><span className='orange-border'>Bank</span> Account</h5>
                              <div className='row my-3'>
                                <div className='col-md-3 mb-2'>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control
                                      className={classNames("", {
                                        "is-invalid": errors?.bank_name,
                                      })}
                                      disabled
                                      type="text"
                                      name="bank_name"
                                      placeholder="Enter Bank Name"
                                      {...register("bank_name", {
                                        required: "Field is required",
                                      })}
                                    />
                                  </Form.Group>
                                </div>
                                <div className='col-md-3 mb-2'>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Ifsc Code</Form.Label>
                                    <Form.Control
                                      // className={classNames("", {
                                      //   "is-invalid": errors?.ifsc_code,
                                      //   "is-valid": verifyStatus,
                                      // })}
                                      type="text"
                                      name="ifsc_code"
                                      placeholder="Enter IFSC Code."
                                      {...register("ifsc_code", {
                                        required: "Field is required",
                                        //   pattern: {
                                        //     value: RegxExpression.ifsc,
                                        //     message: "Invalid IFSC code",
                                        //   },
                                      })}
                                    // onChange={() => {
                                    //   setError("ifsc_code", "");
                                    //   setVerifyStatus(false);
                                    // }}
                                    // onKeyDown={(event) => {
                                    //   if (!RegxExpression.ifsc_code.test(event.key) && event.key !== "Backspace") {
                                    //     event.preventDefault();
                                    //   }
                                    // }}
                                    />
                                  </Form.Group>
                                </div>
                                <div className='col-md-3 mb-2'>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Bank Account No</Form.Label>
                                    <Form.Control
                                      className={classNames("", {
                                        "is-invalid": errors?.bank_account_no,
                                      })}
                                      disabled
                                      type="text"
                                      name="bank_account_no"
                                      placeholder="Enter bank_account_no"
                                      {...register("bank_account_no", {
                                        required: "Field is required",
                                      })}
                                    />
                                  </Form.Group>
                                </div>
                                <div className='col-md-3 mb-2'>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label>branch name</Form.Label>
                                    <Form.Control
                                      className={classNames("", {
                                        "is-invalid": errors?.branch_name,
                                      })}
                                      disabled
                                      type="text"
                                      name="branch_name"
                                      placeholder="Enter Bank Name"
                                      {...register("branch_name", {
                                        required: "Field is required",
                                      })}
                                    />
                                  </Form.Group>
                                </div>
                                <div className='col-md-3 mb-2'>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label>micr_code</Form.Label>
                                    <Form.Control
                                      className={classNames("", {
                                        "is-invalid": errors?.micr_code,
                                      })}
                                      disabled
                                      type="text"
                                      name="micr_code"
                                      placeholder="Enter Bank Name"
                                      {...register("micr_code", {
                                        required: "Field is required",
                                      })}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="4">
                      <div>
                        <div>
                          <h3 className="titleName">Firm Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>GST Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_firm_detail?.gst_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>PAN Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_firm_detail?.pan_no}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className=""></Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Uploaded GST Document</Form.Label>
                                  {/* <Form.Group>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    value={data?.start_date}
                                    disabled
                                  />
                                </InputGroup>
                              </Form.Group> */}
                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL + data?.s_firm_detail?.gst_image
                                      }
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4} className="">
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Uploaded PAN Document</Form.Label>
                                  {/* <Form.Group>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    value={data?.start_date}
                                    disabled
                                  />
                                </InputGroup>
                              </Form.Group> */}
                                  <div className="image-boxes-main">
                                    <img
                                      className="image-boxes"
                                      src={
                                        IMG_URL + data?.s_firm_detail?.pan_image
                                      }
                                    ></img>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="titleName">
                          <h3>Firm Address Details</h3>
                        </div>

                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_firm_detail?.name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Firm type</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.s_firm_detail?.s_firm_type?.name
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="titleName">
                          <h3>Firm Address Details</h3>
                        </div>

                        <div>
                          {data?.s_firm_address_details?.map((val, index) => (
                            <Row key={index}>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Type</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.s_firm_address_type?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 1</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_one}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 2</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_two}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City/District</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>PinCode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div>
                          <h3 className="titleName">Drop Address Details</h3>
                        </div>

                        <div>
                          {data?.s_firm_drop_addresses?.map((val, index) => (
                            <Row key={index}>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 1</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_one}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 2</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_two}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City/District</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>PinCode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div>
                          <h3>Pickup Address Details</h3>
                        </div>

                        <div>
                          {data?.s_firm_pickup_addresses?.map((val, index) => (
                            <Row key={index}>
                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 1</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_one}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Address Line 2</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.add_line_two}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.country?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>State</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.state?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>City/District</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.city?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={4}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label>PinCode</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          value={val?.pincode?.name}
                                          disabled
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="5">
                      <div>
                        <div>
                          <h3>Bank Details</h3>
                        </div>
                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Bank Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.bank_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Bank Account Number</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={
                                          data?.s_bank_detail?.bank_account_no
                                        }
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>IFSC Code</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.ifsc_code}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>Branch Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.branch_name}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="main-form-section mt-4">
                                <Row className="justify-content-center">
                                  <Form.Label>MICR Code</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        value={data?.s_bank_detail?.micr_code}
                                        disabled
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </>
  );
};

export default ViewOffCanvance;
