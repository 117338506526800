import React, { useState } from 'react'
import Header from '../../Header/Header'
import DashboardInHouse from './DashboardInHouse/DashboardInHouse'
import MainDashoboardTab from './MainDashoboardTab/MainDashoboardTab'

const InhouseAds = () => {

    const [activeCount , setActiveCount] = useState(0);
    return (
        <>
            <Header title={"Inhouse Ads"} />
            <MainDashoboardTab activeCount={activeCount}/>
            <DashboardInHouse setActiveCount={setActiveCount}/>
        </>
    )
}

export default InhouseAds