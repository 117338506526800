import React, { useContext, useEffect, useState } from 'react'
import { Col, Nav, Row, Tab, Form, Pagination, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';
import { blockApi, getAffiliateActive, getAffiliateNew, getAffiliateTerminate, getAffiliateUsers, removeTerminationApi, terminationApi } from '../../../../utils/apis/affiliate';



import { Context } from "../../../../utils/context";
import ViewOffCanvance from "./ViewOffCanvance";
import PromptModalDynamic from "../../../common/PromptModalDynamic/PromptModalDynamic";
import SuccessModalDynamic from "../../../common/SuccessModalDynamic/SuccessModalDynamic";
import ReasonModalDynamic from "../../../common/ReasonModalDynamic/PromptModalDynamic";


const UserManagement = () => {



    const [promptModal, setPromptModal] = useState(false);
    const [promptUnBlockModal, setPromptUnBlockModal] = useState(false);
    const [promptTerminateModal, setPromptTerminateModal] = useState(false);
    const [promptBlockModal, setPromptBlockModal] = useState(false);
    const [successTerminateModal, setSuccessTerminateModal] = useState(false);
    const { formatDate } = useContext(Context);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];


    const [selectedOption, setSelectedOption] = useState(null);

    // Modal
    const [lgShow, setLgShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    // star Mark

    const [rating, setRating] = useState(0);

    const handleRating = () => {
        setRating(rating === 1 ? 0 : 1);
    };

    const [successModal, setSuccessModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [affiliateId, setAffiliateId] = useState("");

    const [activeUser, setActiveUser] = useState([]);
    const [newUser, setNewUser] = useState([]);
    const [terminateUser, setTerminateUser] = useState([]);
    const getNewUser = async (type = 'active') => {


        if (type === 'active') {
            const res = await getAffiliateActive();

            if (res?.success) {
                setActiveUser(res?.data?.data)
            }
        }
        if (type === 'new') {
            const res = await getAffiliateNew();
            if (res?.success) {
                setNewUser(res?.data?.data)
            }
        }
        if (type === 'terminate') {
            const res = await getAffiliateTerminate();
            if (res?.success) {
                setTerminateUser(res?.data?.data)
            }
        }
    }

    const blockUser = async (reason) => {
        setPromptUnBlockModal(false);
        setPromptBlockModal(false);
        const res = await blockApi(affiliateId,reason);
        if(res?.success){
           
            getNewUser('terminate');
            getNewUser('new');
            getNewUser();
           
        }
    }

    const terminationUser = async (reason) => {
        setPromptTerminateModal(false);
        const res = await terminationApi(affiliateId,reason);
        if(res?.success){
            setSuccessTerminateModal(true);
            getNewUser('terminate');
            getNewUser('new');
            getNewUser();
            setTimeout(() => {
                    setSuccessTerminateModal(false);
            }, 3000);
        }
    }

    const removeTermination = async (id) => {
         setPromptModal(false);
        const res = await removeTerminationApi(id);
        if(res?.success){
            setSuccessModal(true);
            getNewUser('terminate');
            setTimeout(() => {
                    setSuccessModal(false);
            }, 3000);
        }
    }
    useEffect(() => {
        getNewUser();
    }, []);


    return (
        <section className='dashboard-in'>
            <div className='heading-holder'>
                {/* <h4>Complaint List</h4> */}
            </div>

            <div className='main-class-report-table'>
                <Row>
                    <Col xl={3} lg={3} md={5} sm={12} >
                        <Form.Label>Search</Form.Label>
                        <Form.Control
                            required
                            type="text"
                        />
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >
                        <Form.Label>From</Form.Label>
                        <Form.Control
                            required
                            type="Date"
                        />
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >
                        <Form.Label>To</Form.Label>
                        <Form.Control
                            required
                            type="Date"
                        />
                    </Col>
                    <Col xl={3} lg={5} md={5} sm={12}>
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} >
                                <Form.Label>Sort By</Form.Label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                />
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} >
                                <Form.Label>Download data in Excel</Form.Label><br />
                                <FontAwesomeIcon icon="fa-solid fa-file-lines" className='pdf-icon' />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >

                    </Col>
                </Row>

                <div className='brownborder-report mt-3'></div>
                <Row>
                    <div className='col-md-12'>
                        <div className='my-3'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" onClick={() => getNewUser()}>Active User</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second" onClick={() => getNewUser('new')}>New User</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third" onClick={() => getNewUser('terminate')}>Terminate User</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="table-sec-main mt-5">
                                            <Table responsive bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Affilate Id</th>
                                                        <th>Registered Date</th>
                                                        <th>Earning Status</th>
                                                        <th>Panelty</th>
                                                        <th>View</th>
                                                        <th>Ingagement Total</th>
                                                        <th>Score Card</th>
                                                        <th>Show User</th>
                                                        <th>Reason</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {activeUser?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>#{item?.af_id}</td>
                                                            <td>{formatDate(item?.createdAt)}</td>
                                                            <td>{item.eariningstatus}</td>
                                                            <td>{item.panelty}</td>
                                                            <td>{item.view}</td>
                                                            <td>{item?.affiliate_tasks.length}</td>
                                                            <td>{item.score}</td>
                                                            <td><button className='btn btn-info' onClick={() => { setAffiliateId(item?.id); setUserModal(true); }}>Show Details</button></td>
                                                            
                                                            <td>{item.remark}</td>
                                                            <td>


                                                            <button className='btn btn-danger ' onClick={() => { setAffiliateId(item?.id); setPromptTerminateModal(true);}}><FontAwesomeIcon icon="fa-solid fa-ban" className='action-icon me-2' size={"xl"} title='Terminate User'/>Terminate User</button>
                                                            {item.block_status ? (
                                                            <button className='btn btn-success ms-2'  onClick={async () => {  setAffiliateId(item?.id); setPromptUnBlockModal(true);}}>Unblock User</button>

                                                            ):(
                                                                <button className='btn btn-danger ms-2'  onClick={() => { setAffiliateId(item?.id); setPromptBlockModal(true);}}><FontAwesomeIcon icon="fa-solid fa-circle-stop" className='action-icon me-2'  size={"xl"} title='Block User'/>Block User</button>

                                                            )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="paginationss mt-4">
                                            <Pagination>
                                                <Pagination.Prev />
                                                <Pagination.Item>{1}</Pagination.Item>
                                                <Pagination.Item>{2}</Pagination.Item>
                                                <Pagination.Ellipsis />
                                                <Pagination.Item>{10}</Pagination.Item>
                                                <Pagination.Next />
                                            </Pagination>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="table-sec-main mt-5">
                                            <Table responsive bordered>
                                                <thead>
                                                    <tr className="heads-main">
                                                        <th>
                                                            {/* <Form.Check type="checkbox" label="Select" /> */}
                                                        </th>
                                                        <th>Apply Id</th>
                                                        <th>Show User</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newUser?.map((item, index) => (
                                                        <tr key={index} className="heads-main">
                                                            <td>{index + 1}</td>
                                                            <td>#{item?.af_id}</td>
                                                            <td><button className='btn btn-info' onClick={() => { setAffiliateId(item?.id); setUserModal(true); }}>Show Details</button></td>
                                                            <td>{item.remark}</td>
                                                            <td>


                                                            <button className='btn btn-danger ' onClick={() => { setAffiliateId(item?.id); setPromptTerminateModal(true);}}><FontAwesomeIcon icon="fa-solid fa-ban" className='action-icon me-2' size={"xl"} title='Terminate User'/>Terminate User</button>
                                                            {item.block_status ? (
                                                            <button className='btn btn-success ms-2'  onClick={async () => {  setAffiliateId(item?.id); setPromptUnBlockModal(true);}}>Unblock User</button>

                                                            ):(
                                                                <button className='btn btn-danger ms-2'  onClick={() => { setAffiliateId(item?.id); setPromptBlockModal(true);}}><FontAwesomeIcon icon="fa-solid fa-circle-stop" className='action-icon me-2'  size={"xl"} title='Block User'/>Block User</button>

                                                            )}     
                                                             </td>                                                      
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="paginationss mt-4">
                                            <Pagination>
                                                <Pagination.Prev />
                                                <Pagination.Item>{1}</Pagination.Item>
                                                <Pagination.Item>{2}</Pagination.Item>
                                                <Pagination.Ellipsis />
                                                <Pagination.Item>{10}</Pagination.Item>
                                                <Pagination.Next />
                                            </Pagination>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="table-sec-main mt-5">
                                            <Table responsive bordered>
                                                <thead>
                                                    <tr className="heads-main">
                                                        
                                                        <th>Affiliate Id</th>
                                                        <th>Total Stricks</th>
                                                        <th>Registered Date</th>
                                                        <th>Total Affilate Activity</th>
                                                        <th>Show User</th>
                                                        <th>Reason</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {terminateUser?.map((item, index) => (
                                                        <tr key={index} className="heads-main">
                                                            <td>#{item?.af_id}</td>
                                                            <td>{item?.strick_statuses.length}</td>
                                                            <td>{formatDate(item?.createdAt)}</td>
                                                            <td>{item?.affiliate_tasks.length}</td>
                                                            {/* <td>
                                                                <FontAwesomeIcon icon="fa-solid fa-eye" className='action-icon me-2' />
                                                                <FontAwesomeIcon icon="fa-solid fa-pen" className='action-icon me-2' />
                                                                <FontAwesomeIcon icon="fa-solid fa-trash-can" className='action-icon me-2' />
                                                                <FontAwesomeIcon icon="fa-solid fa-clock-rotate-left" onClick={() => setLgShow(true)} className='action-icon' />
                                                            </td> */}
                                                            <td><button className='btn btn-info' onClick={() => { setAffiliateId(item?.id); setUserModal(true); }}>Show Details</button></td>
                                                            <td>{item?.remark}</td>
                                                            <td>
                                                                <div className='d-flex'>
                                                                    <button className='btn btn-success' onClick={() => {setAffiliateId(item?.id); setPromptModal(true);}}>Remove Termination</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="paginationss mt-4">
                                            <Pagination>
                                                <Pagination.Prev />
                                                <Pagination.Item>{1}</Pagination.Item>
                                                <Pagination.Item>{2}</Pagination.Item>
                                                <Pagination.Ellipsis />
                                                <Pagination.Item>{10}</Pagination.Item>
                                                <Pagination.Next />
                                            </Pagination>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </Row>
            </div>
            {userModal ? (
                <ViewOffCanvance
                    handleClose={() => setUserModal(false)}
                    setShow={setUserModal}
                    show={userModal}
                    id={affiliateId}
                />
            ) : (
                ""
            )}
            <ReasonModalDynamic show={promptTerminateModal} onHide={() => setPromptTerminateModal(false)} 
             button={"Terminate"} 
             text={"Mention Reason to terminate user"} 
             placeholder={"Reason for termination"}
             handleReasonFunction={terminationUser}
             />
             <ReasonModalDynamic show={promptBlockModal} onHide={() => setPromptBlockModal(false)} 
             button={"Block"} 
             text={"Mention Reason to block user"} 
             placeholder={"Reason for Blocking User"}
             handleReasonFunction={blockUser}
             />

            <PromptModalDynamic show={promptUnBlockModal} onHide={() => setPromptUnBlockModal(false)} handlePromptFunction={() => blockUser("")} button={"Unblock"} text={"Sure you want to Unblock User ?"}/>

            <PromptModalDynamic show={promptModal} onHide={() => setPromptModal(false)} handlePromptFunction={() => removeTermination(affiliateId)} button={"Remove"} text={"Sure you want to remove termination ?"}/>
            <SuccessModalDynamic show={successModal} onHide={() => setSuccessModal(false)} text={"Termination Removed"}/>
            <SuccessModalDynamic show={successTerminateModal} onHide={() => setSuccessTerminateModal(false)} text={"User Terminated"}/>
        </section>

    )
}

export default UserManagement