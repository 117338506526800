import { React, useState, useEffect, useContext } from "react";
import "./Affi_setii_page.css";
import axios from "axios";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { Row, Col, InputGroup, Modal } from "react-bootstrap";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import JoditEditor from "jodit-react";
import Button from "react-bootstrap/Button";

import { Context } from "../../../utils/context";
import ModalSave from "../../common/ModelSave";
const Setting_page = (props) => {
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [showError, setShowError] = useState("");

  const {
    control,
    watch,
    trigger,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();

  
  const description = watch("description");
  const { getData, postData, IMG_URL, Select2Data, Id, Select2CurrencyData } =
    useContext(Context);

  useEffect(() => { }, []);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const todayDate = getTodayDate();

  const onSubmit = async (data) => {
    try {


      console.log(data);
      const finaldata = new FormData();

      if(data?.video){
        finaldata.append('video',data?.video);
      }
      
      finaldata.append('description',data?.description);
      
        const response = await postData(
          `/my-website/pricing/domain-information`,
          finaldata
        );

        if (response?.success) {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
          getSetting();
        } else {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
        }
        setTimeout(() => {
          setShowModal(0);
          reset();
          setShowError("");
        }, 2000);
      
    } catch (error) {
      console.log(error);
    }
  };


  const getSetting = async () => {
    const res = await getData(`/my-website/pricing/domain-information`);
    if (res?.success) {
      setValue('description',res?.data?.description);
      setVideoPreviewUrl(IMG_URL + res?.data?.video);
    }
  }

  const [videoFile, setVideoFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);

  const handleVideoChange = (e) => {
    setValue('video',e.target.files[0]);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setVideoFile(selectedFile);

      // Preview video
      const reader = new FileReader();
      reader.onloadend = () => {
        setVideoPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);
  return (
    <>
      <Form>
        <section className="affi-setting-seccc">
          <h5 className="aff-title">
            <span className="orange-border">Domain </span> Information
          </h5>

          <div className="row">
            <div className="col-md-12">
              <h5 className="aff-title">
                <span className="orange-border">Video</span>{" "}
                Tutotrial
              </h5>
              <div className="row my-3">
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Video</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="file"
                        name="video"
                        accept="video/*"
                        onChange={handleVideoChange}
                        className={classNames("", {
                          "is-invalid": errors?.video,
                        })}
                      
                      />
                    </InputGroup>
                    {errors.video && (
                      <span className="text-danger">
                        {errors.video.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Video Preview</Form.Label>
                    {videoPreviewUrl ? (
                      <video
                        src={videoPreviewUrl}
                        controls
                        className="d-block"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    ) : (
                      <p>No video selected</p>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5 className="aff-title">
                <span className="orange-border">Description </span>{" "}

              </h5>
              <div className="row my-3">
                <div className="col-md-8 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    {/* <Form.Label>Days</Form.Label> */}
                    <InputGroup>
                    <Controller
                              name="description"
                              control={control}
                              render={({ field }) => (
                                <JoditEditor
                                  value={description || ""}
                                  onChange={(newContent) => {
                                    setValue(`description`, newContent);
                                    trigger("description");
                                  }}
                                />
                              )}
                            />
                    </InputGroup>
                    {errors.description && (
                      <span className="text-danger">{errors.description.message}</span>
                    )}
                  </Form.Group>
                </div>

              </div>
            </div>
          </div>


          <Button
            className="btn submittttbtnnn"
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </section>
      </Form>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default Setting_page;
