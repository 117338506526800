import React, { useContext } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const response = await postData(`/seller/masters/margin-settings`, data);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Margin Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Category Margin</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="category_margin"
                          placeholder="Category Margin"
                          className={classNames("", {
                            "is-invalid": errors?.category_margin,
                          })}
                          {...register("category_margin", {
                            required: "Category Margin is required",
                          })}
                        />
                      </InputGroup>
                      {errors.category_margin && (
                        <span className="text-danger">
                          {errors.category_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Sub Category Margin</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="sub_category_margin"
                          placeholder="Sub Category Margin"
                          className={classNames("", {
                            "is-invalid": errors?.sub_category_margin,
                          })}
                          {...register("sub_category_margin", {
                            required: "Sub Category Margin is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sub_category_margin && (
                        <span className="text-danger">
                          {errors.sub_category_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Child Category Margin</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="child_category_margin"
                          placeholder="Child Category Margin"
                          className={classNames("", {
                            "is-invalid": errors?.child_category_margin,
                          })}
                          {...register("child_category_margin", {
                            required: "Child Category Margin is required",
                          })}
                        />
                      </InputGroup>
                      {errors.child_category_margin && (
                        <span className="text-danger">
                          {errors.child_category_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Child Sub Category Margin</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="child_sub_category_margin"
                          placeholder="Child Sub Category Margin"
                          className={classNames("", {
                            "is-invalid": errors?.child_sub_category_margin,
                          })}
                          {...register("child_sub_category_margin", {
                            required: "Child Sub Category Margin is required",
                          })}
                        />
                      </InputGroup>
                      {errors.child_sub_category_margin && (
                        <span className="text-danger">
                          {errors.child_sub_category_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Product Margin</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="product_margin"
                          placeholder="Product Margin"
                          className={classNames("", {
                            "is-invalid": errors?.product_margin,
                          })}
                          {...register("product_margin", {
                            required: "Product Margin is required",
                          })}
                        />
                      </InputGroup>
                      {errors.product_margin && (
                        <span className="text-danger">
                          {errors.product_margin.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
