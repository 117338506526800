import React from "react";
import { CardBody, CardFooter, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";

const DashboardDashboardContent = () => {
  const cardsData = [
    {
      number: "1492",
      types: "Active Task",
      icon: "fa-cart-shopping",
      className: "#63bff5",
    },
    {
      number: "116",
      types: "Active User",
      icon: "fa-solid fa-star",
      className: "#63bff5",
    },
    {
      number: "1094",
      types: "Total Click",
      icon: "fa-solid fa-user",
      className: "#63bff5",
    },
    {
      number: "6",
      types: "Total Onboarding Month Wise",
      icon: "fa-solid fa-users",
      className: "#63bff5",
    },
    {
      number: "1492",
      types: "Next Month Payout generate",
      icon: "fa-cart-shopping",
      className: "#63bff5",
    },
    {
      number: "116",
      types: "Current month payout",
      icon: "fa-solid fa-star",
      className: "#63bff5",
    },
    {
      number: "1094",
      types: "Total link generated",
      icon: "fa-solid fa-user",
      className: "#63bff5",
    },
    {
      number: "6",
      types: "Afillate to onboarding ratio",
      icon: "fa-solid fa-users",
      className: "#63bff5",
    },
    {
      number: "1492",
      types: "Terminate Afillate",
      icon: "fa-cart-shopping",
      className: "#63bff5",
    },
    {
      number: "116",
      types: "New Customer",
      icon: "fa-solid fa-star",
      className: "#63bff5",
    },
    {
      number: "1094",
      types: "Monthly Active User",
      icon: "fa-solid fa-user",
      className: "#63bff5",
    },
    {
      number: "6",
      types: "New Afillate",
      icon: "fa-solid fa-users",
      className: "#63bff5",
    },
  ];
  const colors = ["#63bff5", "#f39c12", "#dd4b39", "#00a65a"];
  return (
    <section className="affiliate-desh-board">
      <Row className="me-0 ms-0">
        {cardsData.map((item, index) => (
          <Col xxl={3} xl={3} lg={6} md={6}>
            <Card
              className="small-box mb-3"
              style={{ backgroundColor: colors[index % colors.length] }}
            >
              <CardBody className=" ps-0 pe-1">
                <div className={item.className}>
                  <div className="content">
                    <div class="inner">
                      <h3>{item.number}</h3>
                    </div>
                    <div class="icon">
                      <FontAwesomeIcon icon={item.icon} />
                    </div>
                  </div>
                  <div class="inner">
                    <p>{item.types}</p>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <p className="mb-0 small-box-footer">
                  More info{" "}
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </p>
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default DashboardDashboardContent;
