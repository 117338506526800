import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, Select2Data } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const ExportableOptions = [
    { value: "YES", label: "YES" },
    { value: "No", label: "NO" },
  ];

  const GetEditData = async () => {
    const response = await getData(`/seller/masters/hsn-code/${id}`);
    reset(response?.data);
    if(response?.data?.allow_rate_fluctuation === 'YES'){
      setValue('allow_rate_fluctuation',{ value: "YES", label: "YES" });
    }else{
      setValue('allow_rate_fluctuation',{ value: "No", label: "No" });
    }
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // const DataToSend = new FormData();
      // DataToSend.append("hsn_type_one_id", data?.hsn_type_one_id?.value);
      // DataToSend.append("hsn_type_two_id", data?.hsn_type_two_id?.value);
      // DataToSend.append("name", data?.name);
      // DataToSend.append("exportable", data?.exportable?.value);
      // DataToSend.append("below", data?.below);
      // DataToSend.append("allow_rate_fluctuation", data?.allow_rate_fluctuation);
      // DataToSend.append("product_narration", data?.product_narration);
      // DataToSend.append("gst_narration", data?.gst_narration);
      console.log(data);
      data.allow_rate_fluctuation = data.allow_rate_fluctuation.value;
      const response = await postData(`/seller/masters/hsn-code/${id}`, data);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [hsnTypeOne, setHsnTypeOne] = useState([]);
  const [hsnTypeTwo, setHsnTypeTwo] = useState([]);

  const GetAllHSNTypeOne = async () => {
    const response = await getData("/common/seller/masters/all-hsn-type-one");

    if (response?.success) {
      setHsnTypeOne(await Select2Data(response?.data, "hsn_type_one_id"));
    }
  };

  const GetAllHSNTypeTwo = async () => {
    const response = await getData("/common/seller/masters/all-hsn-type-two");

    if (response?.success) {
      setHsnTypeTwo(await Select2Data(response?.data, "hsn_type_two_id"));
    }
  };
  const [productNarration, setProductNarration] = useState('');

  const handleProductNarrationChange = (e) => {
    const { value } = e.target;
    const capitalizedValue = value
      .split(',')
      .map(word => word.trim())
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(',');
    setProductNarration(capitalizedValue);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit HSN Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>HSN Type One</Form.Label>

                    <Controller
                      name="hsn_type_one_id"
                      {...register("hsn_type_one_id", {
                        required: "HSN Type One",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.hsn_type_one_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={hsnTypeOne}
                        />
                      )}
                    />

                    {errors.hsn_type_one_id && (
                      <span className="text-danger">
                        {errors.hsn_type_one_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>HSN Type Two</Form.Label>

                    <Controller
                      name="hsn_type_two_id"
                      {...register("hsn_type_two_id", {
                        required: "HSN Type Two",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.hsn_type_two_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={hsnTypeTwo}
                        />
                      )}
                    />

                    {errors.hsn_type_two_id && (
                      <span className="text-danger">
                        {errors.hsn_type_two_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>GST</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="gst"
                          placeholder="GST"
                          className={classNames("", {
                            "is-invalid": errors?.gst,
                          })}
                          {...register("gst", {
                            required: "GST is required",
                          })}
                        />
                      </InputGroup>
                      {errors.gst && (
                        <span className="text-danger">
                          {errors.gst.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>HSN Primary</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="name"
                          placeholder="HSN Primary"
                          pattern="^\d{2}|\d{4}|\d{8}|\d{12}$"
                          title="Please enter a number with 2, 4, 8, or 12 digits"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "HSN Primary is required",
                            validate: (value) => {
                              const numericValue = Number(value);
                              if (isNaN(numericValue))
                                return "Please enter a valid number";
                              return true;
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Exportable</Form.Label>
                      </div>
                      <Controller
                        name="exportable"
                        {...register("exportable", {
                          required: "Exportable",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.exportable
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={ExportableOptions}
                          />
                        )}
                      />
                      {errors.exportable && (
                        <span className="text-danger">
                          {errors.exportable.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Below</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="below"
                          placeholder="Below"
                          className={classNames("", {
                            "is-invalid": errors?.below,
                          })}
                          {...register("below")}
                        />
                      </InputGroup>
                      
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>
                          Allow Rate Fluctuation And Discount
                        </Form.Label>
                      </div>
                      <InputGroup>
                        <Controller
                          name="allow_rate_fluctuation"
                          {...register("allow_rate_fluctuation", {
                            required: "Allow Rate Fluctuation And Discount is required",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.allow_rate_fluctuation
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={ExportableOptions}
                            />
                          )}
                        />
                      </InputGroup>
                      {errors.allow_rate_fluctuation && (
                        <span className="text-danger">
                          {errors.allow_rate_fluctuation.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Product Narration <sub className="text-info">(Product 1, Product 2 , Product 3)</sub></Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="product_narration"
                          placeholder=" Product Narration"
                          className={classNames("", {
                            "is-invalid": errors?.product_narration,
                          })}
                          {...register("product_narration")}
                          value={productNarration}
                          onChange={handleProductNarrationChange}
                        />
                      </InputGroup>
                     
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>GST Narration</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="gst_narration"
                          placeholder=" GST Narration"
                          className={classNames("", {
                            "is-invalid": errors?.gst_narration,
                          })}
                          {...register("gst_narration")}
                        />
                      </InputGroup>
                      
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
