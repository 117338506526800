import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension, Select2Data } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });


  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  const responseTableValue = watch("response_table");
  const resultExampleValue = watch("request_example");
  const requestStructureValue = watch("request_structure");
  const otherFormateValue = watch("other_formate");
  const imageFile = watch("image");

  const [websiteTypes, setWebsiteTypes] = useState([]);
  const [websitePages, setWebsitePages] = useState([]);

  const GetAllWebsiteTypes = async () => {
    const response = await getData("/common/api-documentation/getAllWebsiteTypes");

    if (response?.success) {
      setWebsiteTypes(await Select2Data(response?.data, "website_type_id"));
    }
  };

  const GetAllWebsitePages = async (id) => {
    const response = await getData(`/common/api-documentation/getAllWebsitePages/${id}`);

    if (response?.success) {
      setWebsitePages(await Select2Data(response?.data, "website_page_id"));
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/api-documentation/website-api/${id}`);
    reset(response?.data);
  };

  useEffect(() => {
    GetAllWebsiteTypes();
    // GetAllWebsitePages();
    GetEditData();
  }, []);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("website_type_id", data?.website_type_id?.value);
      finalData.append("website_page_id", data?.website_page_id?.value);
      finalData.append("name", data?.name);
      finalData.append("response_table", data?.response_table);
      finalData.append("request_example", data?.request_example);
      finalData.append("request_structure", data?.request_structure);
      finalData.append("other_formate", data?.other_formate);

      const response = await postData(`/api-documentation/website-api/${id}`, finalData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Website Page
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>

              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Website Type</Form.Label>
                    <Controller
                      name="website_type_id" // name of the field
                      {...register("website_type_id", {
                        required: "Select Website Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.website_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={websiteTypes}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value); // Update Controller's value
                            GetAllWebsitePages(selectedOption.value);
                            setValue("website_type_id", selectedOption);
                            setValue("website_page_id", null);
                          }}
                        />
                      )}
                    />
                    {errors.website_type_id && (
                      <span className="text-danger">
                        {errors.website_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Website Page</Form.Label>

                    <Controller
                      name="website_page_id" // name of the field
                      {...register("website_page_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.website_page_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={websitePages}
                        />
                      )}
                    />

                    {errors.website_page_id && (
                      <span className="text-danger">
                        {errors.website_page_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={4}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Website Api</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Website Api"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Api name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Response table
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="response_table"
                          control={control}
                          rules={{
                            required: 'response_table is required.',
                            // maxLength: { value: 200, message: 'response_table must be at most 200 characters long.' }
                          }} // Correct the maxLength rule
                          render={({ field: { onChange, onBlur, value } }) => (
                            <JoditEditor
                              value={value}
                              onBlur={() => {
                                onBlur();
                                trigger('response_table');
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.response_table && (
                        <span className="text-danger">
                          {errors.response_table.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Result Example
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="request_example"
                          control={control}
                          rules={{
                            required: 'request_example is required.',
                            // maxLength: { value: 200, message: 'request_example must be at most 200 characters long.' }
                          }} // Correct the maxLength rule
                          render={({ field: { onChange, onBlur, value } }) => (
                            <JoditEditor
                              value={value}
                              onBlur={() => {
                                onBlur();
                                trigger('request_example');
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.request_example && (
                        <span className="text-danger">
                          {errors.request_example.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Request Structure
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="request_structure"
                          control={control}
                          rules={{
                            required: 'request_structure is required.',
                            // maxLength: { value: 200, message: 'request_structure must be at most 200 characters long.' }
                          }} // Correct the maxLength rule
                          render={({ field: { onChange, onBlur, value } }) => (
                            <JoditEditor
                              value={value}
                              onBlur={() => {
                                onBlur();
                                trigger('request_structure');
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.request_structure && (
                        <span className="text-danger">
                          {errors.request_structure.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start"> {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Other Formate
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="other_formate"
                          control={control}
                          rules={{
                            required: 'other_formate is required.',
                            // maxLength: { value: 200, message: 'other_formate must be at most 200 characters long.' }
                          }} // Correct the maxLength rule
                          render={({ field: { onChange, onBlur, value } }) => (
                            <JoditEditor
                              value={value}
                              onBlur={() => {
                                onBlur();
                                trigger('other_formate');
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.other_formate && (
                        <span className="text-danger">
                          {errors.other_formate.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>









              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
