import React from 'react'
import '../DashboardInHouse/DashboardInHouse.css'
import { Col, Row } from 'react-bootstrap'

const MainDashoboardTab = ({activeCount}) => {
    return (
        <section className='dashboard-in'>
            <div className='card-section'>
                <Row>
                    <Col xl={3} lg={6} md={6}>
                        <div className=' d-flex registered-number'>
                            <div className='circle-holder'>
                                <img className='ticket-img' src={process.env.PUBLIC_URL + '/assets/image/tickets.png'} />
                            </div>
                            <div className='text-holder'>
                                <p>My active task</p>
                                <h5>{activeCount}</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} >
                        <div className=' d-flex registered-number'>
                            <div className='circle-holder1'>
                                <img className='ticket-img' src={process.env.PUBLIC_URL + '/assets/image/tickets.png'} />
                            </div>
                            <div className='text-holder'>
                                <p>Total view</p>
                                <h5>50</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} >
                        <div className=' d-flex  mt-xl-0 mt-lg-3 mt-md-3 registered-number'>
                            <div className='circle-holder2'>
                                <img className='ticket-img' src={process.env.PUBLIC_URL + '/assets/image/tickets.png'} />
                            </div>
                            <div className='text-holder'>
                                <p>Spend cost month</p>
                                <h5>340</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} >
                        <div className=' d-flex mt-xl-0 mt-lg-3 mt-md-3'>
                            <div className='circle-holder3'>
                                <img className='ticket-img' src={process.env.PUBLIC_URL + '/assets/image/tickets.png'} />
                            </div>
                            <div className='text-holder'>
                                <p>Active affiliate link</p>
                                <h5>150</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default MainDashoboardTab