import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SettingContent from "../SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
import Select from "react-select";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    formatDateExpire,
    formatDate,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [searchStatus, setSearchStatus] = useState("");
  const getDataAll = async () => {
    let url = `/payment/affiliate-payment/?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    const response = await getData(url);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/seller/masters/hsn-code/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/seller/masters/hsn-code/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
  });



  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });




  return (
    <>
      <div className="main-advancedashboard">
        <section className="AdvanceDashboard">
          <div className="row  me-0 ms-0  MainRowsec">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio ">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex add-btn-holder">
                      <div className="add me-3">
                        <b>Affiliate Payments</b>
                      </div>

                    
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="   col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex row-flex-holder">
                          <div className="show me-2">
                            <p className="show ">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show ">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="   col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end entry-showing">
                          <div className="sowing me-2">
                            <p className="show ">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              placeholder="  Affiliate Id"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setSearchStatus("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {" "}
                            {visible.col0 && (
                              <th className="sr">
                                <input
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                  className="me-1"
                                ></input>
                              </th>
                            )}
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">Affiliate Id</th>
                            )}{" "}
                            {visible.col3 && (
                              <th className="tax-name">Task Id</th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name">Task Deadline</th>
                            )}
                            {visible.col6 && (
                              <th className="tax-name">Incentive</th>
                            )}{" "}
                            {visible.col7 && (
                              <th className="tax-name">Total Buy</th>
                            )}{" "}
                            {visible.col12 && (
                              <th className="tax-name">Total Payment</th>
                            )}
                            {/* {visible.col12 && (
                              <th className="active">Action</th>
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {isAllow.includes(263) ? (
                            data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {" "}
                                  {visible.col0 && (
                                    <td>
                                      {formatDateExpire(
                                        d?.seller_task?.createdAt,
                                        d?.seller_task?.validity
                                      ) <= formatDate(new Date()) && (
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value={d?.id}
                                            name="perselected"
                                            checked={selectAllChecked.includes(
                                              d?.id
                                            )}
                                            onChange={handleChange}
                                            id="flexCheckDefault"
                                          ></input>
                                        )}
                                    </td>
                                  )}
                                  {visible.col1 && <td>{++index}.{d.id}</td>}{" "}
                                  {visible.col2 && (
                                    <td>#{d?.a_personal_detail?.af_id}</td>
                                  )}{" "}
                                  {visible.col3 && (
                                    <td>{d?.seller_task?.task_id}</td>
                                  )}
                                  {visible.col4 && (
                                    <td>
                                      {formatDateExpire(
                                        d?.seller_task?.createdAt,
                                        d?.seller_task?.validity
                                      )}
                                    </td>
                                  )}
                                  {visible.col6 && (
                                    <td>
                                      {d?.seller_task?.product_type === 'Website' ? (
                                        d?.seller_task?.insentive + ' %'
                                      ) : (
                                        '₹ ' + d?.seller_task?.insentive
                                      )}
                                    </td>
                                  )}{" "}

                                  {visible.col7 && (
                                    <td>
                                      {d?.seller_task?.product_type === 'Website' ? (
                                        d?.seller_plans.length
                                      ) : (
                                        d?.seller_plans.length
                                      )}
                                    </td>
                                  )}{" "}
                                  {visible.col8 && (
                                    <td>
                                      {d?.seller_task?.product_type === 'Website' ? (
                                        '₹ ' + d?.seller_plans?.reduce((totalAmount, plan) => Number(totalAmount) + Number(plan?.amount), 0) * (d?.seller_task?.insentive / 100)
                                      ) : (
                                        d?.seller_plans.length
                                      )}
                                    </td>
                                  )}


                                  {/* {visible.col11 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )} */}
                                  {/* {visible.col12 && (
                                    <td>
                                      <div className="d-flex">
                                        {isAllow.includes(265) ? (
                                          <EditButton
                                            handleShow1={handleShow1}
                                            id={d?.id}
                                          />
                                        ) : (
                                          <></>
                                        )}

                                        {isAllow.includes(266) ? (
                                          <DeletButton
                                            showDeleteRecord={showDeleteRecord}
                                            id={d?.id}
                                            name={d?.name}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  )} */}
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
