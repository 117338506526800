import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import './AddTaskModal.css'
import { useContext, useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from '../../utils/context';
import classNames from "classnames";
import JoditEditor from "jodit-react";
import { getAffiliatePayDetails } from '../../utils/apis/affiliatePayment';
import * as XLSX from 'xlsx';

const PayDetailsModal = (props) => {


    const [payDetails, setPayDetail] = useState([]);
    var [totalPrice, setTotalPrice] = useState(0);
    const handlePayDetails = async () => {
        const res = await getAffiliatePayDetails({ ids: props.nppId });
        if (res?.success) {
            console.log(res?.data);
            setPayDetail(res?.data);
        }
    }

    const { dateShow, formatDate } = useContext(Context);

    console.log(props.nppId);

    useEffect(() => {
        handlePayDetails();
    }, [props.show]);


    const makePayment = async () => {

    }


    const handleExcelExport = () => {

        console.log(payDetails);

        // Convert JSON to worksheet

        // Add transaction data
        let totalPrice = 0;

        const transactionData = payDetails?.data?.map(item => ([
            // First Field: Task ID
            item?.seller_task?.task_id,

            // Second Field: Insentive Display
            item?.seller_task?.product_type === 'Website'
                ? `${item?.seller_task?.insentive} %`
                : `₹ ${item?.seller_task?.insentive}`,

            // Third Field: Insentive Amount Display

            item?.affiliate_status === 'cancelled'  || item?.seller_task?.strick_status?.strick_status === 'pending' || item?.seller_task?.strick_status?.strick_status === `refund payment` ?
            item?.affiliate_status === 'cancelled' ? 'Cancelled' : `Stricked (${item?.seller_task?.strick_status?.strick_status})` :
                item?.seller_task?.task_type === 'affiliate'
                    ? (item?.seller_task?.product_type === 'Website'
                        ? item?.seller_plans?.map(plan => {
                            const total = plan?.amount * (item?.seller_task?.insentive / 100);
                            totalPrice += Number(total);
                            return `₹ ${total}`;
                        }).join(', ') // Joining multiple plans with a comma
                        : ""
                    )
                    : (() => {
                        totalPrice += Number(item?.seller_task?.insentive);
                        return `₹ ${item?.seller_task?.insentive}`;
                    })()


        ]));


        // Create worksheet data
        const clientInfoData = [
            ['Affiliate Payments'],
            ['Affiliate ID', payDetails?.group?.a_personal_detail?.af_id],
            ['NPPID', payDetails?.group?.npp_id],
            ['Month of Payment', payDetails?.group?.month],
            [],
            ['Total Payment', '₹ ' + totalPrice],
            [],
            ['Task ID', 'Insentive', 'Total Amount'],
            []
        ];





        const worksheetData = clientInfoData.concat(transactionData);

        // Create worksheet and append data
        const ws = XLSX.utils.aoa_to_sheet(worksheetData);

        // Set column widths
        ws['!cols'] = [
            { wch: 20 }, // Transaction Date
            { wch: 25 }, // Transaction segment
            { wch: 15 }, // Quantity
            { wch: 15 }, // Buy price
            { wch: 15 }, // Sell Price
            { wch: 15 }  // Net PnL
        ];


        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Append sheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Client Transactions');

        // Write workbook to file
        XLSX.writeFile(wb, 'Client_Transactions.xlsx');

        console.log('Excel file has been generated successfully.');
    };

    return (
        <>
            <section className=''>
                <Modal
                    {...props}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='selldash-commonmodal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <p>Affiliate Pay Details</p>

                            <button className='btn btn-success ms-5' onClick={handleExcelExport}>Excel Export</button>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='form-sec'>

                                    <table class="table table-inverse table-responsive">
                                        <thead class="thead-inverse">
                                            <tr>
                                                <th>NPPID</th>
                                                <th>Affiliate Id</th>
                                                <th>Month</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{payDetails?.group?.npp_id}</td>
                                                <td>#{payDetails?.group?.a_personal_detail?.af_id}</td>
                                                <td>
                                                    {payDetails?.group?.month}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='form-sec'>

                                    <table class="table table-striped table-inverse table-responsive">
                                        <thead class="thead-inverse">
                                            <tr>
                                                {/* <th>Seller Id</th> */}
                                                <th>Task ID</th>
                                                <th>Insentive</th>
                                                <th>Total Amount</th>
                                                {totalPrice != 0 && (
                                                    <th></th>
                                                )}
                                                {/* <th>Insentive</th>
                                                <th>Buying Amount</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payDetails?.data?.map((value, index) => (
                                                <tr>
                                                    {/* <td>{value?.s_personal_detail?.s_id}</td> */}
                                                    <td>{value?.seller_task?.task_id}</td>
                                                    <td>
                                                        {value?.seller_task?.product_type === 'Website' ? (
                                                            value?.seller_task?.insentive + ' %'
                                                        ) : (
                                                            '₹ ' + value?.seller_task?.insentive
                                                        )}
                                                    </td>
                                                    <td>
                                                        {value?.affiliate_status === 'cancelled' || value?.seller_task?.strick_status?.strick_status === 'pending' || value?.seller_task?.strick_status?.strick_status === `refund payment` ? (

                                                            <span className='text text-danger'> {value?.affiliate_status === 'cancelled' ? 'Cancelled' : `Stricked (${value?.seller_task?.strick_status?.strick_status})`} </span>

                                                        ) : (




                                                            value?.seller_task?.task_type === 'affiliate' ? (
                                                                <>
                                                                    {value?.seller_task?.product_type === 'Website' ? (
                                                                        <>
                                                                            {value?.seller_plans?.map((plan, index) => {
                                                                                let total = plan?.amount * (value?.seller_task?.insentive / 100);
                                                                                totalPrice += Number(total);
                                                                                return (
                                                                                    <div key={index}>
                                                                                        ₹ {total}

                                                                                       
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </>
                                                                    ) : (
                                                                        <>

                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>

                                                                    <span style={{ display: 'none' }}>{totalPrice += Number(value?.seller_task?.insentive)}</span>
                                                                    <div>
                                                                        ₹ {value?.seller_task?.insentive}
                                                                        <span className='text text-danger ms-2'>
                                                                            {value?.seller_task?.strick_status?.strick_status === 'pending' && (
                                                                                `Stricked (${value?.seller_task?.strick_status?.strick_status})`
                                                                            )}
                                                                            {value?.seller_task?.strick_status?.strick_status === `refund payment` && (
                                                                                `Stricked (${value?.seller_task?.strick_status?.strick_status})`
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            )




                                                        )}




                                                    </td>
                                                    {/* <td>{dateShow(value?.createdAt)}</td>
                                                    <td>{value?.exp_date}</td>
                                                    <td>₹ {value?.amount * props.data?.seller_task?.insentive / 100}</td>
                                                    <td>₹ {value?.amount}</td> */}
                                                </tr>
                                            ))}

                                            <tr>
                                                <td></td>
                                                <td><b>Total Pay Amount : </b></td>
                                                <td>
                                                    <div className='d-flex'>
                                                        ₹ {totalPrice}
                                                    </div>

                                                </td>
                                                {/* {totalPrice != 0 && (
                                                    <td>
                                                        <button className='btn btn-success ms-3' onClick={makePayment}>Pay</button>
                                                    </td>
                                                )} */}

                                            </tr>


                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default PayDetailsModal