import React, { useContext, useEffect, useState } from 'react'
import { Col, Nav, Row, Tab, Form, Pagination, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select';
import { getStricks, postStrickStatus } from '../../../../utils/apis/affiliate';



import { Context } from "../../../../utils/context";
import StrickStatusModal from "../../../common/StrickStatusModal/StrickStatusModal";
import SuccessModalDynamic from "../../../common/SuccessModalDynamic/SuccessModalDynamic";
import ErrorModal from "../../../common/ErrorModal/ErrorModal";


const UserManagement = () => {



    const [promptModal, setPromptModal] = useState(false);
    const [promptUnBlockModal, setPromptUnBlockModal] = useState(false);
    const [strickModal, setStrickModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [promptBlockModal, setPromptBlockModal] = useState(false);
    const [strickSuccessModal, setStrickSuccessModal] = useState(false);
    const { formatDate } = useContext(Context);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];


    const [selectedOption, setSelectedOption] = useState(null);

    // Modal
    const [lgShow, setLgShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    // star Mark

    const [rating, setRating] = useState(0);

    const handleRating = () => {
        setRating(rating === 1 ? 0 : 1);
    };

    const [successModal, setSuccessModal] = useState(false);
    const [strickError, setStrickError] = useState("");
    const [strickId, setStrickId] = useState("");

    const [stricks, setStricks] = useState([]);
    const getStrickStatus = async (type = 'active') => {


        const res = await getStricks();

        if (res?.success) {
            setStricks(res?.data?.data)
        }

    }


    const submitStrickStatus = async (status,remark) => {
        setStrickModal(false);
        const data = {
            strick_status: status,
            company_remark: remark,
            id: strickId?.id,
        }

        const res = await postStrickStatus(data);

        if (res?.success) {
            setStrickSuccessModal(true);
            getStrickStatus();

            setTimeout(() => {
                setStrickSuccessModal(false);
            }, 3000);
        }else{
            setStrickError(res?.message);
            setErrorModal(true);

            setTimeout(() => {
                setErrorModal(false);
            }, 3000);
        }
    }


    const { totalPages, currentPage } = stricks;

    const renderPaginationItems = () => {
        const items = [];
      
        // Add the first page
        items.push(
          <Pagination.Item key={1} active={1 === currentPage}>
            {1}
          </Pagination.Item>
        );
      
        // Add pages before the current page
        if (currentPage > 3) {
          items.push(<Pagination.Ellipsis key="ellipsis-start" />);
        }
      
        for (let number = Math.max(2, currentPage - 2); number <= Math.min(totalPages - 1, currentPage + 2); number++) {
          items.push(
            <Pagination.Item key={number} active={number === currentPage}>
              {number}
            </Pagination.Item>
          );
        }
      
        // Add ellipsis after the current page
        if (currentPage < totalPages - 2) {
          items.push(<Pagination.Ellipsis key="ellipsis-end" />);
        }
      
        // Add the last page
        if (totalPages > 1) {
          items.push(
            <Pagination.Item key={totalPages} active={totalPages === currentPage}>
              {totalPages}
            </Pagination.Item>
          );
        }
      
        return items;
      };


    useEffect(() => {
        getStrickStatus();
    }, []);


    return (
        <section className='dashboard-in'>
            <div className='heading-holder'>
                {/* <h4>Complaint List</h4> */}
            </div>

            <div className='main-class-report-table'>
                <Row>
                    <Col xl={3} lg={3} md={5} sm={12} >
                        <Form.Label>Search</Form.Label>
                        <Form.Control
                            required
                            type="text"
                        />
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >
                        <Form.Label>From</Form.Label>
                        <Form.Control
                            required
                            type="Date"
                        />
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >
                        <Form.Label>To</Form.Label>
                        <Form.Control
                            required
                            type="Date"
                        />
                    </Col>
                    <Col xl={3} lg={5} md={5} sm={12}>
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} >
                                <Form.Label>Sort By</Form.Label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={options}
                                />
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} >
                                <Form.Label>Download data in Excel</Form.Label><br />
                                <FontAwesomeIcon icon="fa-solid fa-file-lines" className='pdf-icon' />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={2} lg={3} md={5} sm={12} >

                    </Col>
                </Row>

                <div className='brownborder-report mt-3'></div>
                <Row>
                    <div className='col-md-12'>
                        <div className='my-3'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" onClick={() => getStrickStatus()}>Stricks</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="table-sec-main mt-5">
                                            <Table responsive bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Affilate Id</th>
                                                        <th>Task Id</th>
                                                        <th>Seller Remark</th>
                                                        <th>Company Remark</th>
                                                        <th>Strick Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stricks?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>#{item?.a_personal_detail?.af_id}</td>
                                                            <td>{item?.seller_task?.task_id}</td>
                                                            <td>{item?.seller_remark}</td>
                                                            <td>{item?.company_remark}</td>
                                                            <td>{item?.strick_status}</td>
                                                            <td>


                                                                <button className='btn btn-danger ' onClick={() => { setStrickId(item); setStrickModal(true); }}>Status</button>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="paginationss mt-4">
                                            <Pagination>
                                                <Pagination.Prev
                                                    // onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                />
                                                {renderPaginationItems()}
                                                <Pagination.Next
                                                    // onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                />
                                            </Pagination>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </Row>
            </div>


            <StrickStatusModal show={strickModal} onHide={() => setStrickModal(false)} button={"Submit"} handleReasonFunction={submitStrickStatus} strickId={strickId} text={"Submit Your Strick Status"} />


            <SuccessModalDynamic show={strickSuccessModal} onHide={() => setStrickSuccessModal(false)} text={"Strick Submitted"} />
            <ErrorModal show={errorModal} onHide={() => setErrorModal(false)} text={strickError} />
        </section>

    )
}

export default UserManagement