// import React from 'react'

// function AccountPaymentSettingContent() {
//   return (
//     <div>AccountPaymentSettingContent</div>
//   )
// }

// export default AccountPaymentSettingContent

import React, { useState, useContext, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Context } from "../../../../utils/context";
import WithdrawRequests from "../../WithdrawRequest/Table";

const WithdrawRequest = () => {
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(1);

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);


  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="1"
        className="tabadminmain"
      >
        <Row className="me-0 ms-0">
          <Col xl={12} lg={12} md={10} className=" p-0">
            <Tab.Content className="tabadminmain">
              {[149, 150, 151, 152, 153, 154].some((value) =>
                isAllow.includes(value)
              ) ? (
                <>
                  <Tab.Pane eventKey="1">
                    <WithdrawRequests />
                  </Tab.Pane>
                </>
              ) : (
                <></>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default WithdrawRequest;
