import { React, useState, useEffect, useContext } from "react";
import "./Affi_setii_page.css";
import axios from "axios";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { Row, Col, InputGroup, Modal } from "react-bootstrap";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Button from "react-bootstrap/Button";

import { Context } from "../../../utils/context";
import ModalSave from "../../common/ModelSave";
const Setting_page = (props) => {
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [showError, setShowError] = useState("");

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();
  const { getData, postData, IMG_URL, Select2Data, Id, Select2CurrencyData } =
    useContext(Context);

  useEffect(() => {}, []);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const todayDate = getTodayDate();

  const onSubmit = async (data) => {
    try {
      if (data.date || data.days) {
        const response = await postData(
          `/affiliate/super-affiliate-setting`,
          data
        );

        if (response?.success) {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
        } else {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
        }
        setTimeout(() => {
          setShowModal(0);
          reset();
          setShowError("");
        }, 2000);
      } else {
        setShowError("Please Select Date Or Days");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getSetting = async () => {
    const res = await getData(`/affiliate/super-affiliate-setting`);
    if(res?.success){
      reset(res?.data);
    }
  }

  useEffect(() => {
    getSetting();
  },[]);
  return (
    <>
      <Form>
        <section className="affi-setting-seccc">
          <h5 className="aff-title">
            <span className="orange-border">Affiliate</span> Setting
          </h5>

          <div className="row">
            <div className="col-md-12">
              <h5 className="aff-title">
                <span className="orange-border">Maximum Add Value </span>{" "}
                Against Product
              </h5>
              <div className="row my-3">
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Percent Value</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="percent_value"
                        placeholder="Percent Value"
                        className={classNames("", {
                          "is-invalid": errors?.percent_value,
                        })}
                        {...register("percent_value", {
                          required: "Percent Value is required",
                        })}
                      />
                    </InputGroup>
                    {errors.percent_value && (
                      <span className="text-danger">
                        {errors.percent_value.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Amount</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        className={classNames("", {
                          "is-invalid": errors?.amount,
                        })}
                        {...register("amount", {
                          required: "Amount is required",
                        })}
                      />
                    </InputGroup>
                    {errors.amount && (
                      <span className="text-danger">
                        {errors.amount.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5 className="aff-title">
                <span className="orange-border">Affiliate Payment </span>{" "}
                Settlement Slots
              </h5>
              <div className="row my-3">
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Days</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="days"
                        placeholder="Days"
                        className={classNames("", {
                          "is-invalid": errors?.days,
                        })}
                        {...register("days", {
                          // required: "Days is required",
                        })}
                      />
                    </InputGroup>
                    {errors.days && (
                      <span className="text-danger">{errors.days.message}</span>
                    )}
                  </Form.Group>
                </div>

                <span style={{ color: "red" }}>{showError}</span>

                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Date</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="date"
                        name="date"
                        placeholder="Date"
                        min={todayDate}
                        className={classNames("", {
                          "is-invalid": errors?.date,
                        })}
                        {...register("date", {
                          // required: "Date is required",
                        })}
                      />
                    </InputGroup>
                    {errors.date && (
                      <span className="text-danger">{errors.date.message}</span>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5 className="aff-title">
                <span className="orange-border">Influencer </span>{" "}
                Block Amount Settings
              </h5>
              <div className="row my-3">
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Below Amount</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="below_amount"
                        placeholder="Below Amount"
                        className={classNames("", {
                          "is-invalid": errors?.below_amount,
                        })}
                        {...register("below_amount", {
                          required: "Below Amount is required",
                        })}
                      />
                    </InputGroup>
                    {errors.below_amount && (
                      <span className="text-danger">
                        {errors.below_amount.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Package % deduction</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="package_percent"
                        placeholder="Percent Value from package"
                        className={classNames("", {
                          "is-invalid": errors?.package_percent,
                        })}
                        {...register("package_percent", {
                          required: "Percent Value from package is required",
                        })}
                      />
                    </InputGroup>
                    {errors.package_percent && (
                      <span className="text-danger">
                        {errors.package_percent.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Visit Cost % deduction</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="visit_percent"
                        placeholder="Percent Value from visit"
                        className={classNames("", {
                          "is-invalid": errors?.visit_percent,
                        })}
                        {...register("visit_percent", {
                          required: "Percent Value from visit is required",
                        })}
                      />
                    </InputGroup>
                    {errors.visit_percent && (
                      <span className="text-danger">
                        {errors.visit_percent.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h5 className="aff-title">
                <span className="orange-border">Influencer </span>{" "}
                Penalty Settings
              </h5>
              <div className="row my-3">
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Below Amount Penalty</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="below_amount_penalty"
                        placeholder="Below Amount Penalty"
                        className={classNames("", {
                          "is-invalid": errors?.below_amount_penalty,
                        })}
                        {...register("below_amount_penalty", {
                          required: "Below Amount Penalty is required",
                        })}
                      />
                    </InputGroup>
                    {errors.below_amount_penalty && (
                      <span className="text-danger">
                        {errors.below_amount_penalty.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Amount Less %</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="amount_less_percent"
                        placeholder="Amount Less than below %"
                        className={classNames("", {
                          "is-invalid": errors?.amount_less_percent,
                        })}
                        {...register("amount_less_percent", {
                          required: "Amount Less than below % is required",
                        })}
                      />
                    </InputGroup>
                    {errors.amount_less_percent && (
                      <span className="text-danger">
                        {errors.amount_less_percent.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-2">
                  <Form.Group className="mb-3" controlId="percentValue">
                    <Form.Label>Amount Above %</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="amount_above_percent"
                        placeholder="Amount More than below %"
                        className={classNames("", {
                          "is-invalid": errors?.amount_above_percent,
                        })}
                        {...register("amount_above_percent", {
                          required: "Amount More than below % is required",
                        })}
                      />
                    </InputGroup>
                    {errors.amount_above_percent && (
                      <span className="text-danger">
                        {errors.amount_above_percent.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

          <Button
            className="btn submittttbtnnn"
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </section>
      </Form>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default Setting_page;
