import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import JoditEditor from "jodit-react";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL ,getDimension} = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/seller/masters/s-blog/${id}`);
    reset(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");
  const bannerimageFile = watch("banner_image");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("description", data?.description);
      finalData.append("title", data?.title);
      finalData.append("sub_title", data?.sub_title);
      finalData.append("image", data?.image[0]);
      finalData.append("banner_image", data.banner_image[0]);
      const response = await postData(`/seller/masters/s-blog/${id}`, finalData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Blog
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>

            <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Title</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Brand"
                          className={classNames("", {
                            "is-invalid": errors?.title,
                          })}
                          {...register("title", {
                            required: "Title is required",
                          })}
                        />
                      </InputGroup>
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sub Title</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Brand"
                          className={classNames("", {
                            "is-invalid": errors?.sub_title,
                          })}
                          {...register("sub_title", {
                            required: "Sub Title is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sub_title && (
                        <span className="text-danger">
                          {errors.sub_title.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              
              <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Brand"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              </Row>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {

                            validate: async (value) => {
                              if (typeof value !== "string") {
                                const fileTypes = ["jpg", "png", "jpeg"];
                                const fileType = value[0].name?.split(".")[1];

                                if (!fileTypes.includes(fileType)) {
                                  return `please upload a valid file format. (${fileTypes})`;
                                }

                                const sizes = await getDimension(value[0]);
                                if (sizes.width !== 50 && sizes.height !== 50) {
                                  return "Image width and height must be 50 px and 50 px";
                                }

                                const fileSize = Math.round(value[0].size / 1024);
                                if (fileSize > 1000) {
                                  return "file size must be lower than 1000kb";
                                }
                              }
                            },
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {typeof getValues("image") == "string" ? (
                        <div className="image-preview-container">
                          <img
                            src={IMG_URL + getValues("image")}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: 100, height: 100, marginLeft: 50 }}
                          />
                        </div>
                      ) : (
                        imageFile &&
                        imageFile?.length > 0 && (
                          <div className="image-preview-container">
                            <img
                              // src={URL.createObjectURL(getValues("image")[0])}
                              src={URL?.createObjectURL(imageFile[0])}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: 100, height: 100, marginLeft: 50 }}
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.banner_image,
                          })}
                          type="file"
                          {...register("banner_image", {

                            validate: async (value) => {
                              if (typeof value !== "string") {
                                const fileTypes = ["jpg", "png", "jpeg"];
                                const fileType = value[0].name?.split(".")[1];

                                if (!fileTypes.includes(fileType)) {
                                  return `please upload a valid file format. (${fileTypes})`;
                                }

                                const sizes = await getDimension(value[0]);
                                if (sizes.width !== 50 && sizes.height !== 50) {
                                  return "Image width and height must be 50 px and 50 px";
                                }

                                const fileSize = Math.round(value[0].size / 1024);
                                if (fileSize > 1000) {
                                  return "file size must be lower than 1000kb";
                                }
                              }
                            },
                          })}
                          accept=".jpg, .jpeg, .png"
                        />
                      </Form.Group>
                      {errors.banner_image && (
                        <span className="text-danger">
                          {errors.banner_image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Image Preview */}
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {typeof getValues("image") == "string" ? (
                        <div className="image-preview-container">
                          <img
                            src={IMG_URL + getValues("image")}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: 100, height: 100, marginLeft: 50 }}
                          />
                        </div>
                      ) : (
                        bannerimageFile &&
                        bannerimageFile?.length > 0 && (
                          <div className="image-preview-container">
                            <img
                              // src={URL.createObjectURL(getValues("image")[0])}
                              src={URL?.createObjectURL(bannerimageFile[0])}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: 100, height: 100, marginLeft: 50 }}
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

             
              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={2} className="text-center">
                      Description
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
