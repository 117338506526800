import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import './AddTaskModal.css'
import { useContext, useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from '../../../../utils/context';
import classNames from "classnames";
import JoditEditor from "jodit-react";
import SuccessModal from './SuccessModal';

const AffiliateModal = (props) => {


    const {dateShow} = useContext(Context);
    return (
        <>
            <section className=''>
                <Modal
                    {...props}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='selldash-commonmodal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Affiliate Task
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='form-sec'>

                                    <table class="table table-striped table-inverse table-responsive">
                                        <thead class="thead-inverse">
                                            <tr>
                                                {/* <th>Seller Id</th> */}
                                                <th>Plan Name</th>
                                                <th>Buying Date</th>
                                                <th>Exp Date</th>
                                                <th>Insentive</th>
                                                <th>Buying Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.data?.seller_plans?.map((value, index) => (
                                                <tr>
                                                    {/* <td>{value?.s_personal_detail?.s_id}</td> */}
                                                    <td>{value?.m_w_plan_detail?.name}</td>
                                                    <td>{dateShow(value?.createdAt)}</td>
                                                    <td>{value?.exp_date}</td>
                                                    <td>₹ {value?.amount * props.data?.seller_task?.insentive / 100}</td>
                                                    <td>₹ {value?.amount}</td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default AffiliateModal