import React from 'react'
import Header from '../../Header/Header'
import AffilateActivityTable from './AffilateActivityTable/AffilateActivityTable'
import MainDashoboardTab from '../InhouseAds/MainDashoboardTab/MainDashoboardTab'

const AffiliateActivity = () => {
    return (
        <>
            <Header title={"Affiliate Activity"} />
            <MainDashoboardTab />
            <AffilateActivityTable />
        </>

    )
}

export default AffiliateActivity