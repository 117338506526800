import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  import Cookies from "js-cookie";
  import { v4 as uuidv4 } from "uuid";


  export const withrawPay = async (data) => {
    try {
      return await postData(`/payment/withdraw-requests/`,data);
    } catch (error) {
      console.log(error);
    }
  };


  export const walletUpdate = async (data) => {
    try {
      return await postData(`/payment/wallet/`,data);
    } catch (error) {
      console.log(error);
    }
  };
  

  

  
 
  
  