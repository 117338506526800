import React from "react";
import { Col, Row } from "react-bootstrap";

import NewCustomerTable from "../../../../../affiliate/AffiliateDashboard/DashboardTab/New-Customer-table/NewCustomerTable";

const DashboardReportContent = () => {
  return (
    <section className="affiliate-desh-board">
      <Row className="me-0 ms-0">
        <Col xxl={12} xl={12}>
          <NewCustomerTable />
        </Col>
      </Row>
    </section>
  );
};

export default DashboardReportContent;
