import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../common/ModelSave";
import { CancelButton } from "../../common/Button";
import { Row, Col, Form, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "../../Tabels/Tabels.css";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({ code: 0, message: "" });
  const [data, setData] = useState({});

  const GetEditData = async () => {
    const response = await getData(`/my-website/pricing/seller-plan/${id}`);
    reset(response?.data);
    setData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("image", data?.image[0]);
      const response = await postData(`/my-website/pricing/seller-plan/${id}`, finalData);
      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
      } else {
        setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewPdf = (invoicePath) => {
    if (invoicePath) {
      const pdfWindow = window.open('', '_blank');
      pdfWindow.document.write('<html><body><iframe width="100%" height="100%" src="' + invoicePath + '"></iframe></body></html>');
      pdfWindow.document.close();
    } else {
      console.error('No invoice path provided');
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Seller's Plan Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <h5>Seller Information</h5>
                  <p><strong>Name:</strong> {data?.s_personal_detail?.f_name} {data?.s_personal_detail?.l_name}</p>
                  <p><strong>Plan Name:</strong> {data?.m_w_plan_detail?.name} ({data?.m_w_plan_detail?.day} days)</p>
                  <p><strong>Amount:</strong> ₹ {data?.m_w_plan_detail?.selling_price}</p>
                  <p><strong>Start Date:</strong> {new Date(data?.createdAt).toDateString()}</p>
                  <p><strong>Expiry Date:</strong> {data?.exp_date}</p>
                  <p><strong>Invoice No.:</strong> {data?.invoice_no}</p>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <h5>Domain Information</h5>
                  <p><strong>Domain Name:</strong> {data?.domain?.domain_name}</p>
                  <p><strong>Domain:</strong> <Link>{data?.domain?.domain}</Link></p>
                </div>
              </Col>
            </Row>

            <Row className="mt-5 pb-3 justify-content-center">
              <Col md="auto">
                <CancelButton
                  name="Close"
                  handleClose={props.handleClose}
                />
              </Col>

              <Col md="auto">
                {(data?.invoice || data?.invoice_a5) && (
                  <DropdownButton id="dropdown-basic-button" title="View PDF">
                    {data?.invoice && (
                      <Dropdown.Item onClick={() => handleViewPdf(IMG_URL + data.invoice)}>
                        A4 Size
                      </Dropdown.Item>
                    )}
                    {data?.invoice_a5 && (
                      <Dropdown.Item onClick={() => handleViewPdf(IMG_URL + data.invoice_a5)}>
                        A5 Size
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
