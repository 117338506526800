import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SettingContent from "../SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";

import AffiliateModal from "../../affiliate/InhouseAds/DashboardInHouse/AffiliateModal";
import InfluencerWorkModal from "../../common/influencer-work-modal/influencer-work-modal";
import ErrorModal from "../../common/ErrorModal/ErrorModal"
import PayDetailsModal from "../../common/PayDetailsModal";
import SuccessModalDynamic from "../../common/SuccessModalDynamic/SuccessModalDynamic";
import Select from "react-select";
import { getAffiliatePayDetails } from "../../../utils/apis/affiliatePayment";

import * as XLSX from 'xlsx';

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    formatDateExpire,
    formatDate,
  } = useContext(Context);

  const [workData, setWorkData] = useState();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [month, setMonth] = useState("");
  const [affiliateId, setAffiliateId] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [searchStatus, setSearchStatus] = useState("");
  const getDataAll = async () => {


    let url = `/payment/influencer-payment/?page=${currentPage}&per_page=${perPage}`;

    if (search !== "") {
      url += `&npp=${search}`;
    }
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    const response = await getData(url);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/seller/masters/hsn-code/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);


  console.log(allChecked, 'allChecked');
  console.log(selectAllChecked, 'selectAllChecked');
  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [affiliateData, setAffiliateData] = useState("");
  const [affiliateModal, setAffiliateModal] = useState(false);
  const [workModal, setWorkModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/seller/masters/hsn-code/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
    col14: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });






  const [nppId, setNppId] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const handlePayDetails = async () => {
    if (selectAllChecked.length > 0) {
      setPayModal(true);
    } else {
      setErrorModal(true);
      setTimeout(() => {
        setErrorModal(false);
      }, 3000);
    }
  }

  const calculationFunction = async (item) => {
    let totalPrice = 0;

    // console.log(item, 'item');

    item?.affiliate_tasks?.forEach(value => {
      if (value?.affiliate_status !== 'cancelled' && value?.seller_task?.strick_status?.strick_status !== 'pending' && value?.seller_task?.strick_status?.strick_status !== `refund payment` && value?.pyd_datum == null) {
        if (value?.seller_task?.task_type === 'affiliate') {
          if (value?.seller_task?.product_type === 'Website') {
            value?.seller_plans?.forEach(plan => {
              const total = plan?.amount * (value?.seller_task?.insentive / 100);
              totalPrice += Number(total);
            });
          }
        } else {
          totalPrice += Number(value?.seller_task?.insentive);
        }
      }

    });

    // console.log(totalPrice, 'item');

    return totalPrice;
  }


  const handleExcelExport = async () => {

    console.log(data);

    // Create worksheet data
    const clientInfoData = [
      ['Sr.No.', 'NPPID', 'Month', 'TotalAmountPayment', 'Affiliate Name','AffiliateID','Country Code', 'AccountNo', 'IFSC Code', 'Bank Name', 'MICR Code', 'PaymentTransactionID','Median'],
      []
    ];



    const transactionData = await Promise.all(data?.data?.data?.map(async (item, index) => {
      const totalPrice = await calculationFunction(item);

      if (totalPrice > 0) {
        return [
          index + 1,
          item?.npp_id,
          item?.month,
          totalPrice,
          item?.a_personal_detail?.f_name + " " + item?.a_personal_detail?.l_name,
          item?.a_personal_detail?.af_id,
          item?.a_personal_detail?.a_firm_address_detail?.country?.name,
          item?.a_personal_detail?.a_bank_detail?.bank_account_no,
          item?.a_personal_detail?.a_bank_detail?.ifsc_code,
          item?.a_personal_detail?.a_bank_detail?.bank_name,
          item?.a_personal_detail?.a_bank_detail?.micr_code,
        ];
      }


    }));


    const worksheetData = clientInfoData.concat(transactionData);

    // Create worksheet and append data
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Set column widths
    ws['!cols'] = [
      { wch: 20 }, // Transaction Date
      { wch: 25 }, // Transaction segment
      { wch: 15 }, // Quantity
      { wch: 15 }, // Buy price
      { wch: 15 }, // Sell Price
      { wch: 15 }  // Net PnL
    ];


    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Append sheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Client Transactions');

    // Write workbook to file
    XLSX.writeFile(wb, 'Client_Transactions.xlsx');

    console.log('Excel file has been generated successfully.');
  };

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [bulkUpload, setBulkUpload] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
      setBulkUpload(true); // Assuming setBulkUpload is defined elsewhere
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const [successModal, setSuccessModal] = useState(false);

  const pydFileUpload = async () => {

    const finaldata = new FormData();
    finaldata.append('file', file)


    const res = await postData(`/payment/influencer-payment/bulk`, finaldata);

    if (res?.success) {
      setFileName('');
      setFile('');
      setBulkUpload(false);
      setSuccessModal(true);

      setTimeout(() => {
        setSuccessModal(false);
      }, 3000);
    }
  }




  return (
    <>
      <div className="main-advancedashboard">
        <section className="AdvanceDashboard">
          <div className="row  me-0 ms-0  MainRowsec">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio ">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex add-btn-holder">
                      <div className="add me-3">
                        <b>Affiliate Payments</b>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="  col-md-6 col-12">
                        <div className="d-flex row-flex-holder">

                          <div className="show me-2">
                            <p className="show ">Show</p>
                          </div>

                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="entries">
                            <p className="show ">entries</p>
                          </div>

                          <div className="sowing ms-5">
                            <p className="show ">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>



                        </div>
                      </div>

                      <div className="col-md-6 text-end">
                        <div className="justify-content-end">
                          {fileName && <p><b>Selected File :</b> {fileName}</p>}
                        </div>
                      </div>

                      <div className="   col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex row-flex-holder justify-content-end">
                          <div className="show me-2">
                            <div className="d-flex">
                              <button onClick={triggerFileInput} className="btn btn-success ms-3 mt-3">PYD Upload</button>
                              <input
                                type="file"
                                hidden
                                ref={fileInputRef}
                                onChange={handleFileChange}
                              />

                              {bulkUpload && (
                                <button className='btn btn-success mt-3' onClick={pydFileUpload}>Upload Transactions</button>
                              )}

                              <button className='btn btn-success ms-3 mt-3' onClick={handleExcelExport}>Excel Export</button>
                            </div>

                          </div>
                        </div>

                      </div>
                      <div className="   col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end entry-showing">

                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              placeholder=" Search by NPPID"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setSearchStatus("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {" "}

                            {visible.col1 && <th className="influencer-payment-name">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="influencer-payment-name">NPPID</th>
                            )}{" "}
                            {visible.col3 && (
                              <th className="influencer-payment-name">Affiliate Id</th>
                            )}
                            {visible.col4 && (
                              <th className="influencer-payment-name">Month</th>
                            )}
                            {visible.col14 && (
                              <th className="influencer-payment-name">Action</th>
                            )}
                            {visible.col13 && (
                              <th className="influencer-payment-name">Status</th>
                            )}

                            {/* {visible.col12 && (
                              <th className="active">Action</th>
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {isAllow.includes(263) ? (
                            data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}</td>}{" "}
                                  {visible.col2 && (
                                    <td>{d?.npp_id}</td>
                                  )}{" "}
                                  {visible.col3 && (
                                    <td>#{d?.a_personal_detail?.af_id}</td>
                                  )}
                                  {visible.col4 && (
                                    <td>{d?.month}</td>
                                  )}
                                  {visible.col14 && (
                                    <td>
                                      <div className="d-flex">
                                        <button className="btn btn-info" onClick={() => { setNppId(d?.id); setPayModal(true); }}>View Payments</button>
                                      </div>

                                    </td>
                                  )}


                                  {visible.col13 && (
                                    <td>
                                      {d?.pyd_datum ? (
                                            <span className="text text-success">Payment Done Successfully</span>
                                      ):(
                                         <span className="text text-danger">Payment Not Done Yet</span>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
      <InfluencerWorkModal show={workModal} onHide={() => setWorkModal(false)} data={workData} />
      <AffiliateModal show={affiliateModal} onHide={() => setAffiliateModal(false)} data={affiliateData} />



      <PayDetailsModal show={payModal} onHide={() => setPayModal(false)} nppId={nppId} />



      <ErrorModal show={errorModal} onHide={() => setErrorModal(false)} text={'Select payments first'} />


      <SuccessModalDynamic show={successModal} onHide={() => setSuccessModal(false)} text={"Payments Done Successfully!"} />
    </>
  );
};

export default Tables;
