import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import JoditEditor from "jodit-react";
import Select from "react-select";

// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension, getData, Select2Data } = useContext(Context);
  const [searchCountry, setSearchCountry] = useState("");
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    trigger,
  } = useForm();
  const descriptionValue = watch("description");
  const imageFile = watch("image");

  const [category, setCountries] = useState([]);

  const GetAllCountry = async () => {
    const response = await getData("/my-website/masters/masters/c-features");

    if (response?.success) {
      setCountries(await Select2Data(response?.data, "m_w_c_features_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("m_w_c_features_id", data?.m_w_c_features_id?.value);
      finalData.append("name", data?.name);
      finalData.append("price", data?.price);
      finalData.append("type", data?.type?.value);

      // console.log("finalData", finalData);
      const response = await postData(
        `/my-website/pricing/s-features`,
        finalData
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Features SubCategory
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Features Category</Form.Label>
                    <Controller
                      name="m_w_c_features_id" // name of the field
                      {...register("m_w_c_features_id", {
                        required: "Select Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.m_w_c_features_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={category}
                        />
                      )}
                    />
                    {errors.m_w_c_features_id && (
                      <span className="text-danger">
                        {errors.m_w_c_features_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Features SubCategory</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Type</Form.Label>
                    <Controller
                      name="type" // name of the field
                      {...register("type", {
                        // required: "Select Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.type ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={[
                            { value: "per_day", label: "per_day" },
                            { value: "per_call", label: "per_call" },
                            { value: "per_annual", label: "per_annual" },
                            { value: "per_month", label: "per_month" },
                          ]}
                        />
                      )}
                    />
                    {errors.type && (
                      <span className="text-danger">{errors.type.message}</span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Price</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="price"
                          placeholder="Price"
                          className={classNames("", {
                            "is-invalid": errors?.price,
                          })}
                          {...register("price", {
                            // required: "Price is required",
                          })}
                        />
                      </InputGroup>
                      {errors.price && (
                        <span className="text-danger">
                          {errors.price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
