import React from 'react'
import Header from '../../Header/Header'
import MainDashoboardTab from '../InhouseAds/MainDashoboardTab/MainDashoboardTab'
import UserManagement from './UserManagement/UserManagement'

const Stricks = () => {
    return (
        <>
            <Header title={"Stricks"} />
            <UserManagement />
        </>
    )
}

export default Stricks