import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  import Cookies from "js-cookie";
  import { v4 as uuidv4 } from "uuid";


  
  export const getAffiliatePayDetails = async (data) => {
    try {
      return await postData(`/payment/influencer-payment/pay-details`,data);
    } catch (error) {
      console.log(error);
    }
  };
  

  

  
 
  
  