import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./SuccessModal.css";
import Animation from "../Animation/SuccessAnimation/Animation";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
const PromptModalDynamic = (props) => {

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();



  const onSubmit = async (d) => {
    props.handleReasonFunction(d.remark);
    setValue('remark',"");
    // give onHide in handleReasonFunction not here
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="SuccessModalPop"
      >
        <Modal.Body>
          <div className="modalContentinfo">
            <div className="modalHead">
              {/* <h3>SUCCESS!!</h3> */}
              <p>{props?.text}</p>
            </div>


           

            <div className="text-center">
            {errors.remark && (
                        <span className="text-danger">
                          {errors.remark.message}
                        </span>
                      )}
                        <Form.Control
                          type="text"
                          name="remark"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder={props.placeholder}
                          className={classNames("mb-3", {
                            "is-invalid": errors?.remark,
                          })}
                          {...register("remark", {
                            required: "Field is requried",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      
             
              <Button className="btn btn-danger" type="button" onClick={props.onHide}>Cancel</Button>
            
              <Button className="btn btn-success ms-3" onClick={handleSubmit(onSubmit)}>{props.button}</Button>
              
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PromptModalDynamic;
