import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import Form from 'react-bootstrap/Form';
import './AddTaskModal.css'
import { useContext, useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from '../../../../utils/context';
import classNames from "classnames";
import JoditEditor from "jodit-react";
import SuccessModal from './SuccessModal';

const AddTaskModal = (props) => {
    const { Select2Data, Select3Data, getData, postData, WEBSITE_PLAN_URL,
        BTB_URL,
        BTC_URL } = useContext(Context);
    const [productType, setProductType] = useState();
    const [insentive, setInsentive] = useState(0);
    const [product, setProduct] = useState();
    const [packages, setPackage] = useState([]);
    const [productData, setProductsData] = useState("");
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [longValidation, setLongValidation] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
        reset, trigger
    } = useForm();

    const getProduct = async (type) => {
        {
            const res = await getData(`/common/affiliate/masters/allProduct?product_type=${type}`);
            if (res?.success) {
                setProduct(await Select3Data(res?.data, 'product_id', false));
                // setOrderStatus(res?.data)
            }
        }

    };

    const getPackages = async () => {
        {
            const res = await getData(`/my-website/pricing/plan/all`);
            if (res?.success) {
                setPackage(res?.data);
                // setOrderStatus(res?.data)
            }
        }
    }

    console.log(errors, "product");


    const productTypeOption = [
        { value: 'BTB', label: 'B2B' },
        { value: 'BTC', label: 'B2C' },
        { value: 'Website', label: 'Website' }
    ]
    const options = [
        { value: '1', label: 'Yes' },
        { value: '0', label: 'NO' }
    ]

    console.log(productType, "productType");

    const onSubmit = async (d) => {
        console.log(d, "form data");
        const data = {
            product_id: d.product_id?.value,
            product_type: d.product_type?.value,
            product_available: d.product_available?.value,
            task_type: 'affiliate',
            validity: d.validity,
            long_validity: d?.long_validity,
            instruction: d.instruction,
            long_term: d.long_term?.value,
            insentive: d.insentive,
            highlight_point: d.highlight_point,
            target_view: d.target_view,
            website_url: d.product_type?.value === 'BTB'
                ? BTB_URL + d.product_id?.value
                : d.product_type?.value === 'BTC'
                    ? BTC_URL + d.product_id?.value
                    : WEBSITE_PLAN_URL

        };
        const res = await postData(`/affiliate/inhouse-task`, data);
        if (res?.success) {
            props.onHide();
            reset();
            setProductsData('');
            setSuccessModalShow(true);
            props.getAllProducts();
            setTimeout(() => {
                setSuccessModalShow(false);
            }, 2000);
        }
    }

    const handleProductData = async (product_id) => {
        const res = await getData(`/common/affiliate/masters/single-product/${product_id?.value}`);
        if (res?.success) {
            setProductsData(res?.data);
        }
    }

    useEffect(() => {
        getPackages();
    }, []);
    return (
        <>
            <section className=''>
                <Modal
                    {...props}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='selldash-commonmodal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Task
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='form-sec'>
                                    <Form>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Choose Product Type</Form.Label>
                                                    <Controller
                                                        name="product_type"
                                                        {...register("product_type", {
                                                            required: "Product Type Is Required",
                                                        })}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                styles={{
                                                                    control: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        borderColor: errors.productId ? "red" : baseStyles,
                                                                    }),
                                                                }}
                                                                {...field}
                                                                options={productTypeOption}
                                                                onChange={(options) => {
                                                                    setProductType(options?.value);
                                                                    setValue("product_type", options)
                                                                    getProduct(options?.value)
                                                                }
                                                                }
                                                                placeholder="--- please select ---"
                                                            />
                                                        )}

                                                    />
                                                    {errors?.product_type && (
                                                        <span className="text text-danger">
                                                            {errors.product_type.message}
                                                        </span>
                                                    )}

                                                </Form.Group>

                                                <Form.Group className="mb-3" >
                                                    <Form.Label>
                                                        {(productType === 'BTB' || productType === 'BTC') ? (
                                                            <>Amount per product selling insentive</>
                                                        ) : (
                                                            <>Percent per plan selling insentive</>
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.insentive,
                                                        })}
                                                        type="number" {...register("insentive", {
                                                            required:
                                                                (productType === 'BTB' || productType === 'BTC') ? (
                                                                    "Insentive Amount Is Required"
                                                                ) : (
                                                                    "Insentive Percent Is Required"
                                                                )
                                                            ,
                                                        })} placeholder={
                                                            (productType === 'BTB' || productType === 'BTC') ? (
                                                                "Enter Amount")
                                                                :
                                                                (
                                                                    "Enter Percent"
                                                                )

                                                        }

                                                        onKeyUp={(e) => setInsentive(e.target.value)}

                                                    />
                                                    {errors?.insentive && (
                                                        <span className="text text-danger">
                                                            {errors.insentive.message}
                                                        </span>
                                                    )}
                                                </Form.Group>

                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Product available for review</Form.Label>
                                                    <Controller
                                                        name="product_available"
                                                        {...register("product_available", {
                                                            required: "Available for review is required"
                                                        })}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                styles={{
                                                                    control: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        borderColor: errors.product_available ? "red" : baseStyles,
                                                                    }),
                                                                }}
                                                                {...field}
                                                                options={options}
                                                                placeholder="--- Please select ---" />
                                                        )}
                                                    />
                                                    {errors?.product_available && (
                                                        <span className="text text-danger">
                                                            {errors.product_available.message}
                                                        </span>
                                                    )}
                                                </Form.Group>

                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Validity (in Days)</Form.Label>
                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.validity,
                                                        })}
                                                        type="number" {...register("validity", {
                                                            required: "validity Is Required",
                                                        })} placeholder="Enter Amount" />

                                                    {errors?.validity && (
                                                        <span className="text text-danger">
                                                            {errors.validity.message}
                                                        </span>
                                                    )}
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"

                                                >
                                                    <Form.Label>Highlight Point</Form.Label>
                                                    <Controller
                                                        name="highlight_point"
                                                        control={control}
                                                        rules={{
                                                            required: 'highlight point is required.',

                                                        }}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <JoditEditor
                                                                value={value}
                                                                onBlur={() => {
                                                                    onBlur();
                                                                    trigger('highlight_point');
                                                                }}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />

                                                    {errors.highlight_point && (
                                                        <span className="text-danger">
                                                            {errors.highlight_point.message}
                                                        </span>
                                                    )}
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"

                                                >
                                                    <Form.Label>Instructions</Form.Label>
                                                    <Controller
                                                        name="instruction"
                                                        control={control}
                                                        rules={{
                                                            required: 'instruction is required.',

                                                        }}
                                                        render={({ field: { onChange, onBlur, value } }) => (
                                                            <JoditEditor
                                                                value={value}
                                                                onBlur={() => {
                                                                    onBlur();
                                                                    trigger('instruction');
                                                                }}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />

                                                    {errors.instruction && (
                                                        <span className="text-danger">
                                                            {errors.instruction.message}
                                                        </span>
                                                    )}
                                                </Form.Group>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='row'>

                                                    {/* <div className={productType === 'BTB' || productType === 'BTC' ? '' : 'd-none'}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Choose Product</Form.Label>
                                                    <Controller
                                                        name="product_id"
                                                        {...register("product_id", {
                                                            required: "Product Is Required",
                                                        })}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                styles={{
                                                                    control: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        borderColor: errors.product_id ? "red" : baseStyles,
                                                                    }),
                                                                }}
                                                                {...field}
                                                                options={product}
                                                                onChange={(options) => {                                                                    
                                                                    setValue("product_id",options)
                                                                }
                                                                }
                                                                placeholder="--- please select ---"
                                                            />
                                                        )}

                                                    />
                                                    {errors?.product_id && (
                                                        <span className="text text-danger">
                                                            {errors.product_id.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                                    <div className='col-lg-4 col-sm-6'>
                                                        <Form.Group className="mb-3" >
                                                            <div><Form.Label>Brand Name <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                            <div><Form.Label>AAA</Form.Label></div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-6'>
                                                        <Form.Group className="mb-3" >
                                                            <div><Form.Label>Type <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                            <div><Form.Label>B2B</Form.Label></div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-6'>
                                                        <Form.Group className="mb-3" >
                                                            <div><Form.Label>Brand Name <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                            <div><Form.Label>AAA</Form.Label></div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-6'>
                                                        <Form.Group className="mb-3" >
                                                            <div><Form.Label>Industries<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                            <div><Form.Label>Industry 1</Form.Label></div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-6'>
                                                        <Form.Group className="mb-3" >
                                                            <div>
                                                                <Form.Label>Product Review<span className='labelsbtext'>(Non editable)</span></Form.Label>
                                                            </div>
                                                            <div><Form.Label>4.3</Form.Label></div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-6'>
                                                        <Form.Group className="mb-3" >
                                                            <div><Form.Label>Cost<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                            <div><Form.Label>500</Form.Label></div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-6'>
                                                        <Form.Group className="mb-3" >
                                                            <div><Form.Label>Discount<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                            <div><Form.Label>5%</Form.Label></div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                               
                                                <div className={productType === 'Website' ? '' : 'd-none'}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Website URL</Form.Label>
                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.website_url,
                                                        })}
                                                        type="text" {...register("website_url", {
                                                            required: "Website URL Is Required",
                                                        })} placeholder="Website URL" />
                                                    {errors?.website_url && (
                                                        <span className="text text-danger">
                                                            {errors.website_url.message}
                                                        </span>
                                                    )}
                                                </Form.Group>

                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Target View</Form.Label>
                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.target_view,
                                                        })}
                                                        type="text" {...register("target_view", {
                                                            required: "Target View Is Required",
                                                        })} placeholder="Target View" />
                                                    {errors?.target_view && (
                                                        <span className="text text-danger">
                                                            {errors.target_view.message}
                                                        </span>
                                                    )}
                                                </Form.Group>
                                                </div> */}

                                                    {(productType === 'BTB' || productType === 'BTC') ? (
                                                        <div>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Choose Product</Form.Label>
                                                                <Controller
                                                                    name="product_id"
                                                                    {...register("product_id", {
                                                                        required: "Product Is Required",
                                                                    })}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderColor: errors.product_id ? "red" : baseStyles.borderColor,
                                                                                }),
                                                                            }}
                                                                            {...field}
                                                                            options={product}
                                                                            onChange={(options) => {
                                                                                setValue("product_id", options);
                                                                                handleProductData(options);
                                                                            }}
                                                                            placeholder="--- please select ---"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors?.product_id && (
                                                                    <span className="text text-danger">
                                                                        {errors.product_id.message}
                                                                    </span>
                                                                )}
                                                            </Form.Group>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3" >
                                                                    <div><Form.Label>Brand Name <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>{productData.s_brand?.name}</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3" >
                                                                    <div><Form.Label>Type <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>{productData.added_by}</Form.Label></div>
                                                                </Form.Group>
                                                            </div>

                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3" >
                                                                    <div><Form.Label>Industries<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>{productData.s_category?.name}</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3" >
                                                                    <div>
                                                                        <Form.Label>Product Review<span className='labelsbtext'>(Non editable)</span></Form.Label>
                                                                    </div>
                                                                    <div><Form.Label>4.3</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3" >
                                                                    <div><Form.Label>Cost<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>500</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3" >
                                                                    <div><Form.Label>Discount<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>5%</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            {/* <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3">
                                                                    <div><Form.Label>Brand Name <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>AAA</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3">
                                                                    <div><Form.Label>Type <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>B2B</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3">
                                                                    <div><Form.Label>Brand Name <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>AAA</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3">
                                                                    <div><Form.Label>Industries<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>Industry 1</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3">
                                                                    <div>
                                                                        <Form.Label>Product Review<span className='labelsbtext'>(Non editable)</span></Form.Label>
                                                                    </div>
                                                                    <div><Form.Label>4.3</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3">
                                                                    <div><Form.Label>Cost<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>500</Form.Label></div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className='col-lg-4 col-sm-6'>
                                                                <Form.Group className="mb-3">
                                                                    <div><Form.Label>Discount<span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                                    <div><Form.Label>5%</Form.Label></div>
                                                                </Form.Group>
                                                            </div> */}
                                                        </div>
                                                    ) : (productType === 'Website' ? (
                                                        <div>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Website URL</Form.Label>
                                                                <Form.Control

                                                                    disabled
                                                                    type="text"
                                                                    placeholder="Website URL"
                                                                    value={WEBSITE_PLAN_URL}
                                                                />

                                                            </Form.Group>

                                                            <Form.Group className="mb-5" >
                                                                <Form.Label>Long Term Offer</Form.Label>
                                                                <Controller
                                                                    name="long_term"
                                                                    {...register("long_term", {
                                                                        required: "Long Term is required"
                                                                    })}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            styles={{
                                                                                control: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    borderColor: errors.long_term ? "red" : baseStyles,
                                                                                }),
                                                                            }}
                                                                            {...field}
                                                                            options={options}
                                                                            placeholder="--- Please select ---"
                                                                            onChange={(e) => {
                                                                                setValue('long_term',e);
                                                                                if (e.value == 1) {
                                                                                    setLongValidation(true);
                                                                                } else {
                                                                                    setLongValidation(false);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors?.long_term && (
                                                                    <span className="text text-danger">
                                                                        {errors.long_term.message}
                                                                    </span>
                                                                )}
                                                            </Form.Group>
                                                            {longValidation && (
                                                                <Form.Group className="mb-5" >
                                                                    <Form.Label>Long Term Validation (in Years)</Form.Label>
                                                                    <Form.Control
                                                                        className={classNames("", {
                                                                            "is-invalid": errors?.long_validity,
                                                                        })}
                                                                        type="number" {...register("long_validity", {
                                                                            required: "validity Is Required",
                                                                        })} placeholder="Enter Validity (in Years)" />

                                                                    {errors?.long_validity && (
                                                                        <span className="text text-danger">
                                                                            {errors.long_validity.message}
                                                                        </span>
                                                                    )}
                                                                </Form.Group>
                                                            )}


                                                            <hr />
                                                            {packages?.map((value) => (
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Label>{value?.name}</Form.Label>
                                                                    <p><b>Plan Amount:</b> ₹ {value?.selling_price}</p>
                                                                    <p><b>Incentive Amount:</b> ₹ {value?.selling_price * insentive / 100}</p>
                                                                </Form.Group>
                                                            ))}

                                                        </div>
                                                    ) : null)}


                                                </div>

                                                {/* <div className='row'>
                                                    <div className='col-lg-8  col-sm-6'>
                                                        <Form.Group className="mb-1" >
                                                            <div><Form.Label>Highlit Point <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                        </Form.Group>
                                                        <div className='ullistt'>
                                                            <ul>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                                <li>Lorem ipsum dolor sit amet, consectetur</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4  col-sm-6'>
                                                        <Form.Group className="mb-1" >
                                                            <div><Form.Label>Image <span className='labelsbtext'>(Non editable)</span></Form.Label></div>
                                                            <div className=''>
                                                                <img className='bjvbhbvh-img' src={process.env.PUBLIC_URL + '/assest/images/Seller_panel/dashboard/images/viewimg.png'} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className='text-end'>
                                            <button className='btn closebtn'>Close</button>
                                            <button className='btn sbmitbtn' onClick={handleSubmit(onSubmit)}>Submit</button>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                            {/* <div className='col-lg-3'>
                                <Form.Group className="mb-1" >
                                    <div><Form.Label>Instructions</Form.Label></div>
                                </Form.Group>
                                <div className='ullistt bg'>
                                    <ul>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur</li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </Modal.Body>
                </Modal>
                <SuccessModal show={successModalShow} />
            </section>
        </>
    )
}

export default AddTaskModal