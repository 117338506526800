import React from 'react'
import Header from '../../Header/Header'
import MainDashoboardTab from '../InhouseAds/MainDashoboardTab/MainDashoboardTab'
import UserManagement from './UserManagement/UserManagement'

const AffilateUserManage = () => {
    return (
        <>
            <Header title={"Affilate User Manage"} />
            <MainDashoboardTab />
            <UserManagement />
        </>
    )
}

export default AffilateUserManage