import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import EditOffCanvance from "./Reasons";

// import ModalDelete from "../../../common/ModelDelete";
// import ModelBulkUpload from "../../../common/ModelBulkUpload";
import {
  AddButton,
  EditButton,
  DeletButton,
  ViewButton,
} from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { Button, Col, Nav, Row, Tab } from "react-bootstrap";

import parse from "html-react-parser";
import ViewOffCanvance from "./ViewOffCanvance";
import { Context } from "../../../utils/context";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    Per_Page_Dropdown,
    getDownloadDataExcel,
    isAllow,
    postData,
  } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);

  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  const [showPopup, setShowPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (event) => {
    setRejectReason(event.target?.value);
  };

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const getDataAll = async () => {
    const response = await getData(
      `/seller/products/all-products?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  const [view, setview] = useState(0);
  const handleViewClose = () => setview(false);
  const handleViewshow = async (id) => {
    console.log(id);
    await setview(id);
  };

  const getAllAccepted = async () => {
    const response = await getData(`/seller/products/accepted-products`);
    await setAccepted(response);
  };

  const getAllRejected = async () => {
    const response = await getData(`/seller/products/rejected-products`);
    await setRejected(response);
  };

  useEffect(() => {
    getDataAll();
    getAllAccepted();
    getAllRejected();
  }, [changeStatus, perPage, reset, show]);

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const ChangeStatus = async (id) => {
    const response = await postData(`/seller/products/all-products/${id}`, {
      accept_or_reject: 1,
    });
    setChangeStatus(response);
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"All Products"} />
        <section className="AdvanceDashboard">
          <div className="row  me-0 ms-0  MainRowsec">
            <div className="col-lg-12">
              <section className="Tabels tab-radio tab-radio ">
                <div className="">
                  <div className="row">
                    <div className="d-flex add-btn-holder">
                      <div className="add me-3">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={colunms} className="columns " alt="" />
                            Column Selection
                            <img src={top} className="top ms-1" alt="" />
                          </button>

                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Product
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>{" "}
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col8")}
                                href="#"
                              >
                                Accept Or Reject
                                {visible?.col8 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                View
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="   col-xl-12  col-lg-12 col-md-12 col-12">
                        <div className="d-flex row-flex-holder">
                          <div className="show me-2">
                            <p className="show ">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show ">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="   col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end entry-showing">
                          <div className="sowing me-2">
                            <p className="show ">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          <div className="Search me-2">
                            <button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </button>
                          </div>

                          <div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Nav variant="pills" className="mb-3">
                        <Nav.Item>
                          <Nav.Link eventKey="first">All Products</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            Accepted Products
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">
                            Rejected Products
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <div className="data table-responsive">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Table striped bordered hover responsive center>
                              <thead>
                                <tr className="">
                                  {visible.col1 && (
                                    <th className="sr">Sr. No.</th>
                                  )}
                                  {visible.col2 && (
                                    <th className="tax-name">Products</th>
                                  )}

                                  {visible.col8 && (
                                    <th className="tax-name">
                                      Accept Or Reject
                                    </th>
                                  )}

                                  {visible.col6 && (
                                    <th className="active">View</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {" "}
                                {isAllow.includes(106) ? (
                                  data?.data?.data?.map((d, index) => {
                                    return (
                                      <tr className="" key={index}>
                                        {visible.col1 && <td>{++index}.</td>}
                                        {visible.col2 && (
                                          <td>{d?.product_title}</td>
                                        )}
                                        {/* {visible.col8 && (
                                          <td>
                                            <div className="d-flex">
                                              <button
                                                className={`btn btn-${
                                                  d.accept_or_reject
                                                    ? "success"
                                                    : "success"
                                                }`}
                                                onClick={() => {
                                                  ChangeStatus(d.id, true);
                                                }}
                                              >
                                                Accept
                                              </button>

                                              <button
                                                className={`btn btn-${
                                                  d.accept_or_reject
                                                    ? "danger"
                                                    : "danger"
                                                }`}
                                                onClick={() => {
                                                  if (
                                                    d.accept_or_reject === 1 ||
                                                    d.accept_or_reject === null
                                                  ) {
                                                    handleShow1(d.id);
                                                  }
                                                }}
                                              >
                                                Reject
                                              </button>
                                            </div>
                                          </td>
                                        )} */}
                                        {visible.col8 && (
                                          <td>
                                            <div className="d-flex">
                                              {d.accept_or_reject === 1 ? (
                                                <button
                                                  className={`btn btn-success disabled`}
                                                  disabled
                                                >
                                                  Accept
                                                </button>
                                              ) : d.accept_or_reject === 0 ? (
                                                <button
                                                  className={`btn btn-danger disabled`}
                                                  disabled
                                                >
                                                  Reject
                                                </button>
                                              ) : (
                                                <>
                                                  <div className="d-flex">
                                                    <button
                                                      className={`btn btn-${
                                                        d.accept_or_reject
                                                          ? "success"
                                                          : "success"
                                                      }`}
                                                      onClick={() => {
                                                        ChangeStatus(
                                                          d.id,
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      Accept
                                                    </button>

                                                    <button
                                                      className={`btn btn-${
                                                        d.accept_or_reject
                                                          ? "danger"
                                                          : "danger"
                                                      }`}
                                                      onClick={() => {
                                                        if (
                                                          d.accept_or_reject ===
                                                            1 ||
                                                          d.accept_or_reject ===
                                                            null
                                                        ) {
                                                          handleShow1(d.id);
                                                        }
                                                      }}
                                                    >
                                                      Reject
                                                    </button>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </td>
                                        )}

                                        {visible.col6 && (
                                          <td>
                                            <div className="add me-3">
                                              {" "}
                                              <Link
                                                type="button"
                                                className="btn btn-add pe-3"
                                              >
                                                <div
                                                  onClick={() =>
                                                    handleViewshow(d.id)
                                                  }
                                                >
                                                  view
                                                </div>
                                              </Link>
                                            </div>
                                          </td>
                                        )}
                                        {showPopup && (
                                          <div className="popup">
                                            <div className="popup-inner">
                                              <h2>Reason for Rejection</h2>
                                              <form>
                                                <Form.Label>Reasons</Form.Label>
                                                <Form.Group>
                                                  <InputGroup>
                                                    <Form.Control
                                                      type="text"
                                                      name="reasons"
                                                      placeholder="Reasons"
                                                      className={classNames(
                                                        "",
                                                        {
                                                          "is-invalid":
                                                            errors?.reasons,
                                                        }
                                                      )}
                                                      {...register("reasons", {
                                                        required:
                                                          "reasons is required",
                                                      })}
                                                    />
                                                  </InputGroup>
                                                  {errors.reasons && (
                                                    <span className="text-danger">
                                                      {errors.reasons.message}
                                                    </span>
                                                  )}
                                                </Form.Group>
                                              </form>

                                              <button
                                                onClick={() =>
                                                  ChangeStatus(
                                                    d.id,
                                                    false,
                                                    rejectReason
                                                  )
                                                }
                                              >
                                                Submit
                                              </button>
                                              <button onClick={closePopup}>
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </Table>
                          </Tab.Pane>

                          <Tab.Pane eventKey="second">
                            <Table striped bordered hover responsive center>
                              <thead>
                                <tr className="">
                                  {visible.col1 && (
                                    <th className="sr">Sr. No.</th>
                                  )}
                                  {visible.col2 && (
                                    <th className="tax-name">Products</th>
                                  )}{" "}
                                  {visible.col3 && (
                                    <th className="tax-name">View</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {" "}
                                {isAllow.includes(106) ? (
                                  accepted?.data?.map((d, index) => {
                                    return (
                                      <tr className="" key={index}>
                                        {visible.col1 && <td>{++index}.</td>}
                                        {visible.col2 && (
                                          <td>{d?.product_title}</td>
                                        )}

                                        <div className="add me-3">
                                          {" "}
                                          <Link
                                            type="button"
                                            className="btn btn-add pe-3"
                                          >
                                            <div
                                              onClick={() =>
                                                handleViewshow(d.id)
                                              }
                                            >
                                              view
                                            </div>
                                          </Link>
                                        </div>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </Table>
                          </Tab.Pane>
                          {/* <h3>Rejected Products</h3> */}

                          <Tab.Pane eventKey="third">
                            <Table striped bordered hover responsive center>
                              <thead>
                                <tr className="">
                                  {visible.col1 && (
                                    <th className="sr">Sr. No.</th>
                                  )}
                                  {visible.col2 && (
                                    <th className="tax-name">Products</th>
                                  )}{" "}
                                  {visible.col3 && (
                                    <th className="tax-name">View</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {" "}
                                {isAllow.includes(106) ? (
                                  rejected?.data?.map((d, index) => {
                                    return (
                                      <tr className="" key={index}>
                                        {visible.col1 && <td>{++index}.</td>}
                                        {visible.col2 && (
                                          <td>{d?.product_title}</td>
                                        )}
                                        <div className="add me-3">
                                          {" "}
                                          <Link
                                            type="button"
                                            className="btn btn-add pe-3"
                                          >
                                            <div
                                              onClick={() =>
                                                handleViewshow(d.id)
                                              }
                                            >
                                              view
                                            </div>
                                          </Link>
                                        </div>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </Table>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setChangeStatus={setChangeStatus}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      {view ? (
        <ViewOffCanvance
          handleClose={handleViewClose}
          setShow={setview}
          show={view}
          id={view}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Tables;
