import React from 'react'
import Header from '../../Header/Header'
import MainDashoboardTab from '../InhouseAds/MainDashoboardTab/MainDashoboardTab'
import NewLinkDash from './NewLinkDash/NewLinkDash'

const NewLink = () => {
    return (
        <>
            <Header title={"New Link"} />
            <MainDashoboardTab />
            <NewLinkDash />
        </>

    )
}

export default NewLink