import React from 'react'
import Header from '../../Header/Header'
import SellerInfluencerInner from './SellerInfluencerInner/SellerInfluencerInner'
import MainDashoboardTab from '../InhouseAds/MainDashoboardTab/MainDashoboardTab'


const SellerInfluencer = () => {
    return (
        <>
            <Header title={"Seller Influencer"} />
            <MainDashoboardTab />
            <SellerInfluencerInner />
        </>
    )
}

export default SellerInfluencer