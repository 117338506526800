import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const DashboardPieChart = () => {
    const options = {
        backgroundColor: "transparent", // Set background color to transparent
        // theme: "dark2",
        animationEnabled: true,
        exportFileName: "Earning",
        exportEnabled: true,
        title: {
            text: "Earning",
            fontSize: 20, 
            fontColor: "#000" 
        },
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            toolTipContent: "{label}: <strong>{y}%</strong>",
            indexLabel: "{y}%",
            indexLabelPlacement: "inside",
            dataPoints: [
                { y: 32, label: "Health" },
                { y: 22, label: "Finance" },
                { y: 15, label: "Education" },
                { y: 19, label: "Career" },
               
               
            ]
        }]
    }
    return (
        <>
            <div className=' customer-table mt-5'>
                <CanvasJSChart options={options}
                /* onRef={ref => this.chart = ref} */
                />
            </div>
        </>
    )
}

export default DashboardPieChart